import "@assets/styles/components/profile_company.scss";

import React, {useEffect, useMemo, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import {Tooltip} from "react-tooltip";
import {useSelector} from "react-redux";
import {ToastContainer} from "react-toastify";
import styled from "styled-components";

import {useDownloadFile} from "@hooks/useDownloadFile";

import Team from "@components/organisms/Team";
import ModalCustom from "@components/ModalCustom";
import ProfileEditor from "@components/organisms/ProfileEditor";

import VacancyListItem from "./components/VacancyListItem";
import ProductModal from "./components/ProductModal";
import VacancyModal from "./components/VacancyModal";
import TeamMember from "./components/TeamMember";

import {DOWNLOAD_FILE, GET_OFFERS_LIST} from "@api/endpoints";
import {usePagination} from "@hooks/usePagination";
import {useVariables} from "@hooks/useVariables";
import {getOfferList} from "@store/asyncActions/offersResponses";
import {displayDateDDMMYYYY} from "@utils/dateFormat";
import {addHTTPS} from "@utils/other";
import ProductCompanyListItem from "@pages/ProfilePage/components/ProfileCompany/components/ProductCompanyListItem";
import {useShop} from "@hooks/useShop";

const ProfileCompany = ({companyData}) => {


    const user = useSelector((state) => state.user.user);
    const [rateModal, setRateModal] = useState(false);
    const [teamModal, setTeamModal] = useState(false);
    const [responsibleModal, setResponsibleModal] = useState(false);
    const [editProfileModal, setEditProfileModal] = useState(false);
    const [polyexchangeModal, setPolyexchangeModal] = useState(false);
    const companyDataReducer = useSelector((state) => state.company);
    const companyData_ = companyDataReducer;


    const [selectedVacancy, setSelectedVacancy] = useState();
    const [vacancyModal, setVacancyModal] = useState(false);
    useEffect(() => {
        if (selectedVacancy) {
            setVacancyModal(true);
        }
    }, [selectedVacancy]);

    useEffect(() => {
        if (!vacancyModal) {
            setSelectedVacancy(undefined);
        }
    }, [vacancyModal]);

    const [productModal, setProductModal] = useState(false);
    const [button, setButton] = useState(null);
    const company = companyData?.company;
    const team = companyData?.team;
    const requestOptionsGet = useVariables().requestOptionsGET;
    const isCompanyContent = user.id === company?.id;
    const {products, refresh, loading} = useShop({companyId:company?.id});
    const [filters, setFilters] = useState({authorId: companyData.company.id, size: 3})
    const [offers_, setOffers] = useState(null);
    //const offers_ = usePagination(GET_OFFERS_LIST,requestOptionsGet,`offerId,desc`,null,filters,true)
    const vacancies = companyData?.vacancies;
    const isStudentContent =
        user.id !== company?.id && user.roles.includes("STUDENT");
    const img = useDownloadFile(DOWNLOAD_FILE, company?.avatarId).file;
    const avatar = user.newAvatar || img;
    const getButtonForStudent = companyData.getButtonForStudent;

    useEffect(() => {
        setButton(getButtonForStudent());
        if (company) {
            setFilters({...filters, authorId: isCompanyContent ? user.id : companyData.company?.id})
        }
    }, [company]);
    useEffect(() => {
        if (filters && filters.authorId) {
            getOfferList(user, 0, 3, "", filters).then(r => {
                setOffers(r?.content)
            })
        }
    }, [filters, company])

    return (
        <div className="profile_company">
            <div
                className={
                    isCompanyContent
                        ? "profile_company_content"
                        : "profile_company_content_for_student"
                }
            >
                {isCompanyContent ? (
                    <div className="rate grid_item">
                        <div className="line_block align_center">
                            <Tooltip id="rate" place="bottom" className="tooltip">
                                Вы можете посмотреть доступные тарифы подписок
                            </Tooltip>
                            <h2 className="tooltip_title">
                                Тариф{/*<span className="i-hint" data-tooltip-id="rate"></span>*/}
                            </h2>
                            <NavLink to={"/profile/edit#1"} className="to_page" onClick={() => setRateModal(true)}>
                                Подробнее
                                <span className="i-up_right"></span>
                            </NavLink>
                        </div>
                        <p className="rate_name">{company?.subscription?.subscriptionName}</p>
                        {company?.subscription?.endDate &&
                            <div className="line_block align_bottom">
                                <span
                                    className="rate_term">Активен до {displayDateDDMMYYYY(company?.subscription?.endDate)}</span>
                                {/*<button className="card_button">Сменить</button>*/}
                            </div>
                        }

                    </div>
                ):(
                    <>
                    </>
                )}
                {/*{isCompanyContent && (
          <div className="team grid_item">
            <div className="line_block align_center">
              <Tooltip id="team" place="bottom" className="tooltip">
                Вы можете добавлять студентов в свою команду для связи с ними
              </Tooltip>
              <h2 className="tooltip_title">
                Управление командой
                <span className="i-hint" data-tooltip-id="team"></span>
              </h2>
              <span className="to_page" onClick={() => setTeamModal(true)}>
                Подробнее
                <span className="i-up_right"></span>
              </span>
            </div>
            <div className="students_team">
              {team.map((member) => (
                <TeamMember
                  onClick={() => setTeamModal(true)}
                  key={member.id}
                  member={member}
                />
              ))}
            </div>
            <div className="line_block align_center">
              <span className="feedback_info">
                <span className="i-star need_feedback"></span>
                Отсутствует обратная связь
              </span>
              <button
                className="card_button"
                onClick={() => setTeamModal(true)}
              >
                Оставить обратную связь
              </button>
            </div>
          </div>
        )}*/}

                {/*<div className="competencies grid_item">
          <div className="line_block align_center">
            <h2 className="tooltip_title">Компетенции</h2>
            {isCompanyContent && (
              <Link className="to_page" to="/">
                Редактировать<span className="i-up_right"></span>
              </Link>
            )}
          </div>
          Срок разработки блока - 2024 год
        </div>*/}
                <div className="shop grid_item list_block">
                    <div className="line_block align_center">
                        <h2 className="tooltip_title">Магазин</h2>
                        {isCompanyContent &&
                         <>
                             {products?.length > 0 ? (
                                     <button className="to_page" onClick={() => setProductModal(true)}>
                                         Подробнее
                                         <span className="i-up_right"></span>
                                     </button>
                                 ) :
                                 (
                                     <NavLink to={"/profile/shop/create"} className="to_page">
                                         Добавить товар
                                         <span className="i-up_right"></span>
                                     </NavLink>
                                 )}
                         </>
                        }


                    </div>
                    <ul className="list_items list_items-shop products-list">
                        {products.length > 0 ? (
                            products
                                .slice(0, 3)
                                .map((product) => (
                                    <ProductCompanyListItem
                                        key={product.productId}
                                        product={product}
                                    />
                                ))
                        ) : (
                            <div className="empty">Список товаров пуст</div>
                        )}
                    </ul>
                    {isCompanyContent &&
                        <>
                            {products?.length > 0 ? (
                                    <button className="show_all" onClick={() => setProductModal(true)}>
                                        Смотреть все товары
                                    </button>
                                ) :
                                (
                                    <NavLink to={"/profile/shop/create"} className="to_page">
                                        Добавить товар
                                        <span className="i-up_right"></span>
                                    </NavLink>
                                )

                            }
                        </>
                    }

                </div>

                {isCompanyContent ? (
                    <div className="contact_person grid_item">
                        <div className="line_block align_center">
                            <h2 className="tooltip_title">Ответственный</h2>

                            {/*<span
                className="to_page"
                onClick={() => setResponsibleModal(true)}
              >
                Редактировать
                <span className="i-up_right"></span>
              </span>*/}
                        </div>
                        {company.contactPersonList &&
                            [...company.contactPersonList].splice(0, 1).map((el) => (
                                <div key={el.contactPersonId} className="person_info">
                                    <p className="name">{`${el.secondName} ${el.firstName} ${el.middleName ? el.middleName : ""}`}</p>
                                    <p className="profession">{el.position}</p>
                                    <p className="phone">{el.phoneNumber}</p>
                                    <p className="email">{el.email}</p>
                                </div>
                            ))}
                    </div>
                ):(
                    <>
                    </>
                )}

                {/*<div className="events grid_item">
          <div className="line_block align_center">
            <h2 className="tooltip_title">Мероприятия</h2>
            {isCompanyContent && (
              <Link className="to_page" to="/">
                Редактировать<span className="i-up_right"></span>
              </Link>
            )}
          </div>
          Срок разработки блока - 2024 год
        </div>*/}
                <div className="polyexchange grid_item list_block">
                    <div className="line_block align_center">
                        <h2 className="tooltip_title">Карьера</h2>
                        {user.id === company?.id &&
                            <NavLink
                                to={"/profile/edit#2"}
                                className="to_page"
                            >
                                Редактировать
                                <span className="i-up_right"></span>
                            </NavLink>
                        }
                    </div>
                    <ul className="list_items career_list newStyle">
                        {offers_ && offers_.length > 0 ? (
                            offers_
                                .map((vacancy) => (
                                    <VacancyListItem
                                        edit={user.id === company?.id}
                                        key={vacancy.id}
                                        vacancy={vacancy}
                                        onSelect={setSelectedVacancy}
                                    />
                                ))
                        ) : (
                            <div className="empty">Список вакансий пуст</div>
                        )}
                    </ul>

                    {user.id === company?.id &&
                        <NavLink style={{padding: 0}} className="menu_link" to={"/profile/create-career"}
                                 state={{previousLocationPathname: "/profile"}} end>
                            <button
                                className="show_all"
                            >
                                Добавить
                            </button>
                        </NavLink>
                    }
                    {/*<button
            className="show_all"
            onClick={() => setPolyexchangeModal(true)}
          >
            Смотреть все предложения
          </button>*/}
                </div>
                <div className="profile_card grid_item">
                    <div className="top_block">
                        <div className="avatar">
                            <img src={avatar} alt="gasprom"/>
                        </div>
                        {isCompanyContent && (
                            <NavLink className="menu_link" to={"/profile/edit"} end>
                                <div
                                    className="button dark_gray_button"
                                >
                                    <span className="i-edit"></span>
                                </div>
                            </NavLink>
                            /*<div
                              className="button dark_gray_button"
                              onClick={() => setEditProfileModal(true)}
                            >
                              <span className="i-edit"></span>
                            </div>*/
                        )}
                    </div>
                    <p className="activity">{company?.activity}</p>
                    <h1 className={"company_name"}>{company?.name}</h1>
                    <Link className="subtitle to_page" target={"_blank"} to={addHTTPS(company?.site)}>
                        <span className="full_name">{company?.longName}</span>
                        <span className="i-up_right"></span>
                    </Link>

                    <p className="inn">Инн {company?.inn}</p>
                    <Tooltip id="professions" place="bottom" className="tooltip">
                        В данном блоке представлены актуальные для компании профессии
                    </Tooltip>
                    <p className="subtitle tooltip_title">
                        Профессии
                        <span className="i-hint" data-tooltip-id="professions"></span>
                    </p>
                    <div className="professions">
                        {companyDataReducer?.company?.professions &&
                            [...companyDataReducer?.company?.professions].splice(0, 15).map((el) => (
                                <span key={el.id} className="bubble">
                  {el.name}
                </span>
                            ))}
                        {companyDataReducer?.company?.professions.length > 15 &&
                            <>
                                ... +{companyDataReducer?.company?.professions.length - 15}
                            </>
                        }
                    </div>

                    <p className="subtitle">Об организации</p>
                    <p className="description">{company.description}</p>

                    {isStudentContent && button}
                </div>
            </div>
            {isCompanyContent && (
                <>
                    <ModalCustom
                        title="Тарифы"
                        active={rateModal}
                        setActive={setRateModal}
                    ></ModalCustom>
                    <ModalCustom
                        title="Команда"
                        active={teamModal}
                        setActive={setTeamModal}
                    >
                        <Team
                            tabs={["Наша команда", "Входящие заявки", "Исходящие заявки"]}
                        />
                    </ModalCustom>
                    <ModalCustom
                        title="Ответственные"
                        active={responsibleModal}
                        setActive={setResponsibleModal}
                    ></ModalCustom>
                    <ModalCustom
                        title="Редактирование профиля"
                        active={editProfileModal}
                        setActive={setEditProfileModal}
                    >
                        <ProfileEditor onClose={() => setEditProfileModal(false)}/>
                    </ModalCustom>
                </>
            )}
            {/* <ModalCustom
        title="Вакансии и стажировки"
        active={polyexchangeModal}
        setActive={setPolyexchangeModal}
      >
        <VacancyModal company={company} vacancies={vacancies} />
      </ModalCustom>*/}

            <ModalCustom
                title="Магазин"
                active={productModal}
                setActive={setProductModal}
            >
                <ProductModal products={products}/>
            </ModalCustom>
            <ModalCustom
                title={selectedVacancy?.name}
                active={vacancyModal}
                setActive={setVacancyModal}
            >
                {selectedVacancy ? (
                    <VacancyModalContent>
                        <VacancyModalImg src={selectedVacancy.img} alt="vacancy_image"/>
                        {selectedVacancy.description}
                    </VacancyModalContent>
                ) : null}
            </ModalCustom>

            <ToastContainer/>
        </div>
    );
};

export default ProfileCompany;

const VacancyModalContent = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  color: #555555;
`;

const VacancyModalImg = styled.img`
  border-radius: 10px;
  height: 150px;

  margin-right: 20px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;
