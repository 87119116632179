import "./styles.scss";
import { ReactComponent as AndroidIcon } from "@assets/svg/android.svg";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import {TypeAnimation} from 'react-type-animation';
import {Link, NavLink} from "react-router-dom";
import React, {Fragment, useRef, useState} from "react";
import {ToastContainer} from "react-toastify";
import VideoDisplayButton from "./components/VideoDisplayButton";
import polycoin from "@assets/images/shop.png";
import competencies from "./assets/images/competencies.png";
import rating from "./assets/images/rating.png";
import messenger from "./assets/images/messenger.png";
import email from "./assets/images/icon-email.svg";
import vk from "./assets/images/icon-vk.svg";
import footerlogo from "./assets/images/footer-logo.svg";
import styled from "styled-components";
import "./assets/style.scss";
import partner from "./assets/images/partner.svg"
import ModulesCarousel from "@pages/StartPage/components/ModulesCarousel/ModulesCarousel";
import StudentsReviews from "@pages/StartPage/components/StudentsReviews/StudentsReviews";
import ModalCustom from "@components/ModalCustom";
import Team from "@components/organisms/Team";
import FeedbackForm from "@pages/StartPage/components/FeedbackForm/FeedbackForm";
import {PARTNERS, PARTNERS_MAP} from "@constants/partners";
import AnimationBlock from "@components/AnimationBlock";

const INFO_TABS = [
    {
        id: 0,
        name: "Портфолио",
        icon: "competencies",
        image: competencies,
        text: `Здесь собраны цифровые портфолио самых талантливых студентов.
Мы загружаем верифицированные достижения из личного кабинета учащегося и визуализируем их в формате матрицы компетенций. Благодаря Методике расчета прироста компетенций
за внеучебную деятельность студентов пользователь сможет увидеть, какие надпрофессиональные навыки стоят за каждым его достижением в формате удобного графика или таблицы.`,
        link: "Методика формирования цифрового портфолио",
    },
    {
        id: 1,
        name: "Рейтинг",
        icon: "rating",
        image: rating,
        text: `Поиск потенциальных кадров по необходимым критериям.
На этой вкладке можно отфильтровать портфолио по приоритетному навыку, направлению обучения и институту. Рейтинг поможет увидеть уровень достижений и уникальность каждого студента, а также выбрать лучших кандидатов с приоритетными для компании навыками.`,
        link: "Методика формирования рейтинга",
    },
    {
        id: 2,
        name: "Обратная связь",
        icon: "coin",
        image: polycoin,
        text: `Уникальный формат обратной связи на компетенции студента.
Здесь компании смогут поделиться опытом взаимодействия с кандидатом и написать честный отзыв о его навыках. Такой фидбэк помогает студентам совершенствоваться, прокачивать свои навыки и формировать профессиональную самооценку.`,
    },
    {
        id: 3,
        name: "Мессенджер",
        icon: "messenger",
        image: messenger,
        text: `Место, где студенты и компании могут общаться напрямую.
Задать интересующий вопрос, лично пригласить на стажировку, профильное мероприятие или собеседование можно в удобном мессенджере, где есть только работодатель и студент. Прямое, открытое общение и ничего лишнего. `,
        link: "Описание мессенждера",
    },
];

function StartPage() {
    const [collapseStudentInfo, setCollapseStudentInfo] = useState(true);
    const [collapseCompanyInfo, setCollapseCompanyInfo] = useState(true);
    const [formModal, setFormModal] = useState(false);
    const [activeInfoTab, setActiveInfoTab] = useState(INFO_TABS[0]);
    const [activeModulesTab, setActiveModulesTab] = useState(0);
    const reviewRef = useRef();
    const scrollToReviews = () => {
        const y = reviewRef.current.getBoundingClientRect().top + window.scrollY;
        window.scroll({
            top: y,
            behavior: 'smooth'
        });
    }
    const words = [
        'Портфолио',
        2000,
        'Знакомства',
        2000,
        'Работа',
        2000,
        'Стажировка',
        2000,
        'Практика',
        2000,
        'Мерч',
        2000,
    ];

    return (

        <div className="start">
            <header className="header">
                <a href="/" className="logo">
                    <span className="logo_icon"></span>
                    <p>Поликапитал</p>
                </a>

                <div className="exit">
                    <Link to="/profile">
                        <span>Войти</span>
                        <span className="i-up_right"></span>
                    </Link>
                </div>
            </header>
            <main className="index-page">

                <section className="offer">

                    <div className="containers">
                        <div className="offer-content">
                            <div className="offer-title">
                                <span>Единая цифровая платформа</span>
                                <h1>Поликапитал</h1>
                                <p>Система, разработанная специально для студентов, компаний и
                                    университетов</p>
                            </div>
                            <div className="offer-footer">
                                <VideoDisplayButton title={"Видео для студентов"}/>
                                <VideoDisplayButton className={"btn btn-outline-white"} title={"Видео для компаний"} videoUrl={"https://rutube.ru/play/embed/498be4bd9c1b0ccfcbc1d9b8e0cd0ae6/"}/>

                               {/* <a onClick={scrollToReviews} c}>lassName="btn btn-outline-white">Отзывы
                                    студентов</a>*/}
                            </div>
                        </div>
                    </div>
                </section>


                    <section className="moduls">
                        <div className="containers">
                            <div className="section-content tabs">
                                <div className="flex space-between">
                                    <h2>модули системы</h2>
                                    <ul className="d-flex tabsmenu">
                                        <li onClick={() => setActiveModulesTab(0)}
                                            className={activeModulesTab === 0 ? "active" : ""}>Студентам
                                        </li>
                                        <li onClick={() => setActiveModulesTab(1)}
                                            className={activeModulesTab === 1 ? "active" : ""}>Университетам
                                        </li>
                                        <li onClick={() => setActiveModulesTab(2)}
                                            className={activeModulesTab === 2 ? "active" : ""}>Компаниям
                                        </li>
                                    </ul>
                                </div>
                                <div className="tab-content">
                                    <div className="tab1 active" style={{height:552}}>
                                        <ModulesCarousel activeTab={activeModulesTab}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                <AnimationBlock  transformAnimation={false}>

                    <section className="partners">
                        <div className="containers">
                            <div className="section-content">
                                <h2>партнеры</h2>
                                <div className="partner-block">
                                    {PARTNERS.map((partner) => (
                                        <a href={partner.link} target={"_blank"} className="partner-blk">
                                            <img src={partner.logo} alt=""/>
                                        </a>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </section>
                </AnimationBlock>
                <AnimationBlock  transformAnimation={false}>

                    <section className="info-block">
                        <div className="containers">
                            <div className="section-content">
                                <h2><span>
                            <TypeAnimation
                                    sequence={words}
                                    wrapper="span"
                                    cursor={true}
                                    repeat={Infinity}
                                />,
                          </span>мы подготовили все, что нужно для твоего успеха.</h2>
                                <NavLink to={"/login"} className="btn btn-grdnt">Зарегистрироваться</NavLink>
                            </div>
                        </div>
                    </section>
                </AnimationBlock>
                <AnimationBlock transformAnimation={false}>

                    <section ref={reviewRef} className="reviews">
                        <div className="containers">
                            <div className="section-content">
                                <h2>отзывы студентов</h2>
                                <StudentsReviews/>
                            </div>
                        </div>
                    </section>
                </AnimationBlock>
            </main>

            <AnimationBlock >

            <footer>
                <div className="containers">
                    <div className="footer-block">
                        <div className="flex">
                            <div className="fblk fnav">
                                <ul>
                                    <li>
                                        <a href="/pdf/std_instr.pdf" target={"_blank"}>
                                            Для студентов
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/pdf/cmp_instr.pdf" target={"_blank"}>
                                            Для компаний
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/pdf/uni_instr.pdf" target={"_blank"}>
                                            Для вузов
                                        </a>
                                    </li>
                                   {/* <li><a href="https://polykp.spbstu.ru/privacy_policy.pdf" target={"_blank"}>Для
                                        кого</a></li>
                                    <li><a href="https://polykp.spbstu.ru/privacy_policy.pdf"
                                           target={"_blank"}>Материалы</a></li>
                                    <li><a href="https://polykp.spbstu.ru/privacy_policy.pdf"
                                           target={"_blank"}>Модули</a></li>
                                    <li><a href="https://polykp.spbstu.ru/privacy_policy.pdf" target={"_blank"}>О
                                        системе</a></li>
                                    <li><a href="https://polykp.spbstu.ru/privacy_policy.pdf"
                                           target={"_blank"}>Партнеры</a></li>
                                    <li><a href="/polycapital.apk"
                                           target={"_blank"}> <AndroidIcon style={{width:20,height:20}}/> Приложение</a></li>*/}
                                </ul>
                            </div>
                            <div className="fblk ffaq">

                                <span>Остались вопросы?</span>
                                <div className="flex space-between">
                                    <button onClick={() => setFormModal(true)} className="btn btn-white modal-link"
                                            data-modal="#modal-faq">Форма обратной связи
                                    </button>
                                    <div className="footer-apps">
                                        <li>
                                            <a href="/polycapital.apk"
                                               target={"_blank"}> <AndroidIcon style={{width:20,height:20}}/>Приложение</a>
                                        </li>
                                        <ul>

                                            <li><a href="mailto:polykp@spbstu.ru" target={"_blank"}><img src={email}
                                                                                                         alt=""/></a>
                                            </li>
                                            <li><a href="https://vk.com/polykapital" target={"_blank"}><img src={vk}
                                                                                                            alt=""/></a>
                                            </li>

                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="fblk flogo">
                            <img src={footerlogo} alt=""/>
                            <div className="flex">
                                <span>© Поликапитал, {new Date().getFullYear()}</span>
                                <a href="https://polykp.spbstu.ru/privacy_policy.pdf" target={"_blank"}>Политика
                                    конфиденциальности</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            </AnimationBlock>
            <ModalCustom
                className={"formModal"}
                active={formModal}
                setActive={setFormModal}
            >
                <FeedbackForm close={() => setFormModal(false)}/>
            </ModalCustom>
            <ToastContainer/>
        </div>

    );
}

export default StartPage;

const StartUseBlockButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    padding-bottom: 70px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    padding-bottom: 0;
  }
`;

const StartUseBlockButton = styled(Link)`
  display: flex;
  justify-content: center;
  padding: 12px;
  min-width: 140px;

  @media (max-width: 600px) {
    width: 300px;
    margin-bottom: 10px;
  }
`;

const AndroidDownloadButton = styled(StartUseBlockButton)`
  background-color: #00b352;
  padding: 12px;
  padding-right: 30px;

  margin-left: 10px;

  @media (max-width: 600px) {
    margin-left: 0px;
  }
`;
