import React from'react';
import {SwiperSlide} from "swiper/react";
import module3_1 from "@pages/StartPage/assets/images/modul3-1.png";
import module3_2 from "@pages/StartPage/assets/images/modul3-2.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import modul2_1 from "@pages/StartPage/assets/images/modul2-1.png";
    const tabs = [
            <SwiperSlide>
                <div className="modul-blk">
                    <div className="modul-info">
                        <div className="modul-title">
                            <span className="titles">Создавайте карьерные предложения просто</span>
                            <p>Вакансии, стажировки и практики — единое окно со статистикой для всех предложений</p>
                        </div>
                    </div>
                    <div className="modul-image">
                        <LazyLoadImage
                            alt={"module3_1"}
                            effect={"blur"}
                            src={module3_1} />
                    </div>
                </div>

            </SwiperSlide>,
            <SwiperSlide>
                <div className="modul-blk">
                    <div className="modul-info">
                        <div className="modul-title">
                            <span className="titles">Единое окно лучших студентов страны</span>
                            <p>Поликапитал объединяет в себе студентов разных вузов. Так Вы сможете привлекать более
                                квалифицированные кадры под ваши цели от ведущих университетов страны.</p>
                        </div>
                    </div>
                    <div className="modul-image">
                        <LazyLoadImage
                            alt={"module3_2"}
                            effect={"blur"}
                            src={module3_2} />
                    </div>
                </div>

            </SwiperSlide>
    ]

export default tabs;