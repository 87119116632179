import "@assets/styles/components/profile_student.scss";

import React, { useEffect, useMemo, useState } from "react";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import { useDownloadFile } from "@hooks/useDownloadFile";
import useMessenger from "@hooks/useMessenger";

import PolarAreaChart from "@components/PolarAreaChart";
import Team from "@components/organisms/Team";
import ModalCustom from "@components/ModalCustom";
import ShowFeedbackForm from "@components/ShowFeedbackForm";
import ProfileEditor from "@components/organisms/ProfileEditor";
import BarChart from "@components/BarChart";
import CompetenciesTable from "@components/CompetenciesTable";

import {DOWNLOAD_FILE, GET_MY_RESPONSES} from "@api/endpoints";
import {NavLink} from "react-router-dom";
import VacancyListItem from "@pages/ProfilePage/components/ProfileCompany/components/VacancyListItem";
import {usePagination} from "@hooks/usePagination";
import {useVariables} from "@hooks/useVariables";
import StudentVacancyBlock from "@components/StudentVacancyBlock";
import Indicators from "@pages/ProfilePage/components/ProfileStudent/Indicators";
import {IsAdmin, IsCompany, IsMyProfileStudent, useSavedUser} from "@hooks/useAuth";

const ProfileStudent = ({ studentData }) => {
  const user = useSelector((state) => state.user.user);
  const [representation, setRepresentation] = useState(true);
  const [teamModal, setTeamModal] = useState(false);
  const [button, setButton] = useState(null);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [editProfileModal, setEditProfileModal] = useState(false);
  const [methodologyModal, setMethodologyModal] = useState(false);
  const [shopModal, setShopModal] = useState(false);
  const isCompany = IsCompany();
  //const { savedUser } = useSavedUser();
  const isCompanyContent = user.id !== studentData.student.id;
  const isStudentContent = IsMyProfileStudent(studentData.student.id);
  const student = studentData.student.id ? studentData.student : user;
  const requestOptionsGet = useVariables().requestOptionsGET;

  const responses = usePagination(GET_MY_RESPONSES,requestOptionsGet,"",null)

  const professions = user.professions;

  const inviteToCommand = studentData.inviteToCommand;
  const kickFromCommand = studentData.kickFromCommand;
  const approveInvitation = studentData.approveInvitation;
  const img = useDownloadFile(DOWNLOAD_FILE, student.avatarId).file;
  const avatar = studentData?.student?.newAvatar || img;

  const getButtonForCompany = () => {
    switch (student.inviteStatus) {
      case "NO_INVITE":
        return (
          <button
            className="main_button turquoise_button"
            onClick={() => inviteToCommand()}
          >
            Пригласить в команду
          </button>
        );
      case "COMPANY_INVITE":
        return (
          <button className="main_button disabled_button">
            Приглашение отправлено
          </button>
        );
      case "STUDENT_KNOCK":
        return (
          <button
            className="main_button turquoise_button"
            onClick={() => approveInvitation(student.invitationId)}
          >
            Принять в команду
          </button>
        );
      case "APPROVED":
        return (
          <button
            className="main_button turquoise_button"
            onClick={() => kickFromCommand()}
          >
            Убрать из команды
          </button>
        );
      case "REQUEST_SENT":
        return (
          <button className="main_button disabled_button">
            Запрос отправлен
          </button>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    setButton(getButtonForCompany());
  }, [student]);

  const { createOrGetChat } = useMessenger();
  const handleCommunicatePress = () => {
    createOrGetChat(studentData.student.id, true);
  };
  const openFeedbackModal = ()=>{
    setFeedbackModal(false)
  }
  return (
    <div className="newStyle profile_student profile_company student_prof">
      <div className="profile_content ">
        {studentData?.student &&
            <Indicators className={"indicators"} studentId={isStudentContent ? user.id : studentData.student.id} />
        }

        {/*<div className="grid_item wallet">
          <div className="line_block align_center">
            <h2 className="tooltip_title">
              Кошелек
              <span className="i-hint" data-tooltip-id="wallet"></span>
            </h2>
            <Tooltip id="wallet" place="bottom" className="tooltip">
              Здесь можно посмотреть накопленные поликоины за все время обучения
              и за текущий семестр
            </Tooltip>
            <button className="to_page" onClick={() => setShopModal(true)}>
              В магазин<span className="i-up_right"></span>
            </button>
          </div>
          <div className="line_block align_bottom">
            <p>
              <span>{studentData.student.wallet?.currentBalance}</span>
              поликоинов
            </p>
            <p className="all_points">
              <span>{studentData.student.wallet?.sumAllTime}</span>за весь
              период
            </p>
          </div>
        </div>*/}
        {/*<div className="grid_item activity">
          <Tooltip id="activity" place="bottom" className="tooltip">
            Тут будет информация об областях деятельности
          </Tooltip>
          <h2 className="tooltip_title">
            Области деятельности
            <span className="i-hint" data-tooltip-id="activity"></span>
          </h2>
          <div className="chart_block">
            <BarChart dataset={activityPoints} />
          </div>
        </div>*/}

       {/* <div className="grid_item competencies">
          <div className="line_block align_center">
            <h2>Компетенции</h2>
            <button
              className="to_page"
              onClick={() => setMethodologyModal(true)}
            >
              Метод расчета
              <span className="i-up_right"></span>
            </button>
            <a className="to_page" href={calcMethod} target={"_blank"}>
              Метод расчета
              <span className="i-up_right"></span>
            </a>
          </div>
          <div className="representation_competencies">
            <span
              className={representation ? "active_representation" : ""}
              onClick={() => setRepresentation(true)}
            >
              График
            </span>
            <span
              className={!representation ? "active_representation" : ""}
              onClick={() => setRepresentation(false)}
            >
              Таблица
            </span>
          </div>
          <div
            className={representation ? "chart_block" : "chart_block hidden"}
          >
            <PolarAreaChart
              labels={competenceChartData.labels}
              datasets={competenceChartData.datasets}
            />
            <ul className="competencies_list">
              {competenceTableDataOrderedById.map((el) => (
                <li key={el.id} data-id={el.id}>
                  {el?.name}
                </li>
              ))}
            </ul>
          </div>
          <div
            className={!representation ? "table_block" : "table_block hidden"}
          >
            <table className="list">
              <thead>
                <tr>
                  <th>Компетенция</th>
                  <th>Баллы</th>
                </tr>
              </thead>

              <tbody>
                {competenceTableDataOrderedByPoints.map((el, index) => (
                  <tr key={index} className="line">
                    <td>{el?.name}</td>
                    <td>{el.points}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>*/}

        <div className="grid_item profile_card" style={{height:"max-content"}}>
          <div className="top_block">
            <div className="avatar">

              <img src={avatar} alt="student" />

            </div>
            {isCompany &&
                <div className={"top_block-buttons"}>

                  <NavLink to={`/profile/messenger#${studentData.student.id}`} className="student-mess">
                    <div className="button positive_button">
                      <span className="i-messenger"></span>
                    </div>
                  </NavLink>


                  <div onClick={() => setFeedbackModal(true)} className="student-mess">
                    <div className="button dark_gray_button">
                      <span className="i-smile_joy"></span>
                    </div>
                  </div>
                </div>
            }

            <div className="buttons indicator-btn">
              {isStudentContent && (
                <NavLink to={`/profile/edit`}

                >
                  <div className="button dark_gray_button ">
                    <span className="i-edit"></span>
                  </div>

                </NavLink>
              )}
              {isStudentContent && (
                  <div onClick={() => setFeedbackModal(true)}

                  >
                    <button></button>
                  </div>
              )}
              {/*<div className="button dark_gray_button">*/}
              {/*    <span className="i-download"></span>*/}
              {/*</div>*/}
              {isCompanyContent && student.inviteStatus === "APPROVED" && (
                <div
                  className="button turquoise_button"
                  onClick={handleCommunicatePress}
                >
                  <span className="i-messenger"></span>
                </div>
              )}
            </div>
          </div>
          <h1>{`${student.secondName} ${student.firstName} ${student.middleName}`}</h1>

          <div className="student_info   indicator-user-info">

            <ul className="unstyled">
              {student.qualification?.name &&
                  <li><span>Уровень образования</span> {`${student.qualification ? student.qualification?.name : "Не указано"} ${student.course} курс`}</li>
              }
              {student.institute?.name &&
                  <li><span>Институт</span> {student.institute ? student.institute?.name : "Не указан"}</li>
              }
              {student.eduDirection?.name &&
                  <li><span>Направление</span> {student.eduDirection ? student.eduDirection?.name : "Не указано"}</li>
              }
              {student.eduProgram?.name &&
                  <li><span>Образовательная программа</span> {student.eduProgram ? student.eduProgram?.name : "Не указана"}</li>
              }
            </ul>
            <div>
              <Tooltip id="professions" place="bottom" className="tooltip">
                В данном блоке представлены актуальные для компании профессии
              </Tooltip>
              <p style={{marginBottom:0}} className="subtitle tooltip_title">
                Профессии
                <span  className="i-hint" data-tooltip-id="professions"></span>
              </p>
              <div className="professions">
                {professions &&
                    [...professions].splice(0,15).map((el) => (
                        <span key={el.id} className="bubble">
                  {el.name}
                </span>
                    ))}
                {professions?.length > 15 &&
                    <>
                      ... +{professions?.length - 15}
                    </>
                }
              </div>
            </div>


           {/* <div className="search-blk create-blk">
              <div className="filter-blk">
                <div className="flex">
                  <span className="title">Профессия</span>
                </div>
                <ul className="tag tag-choice active unstyled">
                  {student.professions &&
                      student.professions.map((el) => (
                          <li>Астроном
                            {el.name}
                          <button className="btn-close"></button></li>
                      ))}

                </ul>
              </div>
            </div>*/}
            <Tooltip id="professions" place="bottom" className="tooltip">
              В данном блоке представлены актуальные для компании профессии
            </Tooltip>
            {isStudentContent &&
                <div className="wallet-block">
                  <div className="flex">
                    <Tooltip id="wwal" place="bottom" className="tooltip">
                      Ваши монеты для покупок в магазине, участия в аукционах
                    </Tooltip>
                    <p style={{marginBottom:0}} className="subtitle tooltip_title">
                      Кошелек
                      <span  className="i-hint" data-tooltip-id="wwal"></span>
                    </p>

                    <a href="#">В магазин</a>
                  </div>
                  <ul className="unstyled flex">
                    <li><span>{studentData.wallet?.balance}</span> поликоинов</li>
                    <li><span></span></li>
                  </ul>
                </div>
            }
          </div>


          {isCompanyContent && button}
          {/*{isStudentContent && (
            <button
              className="main_button turquoise_button"
              onClick={() => setTeamModal(true)}
            >
              Мои команды
            </button>
          )}*/}
        </div>



       {/* {!isCompanyContent &&
            <div className="grid_item feedback">
              <Tooltip id="feedback" place="bottom" className="tooltip">
                {isStudentContent &&
                    "Здесь находится обратная связь на твои компетенции от компаний, с которым ты взаимодействовал"}
                {isCompanyContent &&
                    "Оставьте обратную связь о компетенциях студента, если вы взаимодействовали с ним"}
              </Tooltip>
              <h2 className="tooltip_title">
                Обратная связь
                <span className="i  -hint" data-tooltip-id="feedback"></span>
              </h2>

              <div className="buttons">
                {isCompanyContent && (
                    <>
                      <button className="main_button disabled_button">
                        Оставить
                        <span className="i-up_right"></span>
                      </button>
                      <button
                          className="main_button dark_gray_button"
                          onClick={() => setFeedbackModal(true)}
                      >
                        Обратная связь<span className="i-up_right"></span>
                      </button>
                    </>
                )}
                {isStudentContent && (
                    <button
                        className="main_button turquoise_button"
                        onClick={() => setFeedbackModal(true)}
                    >
                      Посмотреть
                      <span className="i-up_right"></span>
                    </button>
                )}
              </div>
            </div>
        }*/}
        {isStudentContent  &&
            <div className="grid_item career" style={{height:"max-content"}}>
              <div className="line_block align_center">
                <h2 className="tooltip_title">Карьера</h2>
                <NavLink
                    to={"/profile/info#0"}
                    className="to_page"
                >
                  Смотреть все
                  <span className="i-up_right"></span>
                </NavLink>
              </div>
              <ul className="list_items student-vacancy-list student-career-list">
                {responses.data.length > 0 ? (
                    responses.data
                        .slice(0, 3)
                        .map((vacancy) => (
                            <StudentVacancyBlock
                                key={vacancy.id}
                                vacancy={vacancy}

                            />
                        ))
                ) : (
                    <div className="empty">Список откликов пуст</div>
                )}
              </ul>
            </div>
        }
      </div>

      {isStudentContent && (
        <>
          <ModalCustom
            title="Команды"
            active={teamModal}
            setActive={setTeamModal}
          >
            <Team tabs={["Мои команды", "Приглашения", "Исходящие заявки"]} />
          </ModalCustom>

          <ModalCustom
            title="Редактирование профиля"
            active={editProfileModal}
            setActive={setEditProfileModal}
          >
            <ProfileEditor onClose={() => setEditProfileModal(false)} />
          </ModalCustom>
        </>
      )}
      <ModalCustom title="Магазин" active={shopModal} setActive={setShopModal}>
        <p>Срок разработки блока - конец 2023 года</p>
      </ModalCustom>
      <ModalCustom
        title="Обратная связь"
        active={feedbackModal}
        setActive={setFeedbackModal}
      >
        <ShowFeedbackForm openFeedbackModal={openFeedbackModal} student={student} />
      </ModalCustom>
      <ModalCustom
        title="Методика формирования компетенций"
        active={methodologyModal}
        setActive={setMethodologyModal}
      >
        <CompetenciesTable />
      </ModalCustom>
      <ToastContainer />
    </div>
  );
};

export default ProfileStudent;
