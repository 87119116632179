import React from "react";
import {NavLink} from "react-router-dom";
import {studentsLanguages} from "@utils/languageList";


const StudentBlock = ({user}) => {
    return (
        <tr>
            <td>
                <ul className="unstyled">
                    <li>
                        <NavLink className="edit" to={`/admin/student-update/${user.studentId}`} state={{previousLocationPathname:"/admin/students"}}>
                        </NavLink>
                    </li>
                   {/* <li><a className="delete"></a></li>*/}
                </ul>
            </td>
            <td>{user.secondName} {user.firstName} {user.middleName}</td>
            <td className="status1"><span>{user.status ? studentsLanguages.studentStatuses[user.status] : "Неизвестно"}</span></td>
            <td>{user.qualification?.name} {user.course} курс</td>
            <td data-label="Институт:">{user.institute?.name}</td>
            <td data-label="Направление подготовки:">{user.eduProgram ? user.eduProgram?.name : <i>Неизвестно</i>}</td>
            <td data-label="Почта:">{user.email}</td>
            <td data-label="Номер группы:">{user.group}</td>
        </tr>
    )
}
export default StudentBlock;

/*
<tr>
    <td>
        <ul className="unstyled">
            <li><a href="#" className="edit"></a></li>
            <li><a href="#" className="delete"></a></li>
        </ul>
    </td>
    <td>Иванова Анна Владимировна</td>
    <td className="status1"><span>Учится</span></td>
    <td>Бакалавр 4 курс</td>
    <td data-label="Институт:">Дизайн архитектурной среды</td>
    <td data-label="Направление подготовки:">Дизайн архитектурной среды</td>
    <td data-label="Почта:">example@example.com</td>
    <td data-label="Номер группы:">3331506/90001</td>
</tr>
<tr>
    <td>
        <ul className="unstyled">
            <li><a href="#" className="edit"></a></li>
            <li><a href="#" className="delete"></a></li>
        </ul>
    </td>
    <td>Иванова Анна Владимировна</td>
    <td className="status2"><span>Отчислен</span></td>
    <td>Бакалавр 4 курс</td>
    <td data-label="Институт:">Дизайн архитектурной среды</td>
    <td data-label="Направление подготовки:">Дизайн архитектурной среды</td>
    <td data-label="Почта:">example@example.com</td>
    <td data-label="Номер группы:">3331506/90001</td>
</tr>
<tr>
    <td>
        <ul className="unstyled">
            <li><a href="#" className="edit"></a></li>
            <li><a href="#" className="delete"></a></li>
        </ul>
    </td>
    <td>Иванова Анна Владимировна</td>
    <td className="status3"><span>Академ</span></td>
    <td>Бакалавр 4 курс</td>
    <td data-label="Институт:">Дизайн архитектурной среды</td>
    <td data-label="Направление подготовки:">Дизайн архитектурной среды</td>
    <td data-label="Почта:">example@example.com</td>
    <td data-label="Номер группы:">3331506/90001</td>
</tr>
<tr>
    <td>
        <ul className="unstyled">
            <li><a href="#" className="edit"></a></li>
            <li><a href="#" className="delete"></a></li>
        </ul>
    </td>
    <td>Иванова Анна Владимировна</td>
    <td className="status1"><span>Учится</span></td>
    <td>Бакалавр 4 курс</td>
    <td data-label="Институт:">Дизайн архитектурной среды</td>
    <td data-label="Направление подготовки:">Дизайн архитектурной среды</td>
    <td data-label="Почта:">example@example.com</td>
    <td data-label="Номер группы:">3331506/90001</td>
</tr>
<tr>
    <td>
        <ul className="unstyled">
            <li><a href="#" className="edit"></a></li>
            <li><a href="#" className="delete"></a></li>
        </ul>
    </td>
    <td>Иванова Анна Владимировна</td>
    <td className="status1"><span>Учится</span></td>
    <td>Бакалавр 4 курс</td>
    <td data-label="Институт:">Дизайн архитектурной среды</td>
    <td data-label="Направление подготовки:">Дизайн архитектурной среды</td>
    <td data-label="Почта:">example@example.com</td>
    <td data-label="Номер группы:">3331506/90001</td>
</tr>
<tr>
    <td>
        <ul className="unstyled">
            <li><a href="#" className="edit"></a></li>
            <li><a href="#" className="delete"></a></li>
        </ul>
    </td>
    <td>Иванова Анна Владимировна</td>
    <td className="status1"><span>Учится</span></td>
    <td>Бакалавр 4 курс</td>
    <td data-label="Институт:">Дизайн архитектурной среды</td>
    <td data-label="Направление подготовки:">Дизайн архитектурной среды</td>
    <td data-label="Почта:">example@example.com</td>
    <td data-label="Номер группы:">3331506/90001</td>
</tr>
<tr>
    <td>
        <ul className="unstyled">
            <li><a href="#" className="edit"></a></li>
            <li><a href="#" className="delete"></a></li>
        </ul>
    </td>
    <td>Иванова Анна Владимировна</td>
    <td className="status2"><span>Отчислен</span></td>
    <td>Бакалавр 4 курс</td>
    <td data-label="Институт:">Дизайн архитектурной среды</td>
    <td data-label="Направление подготовки:">Дизайн архитектурной среды</td>
    <td data-label="Почта:">example@example.com</td>
    <td data-label="Номер группы:">3331506/90001</td>
</tr>
<tr>
    <td>
        <ul className="unstyled">
            <li><a href="#" className="edit"></a></li>
            <li><a href="#" className="delete"></a></li>
        </ul>
    </td>
    <td>Иванова Анна Владимировна</td>
    <td className="status2"><span>Отчислен</span></td>
    <td>Бакалавр 4 курс</td>
    <td data-label="Институт:">Дизайн архитектурной среды</td>
    <td data-label="Направление подготовки:">Дизайн архитектурной среды</td>
    <td data-label="Почта:">example@example.com</td>
    <td data-label="Номер группы:">3331506/90001</td>
</tr>
<tr>
    <td>
        <ul className="unstyled">
            <li><a href="#" className="edit"></a></li>
            <li><a href="#" className="delete"></a></li>
        </ul>
    </td>
    <td>Иванова Анна Владимировна</td>
    <td className="status1"><span>Учится</span></td>
    <td>Бакалавр 4 курс</td>
    <td data-label="Институт:">Дизайн архитектурной среды</td>
    <td data-label="Направление подготовки:">Дизайн архитектурной среды</td>
    <td data-label="Почта:">example@example.com</td>
    <td data-label="Номер группы:">3331506/90001</td>
</tr>*/
