import React from "react";
import FilterAccordion from "@pages/CareersPage/components/FilterAccordion";
import InstitutesFilterSelect from "@pages/RatingPage/components/InstitutesFilterSelect";
import QualificationsFilterSelect from "@pages/RatingPage/components/QualificationsFilterSelect";
import ProgramsFilterSelect from "@pages/RatingPage/components/ProgramsFilterSelect";
import DirectionsFilterSelect from "@pages/RatingPage/components/DirectionsFilterSelect";
import ProfessionsFilterSelect from "@pages/RatingPage/components/ProfessionsFilterSelect";
import FilterSelect from "@pages/RatingPage/components/FilterSelect";

const StudentsFilters = ({collapseFilter,setCollapseFilter,filters,handleFilterSelect,handleApplyClick,handleResetClick}) => {

    return (
        <div className={collapseFilter ? "filter_block active" : "filter_block opened active newStyle"}>
            <div className="filter_header">
                <h2>Фильтры</h2>
                <p
                    className="i-right_xs_after collapse_button"
                    onClick={() => setCollapseFilter(true)}
                >
                    Свернуть
                </p>
            </div>
            <FilterAccordion title={"Университет"}>
                <div className="filter_item" style={{width: "100%"}}>
                    <FilterSelect
                        selectedItem={[0]}
                        disabled
                        values={[{name:"СПбПУ",id:0}]}
                        loading={null}
                        placeholder="СПбПУ"
                        filterName="wtf"
                        filters={filters}
                        scrollId={"wtf"}
                        onFilterSelect={()=>{}}
                    />
                   {/* <InstitutesFilterSelect
                        filters={filters}
                        onFilterSelect={handleFilterSelect}
                    />*/}
                </div>
            </FilterAccordion>
            <FilterAccordion title={"Институт"}>
                <div className="filter_item" style={{width: "100%"}}>

                    <InstitutesFilterSelect
                        filters={filters}
                        onFilterSelect={handleFilterSelect}
                    />
                </div>
            </FilterAccordion>
            <FilterAccordion title={"Уровень образования"}>

                <div className="filter_item" style={{width: "100%"}}>
                    <QualificationsFilterSelect
                        filters={filters}
                        onFilterSelect={handleFilterSelect}
                    />
                </div>
            </FilterAccordion>
            <FilterAccordion title={"Образовательная программа"}>
                <div className="filter_item" style={{width: "100%"}}>
                    <ProgramsFilterSelect
                        filters={filters}
                        onFilterSelect={handleFilterSelect}
                    />
                </div>
            </FilterAccordion>
            <FilterAccordion title={"Направление образования"}>

                <div className="filter_item" style={{width: "100%"}}>
                    <DirectionsFilterSelect
                        filters={filters}
                        onFilterSelect={handleFilterSelect}
                    />
                </div>
            </FilterAccordion>

          {/*  <FilterAccordion title={"Профессии"}>
                <div className="filter_item" style={{width: "100%"}}>
                    <ProfessionsFilterSelect
                        filters={filters}
                        onFilterSelect={handleFilterSelect}
                    />
                </div>
            </FilterAccordion>*/}

            <div className="button_block">
                <input
                    className="main_button turquoise_button"
                    type="submit"
                    value="Применить"
                    onClick={handleApplyClick}
                />
                <input
                    className="main_button gray_button"
                    type="reset"
                    value="Сбросить всё"
                    onClick={handleResetClick}
                />
            </div>
        </div>

    )
}
export default StudentsFilters;