import "../assets/styles/pages/notfound.scss";
import React from "react";
import "../assets/styles/components/404_505.scss"
import error from "../assets/svg/error.svg";

const NotfoundPage = () => (
    <div className="error-page">
        <div className="error-block">
            <div className="error-image">
                <img src={error} alt=""/>
            </div>
            <div className="error-content">
                <h1>404</h1>
                <span>Ой, страница не найдена!</span>
                <p>Что-то пошло не так! Но ничего страшного, всегда можно начать с начала и попытаться еще раз. Давайте
                    вернемся на главную.</p>
                <a href="/" className="btn back-home">Вернуться на главную</a>
            </div>
        </div>
    </div>
);

export default NotfoundPage;
