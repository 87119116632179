import React from "react";

import FilterSelect from "./FilterSelect";

import { usePagination } from "@hooks/usePagination";
import { useVariables } from "@hooks/useVariables";

import {GET_EDU_PROGRAMS, STUDENT_INFORMATION} from "@api/endpoints";

const SCROLL_ELEMENT_ID = "programs_list";

const ProgramsFilterSelect = ({ filters, onFilterSelect }) => {
  const requestOptionsGET = useVariables().requestOptionsGET;
  const { data: programs, loading } = usePagination(
    STUDENT_INFORMATION.getEducationPrograms,
    requestOptionsGET,
    "name",
    SCROLL_ELEMENT_ID,
  );

  return (
    <FilterSelect
      values={programs}
      loading={loading}
      field={"educationProgramId"}
      placeholder="Образовательная программа"
      filterName="programId"
      filters={filters}
      scrollId={SCROLL_ELEMENT_ID}
      onFilterSelect={onFilterSelect}
    />
  );
};

export default React.memo(ProgramsFilterSelect);
