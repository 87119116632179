import React, {useRef, useState} from 'react';
import {toast} from "react-toastify";
import {
    displayDateDD_MM_YYYY,
    displayDateDDMMYYYY,
    displayDateYYYY_MM_DD,
    displayDateYYYY_MM_ddThh_mm
} from "@utils/dateFormat";


const CreateOrEditModal = ({activeModal, setActiveModal, selectedAuction,handleSubmit}) => {
    const [date, setDate] = useState({
        from: selectedAuction ? displayDateYYYY_MM_ddThh_mm(selectedAuction.startDate) :null,
        to: selectedAuction ? displayDateYYYY_MM_ddThh_mm(selectedAuction.endDate) :null,
    });
    const add = (e) =>{
        e.preventDefault();
        const form = new FormData(e.currentTarget);
        const data = {
            startDate:date.from,
            endDate:date.to,
            startBid:parseInt(form.get('startBid')),
            count:parseInt(form.get('count')),
        }
        if(data.startDate > data.endDate){
            toast.error("Дата начала не может быть больше даты окончания", {
                position: toast.POSITION.TOP_CENTER,
            });
            return false;
        }
        if(!data.startBid || !data.count || !data.startDate || !data.endDate){
            toast.error("Заполните все поля", {
                position: toast.POSITION.TOP_CENTER,
            });
            return false;
        }
        data.startDate = displayDateYYYY_MM_DD(data.startDate);
        data.endDate = displayDateYYYY_MM_DD(data.endDate);
        handleSubmit(data);
    }
    return (
        <form onSubmit={add} className="create-auction-modal">
            <div className="input-block dates">
                <div className="search-blk input-blk">
                    <label>Дата начала</label>
                    <input
                        value={date.from}
                        onChange={(e) => setDate({...date, from: e.target.value})}
                        className="form_field"
                        name="startDate"
                        type="datetime-local"
                        placeholder="Введите дату начала"
                    />
                </div>
                <div className="search-blk input-blk">
                    <label>Дата окончания</label>
                    <input
                        value={date.to}
                        onChange={(e) => setDate({...date, to: e.target.value})}
                        className="form_field"
                        name="endDate"
                        type="datetime-local"
                        placeholder="Введите дату окончания"
                    />
                </div>
            </div>

            <div className="search-blk create-blk">
                <label htmlFor="Name">Начальная ставка</label>
                <input defaultValue={selectedAuction?.startBid} type="number" className="form_field" placeholder="300" name="startBid"/>
            </div>
            <div className="search-blk create-blk">
                <label htmlFor="Name">Количество</label>
                <input type="number" className="form_field" defaultValue={selectedAuction?.count} placeholder="300" name="count"/>
            </div>
            <div className="buttons_block">
                <button
                    className="secondary_button gray_button"
                    onClick={(event) => {
                        setActiveModal(false)
                    }}
                >
                    Отмена
                </button>
                <button
                    type={'submit'}
                    className="secondary_button turquoise_button">
                    Сохранить
                </button>
            </div>
        </form>
    )
}
export default CreateOrEditModal;