import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";

import {useVariables} from "@hooks/useVariables";

import Loader from "@components/Loader";
import ProductItem from "./components/ProductItem";

import {GET_PRODUCT, SHOP} from "@api/endpoints";

import {ReactComponent as LeftArrowIcon} from "@assets/svg/left-arrow-icon.svg";

const ShopItem = () => {
    const params = useParams();
    const productId = params.productId;
    const [product, setProduct] = useState();
    const previousLocationPathname = useLocation().state?.previousLocationPathname
    const requestOptionsGET = useVariables().requestOptionsGET;

    const [loading, setLoading] = useState(false);
    const getProduct = () => {
        setLoading(true);
        fetch(SHOP.GET_PRODUCT_BY_ID(productId), requestOptionsGET)
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                setProduct(json);
                return json;
            })
            .catch(() => console.log("Произошла ошибка"))
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (productId) {
            getProduct(productId);
        }
    }, [productId]);

    console.log("#### productId", productId);

    const navigate = useNavigate();
    const handleHeaderClick = () => {
        if (previousLocationPathname)
            navigate(previousLocationPathname);
        else
            navigate("/profile/shop");
    };

    if (loading || !product) return <Loader/>;

    return (
        <Wrapper>
            <Header onClick={handleHeaderClick}>
                <LeftArrowIcon/>
                <HeaderLabel>Товар</HeaderLabel>
            </Header>
            <ProductItem product={product} onRefresh={getProduct}/>
        </Wrapper>
    );
};

export default React.memo(ShopItem);

const Wrapper = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 32px;

  cursor: pointer;
`;

const HeaderLabel = styled.span`
  // Товар
  color: #333333;
  font-size: 24px;
  font-family: Ubuntu;
  font-weight: 500;
  word-wrap: break-word;
  margin-left: 10px;
`;
