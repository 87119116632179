import React, {useRef, useState} from 'react';
import {NavLink} from "react-router-dom";
import ButtonWithLoading from "@components/ButtonWithLoading";
import {ADMIN_STUDENTS} from "@api/endpoints";
import {toast} from "react-toastify";
import CustomTabs from "@components/CustomTabs";
import {useVariables} from "@hooks/useVariables";
import InputFile from "@components/InputFile";
import LogsTable from "@pages/AdminStudentList/CreateModal/LogsTable";
import {usePagination} from "@hooks/usePagination";
import Loader from "@components/Loader";
import {validateEmail} from "@utils/other";

const LoadModal = ({setActiveModal,subTitle, uploadFields = null, loadToDB, editList}) => {
    const [modalEmail, setModalEmail] = useState("")
    const requestGet = useVariables().requestOptionsGET;
    const statuses = usePagination(ADMIN_STUDENTS.GET_FILES_LOGS, requestGet, "", "logTable")
    const requestPost = useVariables().constRequestOptionsPOST;
    const [users, setUsers] = useState(null);
    const [loadFile, setLoadFile] = useState(false)

    function decode_utf8(s) {
        return decodeURIComponent(escape(s));
    }

    const readFile = (e) => {
        setLoadFile(true);
        const reader = new FileReader();
        let name = e.target.files[0].name.split(/(\\|\/)/g).pop();
        reader.onload = () => {
            let res = decode_utf8(reader.result).split(/\r|\n|\r\n/);
            res = res.splice(1, res.length);
            res = res.filter(item => item).map(item => {
                if (item)
                    return item.split(";")
            });
            console.log(res)
            res = res.filter(item => item.find(x => x))
            res = res.map(item => {
                if (item) {
                    if (!uploadFields) {
                        return {
                            "firstName": item[0],
                            "middleName": item[1],
                            "secondName": item[2],
                            "group": item[3],
                            "email": item[4],
                            "institute": item[5],
                            "educationDirection": item[6],
                            "educationProgram": item[7],
                            "qualification": item[8],
                        }
                    } else {
                        let obj = {};
                        uploadFields.map((field,key) => {
                            obj[field] = item[key];
                        })
                        return obj;
                    }
                }
            })
            setUsers(res);
            if (editList && (res.filter(x => Object.values(x).find(d => !d)).length > 0 || res.filter(x => !validateEmail(x.email)).length > 0)) {
                setLoadFile("error")
            } else {
                setLoadFile(false)
                setActiveModal(false)
                loadToDB(res,name)
            }
        }

        reader.readAsBinaryString(e.target.files[0])
    }
    if (loadFile === "error")
        return (
            <div className={`search-blk create-blk form-error`}>
                <label htmlFor="" className="error">В файле найдены ошибки. Для загрузки на сервер, нужно их
                    исправить.</label>
                <div className="modal-footer">
                    <button onClick={() => setActiveModal(false)} className="btn btn-grey btn-dismiss">Отменить</button>
                    <button onClick={() => editList(users)} className="btn btn-blue modal-link">Исправить</button>

                </div>
            </div>
        )
    else if (loadFile)
        return (
            <div>
                <Loader/>
            </div>
        )
    else
        return (
            <div className="tabs ">

                <div className="tab-content">

                    <div className={"active"}>
                        <h3>Загрузить базу данных {subTitle ? subTitle : "студентов"}</h3>
                        <InputFile
                            onChangeHandler={readFile}
                            chooseFileLabel={"Выберите файл в csv формате или перетащите сюда"}
                            accept={"text/csv"}
                            name={"a"}
                        />
                    </div>


                </div>
                <div className="modal-footer">
                    <button onClick={() => setActiveModal(false)} className="btn btn-grey btn-dismiss">Отменить</button>
                    {/* <NavLink to={"/admin/student-create"} state={{email: modalEmail}}>
                    <button type="button" className="btn btn-blue modal-link"
                            data-modal="#avatarModal">Добавить
                    </button>
                </NavLink>*/}
                </div>
            </div>

        )

}
export default LoadModal;