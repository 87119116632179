import React from'react';
import test_img from "@assets/images/avatar-default.png";
import {useDownloadFile} from "@hooks/useDownloadFile";
import {DOWNLOAD_FILE} from "@api/endpoints";
import defaultProduct from "@assets/images/product-default.png";
import {useSelector} from "react-redux";

const StudentBidItem = ({item}) => {
    const student = item.student;
    const user = useSelector(state => state.user.user);
    const img = useDownloadFile(
        DOWNLOAD_FILE,
        student.avatarId,
        defaultProduct,
    ).file;
    return(
        <li  className="rating_item">
            <div className="student_item">
                <img className="avatar" src={img ? img : test_img } alt="студент" />
                <div className="student_info">
                        <p>{student.secondName} {student.firstName} {student.middleName}
                            {student.studentId === user.id && <p><span>(Это вы)</span></p>}
                        </p>

                    {/*<p>{"Прикладная информатика" || ""}</p>*/}
                </div>
                <span className="points">{item.bidAmount}</span>
            </div>
        </li>
    )

}
export default StudentBidItem;