import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Navigate, useLocation } from "react-router-dom";

import {
  loadUserProfile,
  loginOther,
  loginStudent,
  logoutUser,
  reloadUser,
} from "../store/asyncActions/user";
import {
  loadOffersReferenceInfo, loadUserOffers,
} from "@store/asyncActions/offers"

import Loader from "../components/Loader";

import { VERIFY_TOKEN } from "../api/endpoints";

export const useAuth = (allowRedirect = false) => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAdmin = user.roles.includes("ADMIN");

  const location = useLocation();

  useEffect(() => {
    if (user.authed && allowRedirect) {
      if (isAdmin) {
        navigate("/admin");
      } else {
        const pathBeforeLogin = location.hash && location.hash !== "#1" && location.hash !== "#0"
          ? location.hash.slice(1)
          : "/profile";
        navigate(pathBeforeLogin);
      }
    }
  }, [user.status]);

  useEffect(() => {
    if (user.accessToken) {
      const { newAvatar, ...cleanedUser } = user;
      localStorage.setItem("user", JSON.stringify(cleanedUser));
    }
  }, [user.accessToken]);

  return {
    user,
    loginStudent(token) {
      return dispatch(loginStudent(token));
    },
    loginOther(login, password) {
      return dispatch(loginOther(login, password));
    },
    logout() {
      localStorage.removeItem("user");
      return dispatch(logoutUser());
    },
    loadUserData(user) {
      return dispatch(loadUserProfile(user));
    },
  };
};

export const useSavedUser = () => {
  const { user, logout } = useAuth();
  const dispatch = useDispatch();

  const userFromStorageAsString = localStorage.getItem("user");
  const savedUser = userFromStorageAsString
    ? JSON.parse(userFromStorageAsString)
    : user;

  const [tokenChecked, setTokenChecked] = useState(false);
  const [tokenError, setTokenError] = useState(false);
  const isCompany = savedUser.roles.includes("COMPANY");
  useEffect(() => {
    if (savedUser.accessToken) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token: savedUser.accessToken }),
      };

      fetch(VERIFY_TOKEN, requestOptions)
        .then((response) => response.json())
        .then((json) => {
          setTokenChecked(true);
          if (json.status === "Success") {
            dispatch(loadOffersReferenceInfo(savedUser));
            //TODO загружать офферы пользователя при загрузке страницы
            //dispatch(loadUserOffers(savedUser));
            dispatch(reloadUser(savedUser));
            dispatch(loadUserProfile(savedUser));
          } else if (json.status === "Error") {
            logout();
            setTokenError(true);
          }
        })
        .catch((e) => console.log("Произошла ошибка",e));
    } else {
      setTokenChecked(true);
      setTokenError(true);
    }
  }, [savedUser.accessToken]);

  return { tokenChecked, tokenError, savedUser,isCompany };
};

export const IsAdmin = () =>{
  const { savedUser } = useSavedUser();
  return savedUser.roles.filter((realRole) => ["ADMIN"].includes(realRole)).length > 0
};
export const IsCompany = () => {
  const { savedUser } = useSavedUser();
  return savedUser.roles.filter((realRole) => ["COMPANY"].includes(realRole)).length > 0
}
export const IsStudent = () => {
  const { savedUser } = useSavedUser();
  return savedUser.roles.filter((realRole) => ["STUDENT"].includes(realRole)).length > 0
}
export const IsMyProfileStudent = (id) => {
  const { savedUser } = useSavedUser();
  return savedUser.roles.filter((realRole) => ["STUDENT"].includes(realRole)).length > 0 && (savedUser.id === id || !id)
}

export const RequireAuth = ({ children, roles }) => {
  const { tokenChecked, tokenError, savedUser } = useSavedUser();

  const checkRoles = (necessary, real) => {
    return (
      necessary &&
      real &&
      real.filter((realRole) => necessary.includes(realRole)).length > 0
    );
  };

  const location = useLocation();
  const currentPath = useMemo(() => location.pathname, [location]);

  if (!tokenChecked) return <Loader />;

  const necessaryRole = checkRoles(roles, savedUser.roles);

  return savedUser.authed === true && necessaryRole && !tokenError ? (
    children
  ) : (
      savedUser.authed?
    <Navigate to={`/`} replace />:
    <Navigate to={`/login#${currentPath}`} replace />
  );
};
