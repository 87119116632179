import React from "react"
import {NavLink} from "react-router-dom";
import {offersLanguages} from "@utils/languageList";


const CareerBlockLoad = () => {
    return (
        <div className={`col-block careen-load`}>

                <div className={`offer-content-block blks`}>
                    <div className="offer-title vacancy-title">

                    </div>
                    <span className="name"></span>

                    <h3></h3>
                    <div className="details">
                        <ul className="unstyled">

                        </ul>
                    </div>
                </div>
        </div>
    )
}

export default CareerBlockLoad