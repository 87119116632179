import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { useDownloadFile } from "@hooks/useDownloadFile";
import getProductStatus from "@utils/getProductStatus";

import defaultProduct from "@assets/images/product-default.png";

import { DOWNLOAD_FILE } from "@api/endpoints";

const ProductListItem = ({ product, openProductItemModal, onDelete }) => {
  const user = useSelector((state) => state.user.user);
  const isCompany = user.roles.includes("COMPANY");

  const img = useDownloadFile(
    DOWNLOAD_FILE,
    product.imgId,
    defaultProduct,
  ).file;

  const handleDeletePress = (e) => {
    console.log("### handleDeletePress");
    e.stopPropagation();
    e.preventDefault();
    onDelete(product.productId);
  };

  const handleUlClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div
      className="product_element shadow_block"
      onClick={() => openProductItemModal?.(product, img)}
    >
      <div className="product_title">
        <h3>{product.name}</h3>
        {onDelete && isCompany ? (
          <span className="i-hidden_menu menu_button" onClick={handleUlClick}>
            <div className="hidden_menu">
              <ul className="hidden_menu_content" onClick={handleUlClick}>
                <li onClick={handleDeletePress}>
                  <DeleteButton>Удалить</DeleteButton>
                </li>
              </ul>
            </div>
          </span>
        ) : null}
      </div>
      {isCompany ? (
        <div className="bubble draft">{getProductStatus(product)?.name}</div>
      ) : null}
      <div className="image">
        <img src={img} alt="product" />
      </div>
    </div>
  );
};

export default React.memo(ProductListItem);

const DeleteButton = styled.button`
  flex: 1;
`;
