import { useSelector } from "react-redux";

import { usePagination } from "./usePagination";
import { useVariables } from "./useVariables";

import { GET_ALL_PRODUCT_LIST, GET_PRODUCT_LIST } from "@api/endpoints";
import { useMemo } from "react";
import {IsCompany} from "@hooks/useAuth";

export const useShop = (params) => {
  console.log("### useShop", params);
  const { productStatus, name,companyId } = useMemo(() => params || {}, [params]);
  const user = useSelector((state) => state.user.user);
  const isCompany = IsCompany()

  console.log("### productStatus", productStatus);

  const searchParams = useMemo(() => {
    const query = {};

    if (productStatus) {
      query.productStatus = productStatus;
    } else {
      query.isAll = true;
    }
    console.log("~~~~~~ name", name);

    if (name) {
      query.name = name;
    }
    if(companyId)
      query.companyId = companyId;
    if(isCompany)
      query.companyId = user.id;

    return query;
  }, [productStatus,companyId, name]);
  console.log("### searchParams", searchParams);

  const requestOptionsGET = useVariables(searchParams).requestOptionsGET;
  console.log("### requestOptionsPOST", requestOptionsGET);
  const { data: products, refresh,loading } = usePagination(
      GET_PRODUCT_LIST,
      requestOptionsGET,
    "productId,desc",
      null,
      searchParams
  );

  // useEffect(() => {
  //   console.log("### products", products);
  // }, [products]);

  return {
    loading,
    products,
    refresh,
  };
};
