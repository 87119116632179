import React from "react";

import { useDownloadFile } from "@hooks/useDownloadFile";
import getProductStatus from "@utils/getProductStatus";

import defaultProduct from "@assets/images/product-default.png";

import { DOWNLOAD_FILE } from "@api/endpoints";
import {NavLink} from "react-router-dom";

const ProductCompanyListItem = ({ product }) => {
  const img = useDownloadFile(
    DOWNLOAD_FILE,
    product.imgId,
    defaultProduct,
  ).file;

  return (
    <li className="list_item">
        <NavLink to={`/profile/shop/edit/${product.productId}`} state={{previousLocationPathname:"/profile"}}>
            <div className="image">
                <img src={img} alt="product_image" />
            </div>
            <div className="list_info">
                <p className="name">{product.name}</p>
                <p className="description">{product.description}</p>
                <button className="status">{getProductStatus(product)?.name}</button>
            </div>
        </NavLink>

    </li>
  );
};

export default React.memo(ProductCompanyListItem);
