import React, {useEffect, useState} from "react"
import {usePagination} from "@hooks/usePagination";
import {
    GET_FEEDBACK_FROM_ADMIN_PAGINATION,
    GET_FEEDBACK_FROM_ALL_COMPANIES,
    GET_FEEDBACK_FROM_ALL_COMPANIES_PAGINATION,
    STUDENT_INFORMATION
} from "@api/endpoints";
import {useParams} from "react-router-dom";
import {useVariables} from "@hooks/useVariables";
import FeedBackRow from "@pages/AdminCreateStudent/FeedBacks/FeedBackRow";


const FeedBack = ({studentId})=>{
    const requestGet = useVariables().requestOptionsGET
    const [feedbacks,setFeedbacks] = useState([])
    useEffect(()=>{
        fetch(GET_FEEDBACK_FROM_ADMIN_PAGINATION(studentId),requestGet).then(res => res.json()).then(data => setFeedbacks(data))
    },[])
    useEffect(() => {
        console.log("feedbacks",feedbacks)
    },[feedbacks])
    return (
        <div className="active">
            <h3 className="table-title">Оценки от организаций</h3>
            <div className="table-block">
                <div className="table-popover">
                    <div className="popover-blk">
                        <p style={{color:"white"}}>Число обозначает количество компетенций, получивших данную оценку</p>
                    </div>
                </div>
                <table className="tables table-grade">
                    <thead>
                    <tr>
                        <th>Организация</th>
                        <th>Хорошо</th>
                        <th>Нейтрально</th>
                        <th>Плохо</th>
                    </tr>
                    </thead>
                    <tbody>
                    {feedbacks && feedbacks.map((feedBack,keyu) => (
                        <FeedBackRow key={keyu} feedback={feedBack}/>
                    ))}

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default FeedBack