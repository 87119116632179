import React from "react";

import FilterSelect from "./FilterSelect";

import { usePagination } from "@hooks/usePagination";
import { useVariables } from "@hooks/useVariables";

import {LOAD_QUALIFICATION, STUDENT_INFORMATION} from "@api/endpoints";

const SCROLL_ELEMENT_ID = "qualifications_list";

const QualificationsFilterSelect = ({ filters, onFilterSelect }) => {
  const requestOptionsGET = useVariables().requestOptionsGET;
  const { data: qualifications, loading } = usePagination(
    STUDENT_INFORMATION.getQualifications,
    requestOptionsGET,
    "name",
    SCROLL_ELEMENT_ID,
  );

  return (
    <FilterSelect
      values={qualifications}
      loading={loading}
      field={"qualificationId"}
      placeholder="Уровень получаемого образования"
      filterName="qualificationId"
      filters={filters}
      scrollId={SCROLL_ELEMENT_ID}
      onFilterSelect={onFilterSelect}
    />
  );
};

export default React.memo(QualificationsFilterSelect);
