import React, {useEffect, useState} from "react";

import {useAuth} from "@hooks/useAuth";

import LoginForm from "@components/LoginForm";
import RegisterForm from "@components/RegisterForm";
import RegisteredSuccess from "@components/RegisteredSuccess";
import ResetPassword from "@components/ResetPassword";
import {REGISTER_COMPANY, RESET_PASSWORD_STUDENT} from "@api/endpoints";
import {toast} from "react-toastify";

const LoginBlock = () => {
    const {loginOther} = useAuth(true);
    const [sentEmail, setSentEmail] = useState("");
    const [activeBlock, setActiveBlock] = useState(0)
    const [registered, setRegistered] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [checkIfOpenByHash, setCheckIfOpenByHash] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        const form = new FormData(event.target);

        loginOther(form.get("login"), form.get("password"));

        event.target.reset();
    };

    useEffect(() => {
        if (window.location.hash && window.location.hash === "#1") {
            let tab = parseInt(window.location.hash.substring(1));
            setActiveTab(tab)
            return;
        } else {
        }
    }, [activeTab]);

    const handleSubmitRegister = async event => {
        let errors = {};
        const form = new FormData(event);
        const data = {
            "inn": form.get("inn"),
            "longName": form.get("name"),
            "contactPerson": {
                "firstName": form.get("first_name"),
                "secondName": form.get("second_name"),
                "email": form.get("email"),
            }
        }
        if (!data.inn) {
            errors.inn = "Введите ИНН";
        }else if(data.inn.toString().length < 10 || !/^\d+$/.test(data.inn)) {
            errors.inn = "Введите ИНН от 10 до 12 цифр";
            toast.error("Введите ИНН от 10 до 12 цифр", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        if (!data.longName)
            errors.longName = "Полное название организации";
        if (!data.contactPerson.firstName)
            errors.firstName = "Введите имя";
        if (!data.contactPerson.secondName)
            errors.secondName = "Введите фамилию";
        if (!data.contactPerson.email)
            errors.email = "Введите email ответственного";
        if (Object.values(errors).find(error => error))
            return {status: false, errors};
        setSentEmail(form.get("email"));
        const config = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }

        let response = await fetch(REGISTER_COMPANY, config)
            .catch((error) => {
                toast.error("Произошла ошибка при отправке запроса", {
                    position: toast.POSITION.TOP_CENTER,
                });
                return {status: false, errors};
            })
        if (response.status === 400) {
            toast.error("Произошла ошибка при отправке запроса", {
                position: toast.POSITION.TOP_CENTER,
            });
            return {status: false, errors};
        } else {
            setActiveBlock(2);
            event.target.reset();
        }

    }

    return (
        <div className="forms_block newStyle">
            <div className="right_block">
                <div className={"for_companies show"}>
                    {activeBlock === 0 && <LoginForm
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        setActiveBlock={setActiveBlock}
                        title="Вход"
                        handlerFormSubmit={handleSubmit}
                        tooltipId="company_tooltip"
                        tooltip="Данные для входа отправлены на почту"
                        theme="dark"
                    />}
                    {activeBlock === 1 &&
                        <RegisterForm back={() => setActiveBlock(0)} handlerFormSubmit={handleSubmitRegister}/>
                    }
                    {activeBlock === 2 && <RegisteredSuccess back={() => setActiveBlock(0)} sentEmail={sentEmail}/>}
                    {activeBlock === 3 &&
                        <ResetPassword setSentEmail={setSentEmail} back={() => {
                            setActiveBlock(0)
                        }} activeTab={activeTab}/>
                    }
                </div>
            </div>
        </div>
    );
};

export default React.memo(LoginBlock);
