import React, {useEffect, useState} from 'react';
import Collapse from "@pages/AdminCareersPage/components/Collapse";
import {usePagination} from "@hooks/usePagination";
import {ADMIN_STUDENTS, GET_PROFESSIONS_V2, STUDENT_INFORMATION} from "@api/endpoints";
import {useVariables} from "@hooks/useVariables";
import CustomSelect from "@components/CustomSelect";
import {studentsLanguages} from "@utils/languageList";

const Search = ({filters,refresh,setFilters}) => {

    const [limit,setLimit] = useState({size:1000})
    const [info, setInfo] = useState(null)
    useEffect(() => {
        fetch(ADMIN_STUDENTS.GET_REFERENCE_INFORMATION, requestGet).then(res => res.json()).then(res => setInfo(res))
    }, [])
    const requestGet = useVariables().requestOptionsGET;
    const institutes = usePagination(STUDENT_INFORMATION.getInstitutes,requestGet,"",null,limit).data
    const educationDirections = usePagination(STUDENT_INFORMATION.getEducationDirections,requestGet,"",null,limit).data
    const educationPrograms = usePagination(STUDENT_INFORMATION.getEducationPrograms,requestGet,"",null,limit).data
    const qualifications = usePagination(STUDENT_INFORMATION.getQualifications,requestGet,"").data
    const professions = usePagination(GET_PROFESSIONS_V2, requestGet, "",null,limit).data;

    const [studentsFilters,setStudentsFilters] = useState({});
    const onFiltersChange = (field,value) => {
        /*if(field === "status"){
            value =value.map((item) => {
                return info.studentStatuses.indexOf(item);
            })
        }*/
        setStudentsFilters({...studentsFilters, [field]:Array.isArray(value) ? value.join(",") : value})
    }
    const applyFilters = () => {
        //console.log(studentsFilters)
        setFilters({...studentsFilters})
    }
    const resetFilters = () => {
        setStudentsFilters({})
        setFilters({});
        refresh();
    }
    const selected = (value,field) =>{
        if(!value) return [];
        let data = [];
        if(field === "qualificationId")
            data = [...qualifications];
        else if(field === "id")
            data = [...professions];
        else if(field === "instituteId")
            data = [...institutes];
        else if(field === "educationDirectionId")
            data = [...educationDirections];
        else if(field === "educationProgramId")
            data = [...educationPrograms];
        if(!Array.isArray(value))
            value = value.split(",").map(i=>parseInt(i));
        return value.map(item=>{
            let vlv = data.find(x=>x[field] === item);
            if(vlv)
                return {label: vlv.name, value: vlv[field]}
        })
    }
    const getSelectedStatuses = (value) => {
        if(!value) return [];
        return value.split(",").map((item) => {

            return {label: studentsLanguages.studentStatuses[item], value: item}
        })
    }

    return (
        <Collapse
            header={"Поиск"}
        >
            <div style={{width: "100%"}}>

                <form action="" method="" className="search-form">
                    <div className="flex">
                        <div className="search-blk create-blk">
                            <label htmlFor="Name">Имя</label>
                            <input value={studentsFilters.firstName || ""}
                                   onChange={(e)=>{onFiltersChange("firstName",e.currentTarget.value)}}
                                   type="text" placeholder="Иван" id="Name"/>
                        </div>
                        <div className="search-blk create-blk">
                            <label htmlFor="Name">Фамилия</label>
                            <input
                                       value={studentsFilters.secondName || ""}
                                       onChange={(e)=>{onFiltersChange("secondName",e.currentTarget.value)}}
                                       type="text" placeholder="Иванович" id="Name"/>
                        </div>
                        <div className="search-blk create-blk">
                            <label htmlFor="Name">Отчество</label>
                            <input
                                value={studentsFilters.middleName || ""}
                                onChange={(e)=>{onFiltersChange("middleName",e.currentTarget.value)}}
                                type="text" placeholder="Иванов" id="Name"/>
                        </div>
                        <div className="search-blk create-blk">
                            <label htmlFor="Mail">Почта</label>
                            <input
                                value={studentsFilters.email || ""}
                                onChange={(e)=>{onFiltersChange("email",e.currentTarget.value)}}
                                type="text" placeholder="ivanov.ii@gmail.com" id="Mail"/>
                        </div>
                        <div className="search-blk create-blk">
                            <label htmlFor="Group">Номер группы</label>
                            <input
                                value={studentsFilters.group || ""}
                                onChange={(e)=>{onFiltersChange("group",e.currentTarget.value)}}
                                type="text" placeholder="3530901/90203" id="Group"/>
                        </div>
                        {info && info.studentStatuses &&
                            <div className="search-blk create-blk">
                                <label htmlFor="Status">Статус</label>
                                <CustomSelect
                                    placeholder={"Статус"}
                                    refValue={getSelectedStatuses(studentsFilters.status)}
                                    name="status"
                                    isMulti={true}
                                    onChange={(value) => onFiltersChange("status",value)}
                                    options={info?.studentStatuses.map((item) => {
                                        return {label: studentsLanguages.studentStatuses[item], value: item}
                                    })}
                                />
                            </div>
                        }
                        <div className="search-blk create-blk">
                            <label htmlFor="Level">Уровень получаемого образования</label>
                            <CustomSelect
                                placeholder={"Уровень образования"}
                                refValue={selected(studentsFilters.qualificationId || [],"qualificationId")}
                                name="qualificationId"
                                isMulti={true}
                                onChange={(value) => onFiltersChange("qualificationId",value)}
                                options={qualifications.map((item) => {
                                    return {label: item.name, value: item.qualificationId}
                                })}
                            />
                        </div>
                        <div className="search-blk create-blk">
                            <label htmlFor="Profession">Профессия</label>
                            <CustomSelect
                                placeholder={"Профессия"}
                                refValue={selected(studentsFilters.professionId || [],"id")}
                                onChange={(value) => onFiltersChange("professionId",value)}
                                name="professionId"
                                isMulti={true}
                                options={professions.map((item) => {
                                    return {label: item.name, value: item.id}
                                })}
                            />
                        </div>
                        <div className="search-blk create-blk">
                            <label htmlFor="Institute">Институт</label>
                            <CustomSelect
                                placeholder={"Институт"}
                                refValue={selected(studentsFilters.instituteId || [],"instituteId")}
                                onChange={(value) => onFiltersChange("instituteId",value)}
                                name="instituteId"
                                isMulti={true}
                                options={institutes.map((item) => {
                                    return {label: item.name, value: item.instituteId}
                                })}
                            />
                        </div>
                        <div className="search-blk create-blk">
                            <label htmlFor="Direction">Направление</label>
                            <CustomSelect
                                placeholder={"Направление"}
                                name="educationDirectionId"
                                refValue={selected(studentsFilters.educationDirectionId || [],"educationDirectionId")}
                                onChange={(value) => onFiltersChange("educationDirectionId",value)}
                                isMulti={true}
                                options={educationDirections.map((item) => {
                                    return {label: item.name, value: item.educationDirectionId}
                                })}
                            />
                        </div>
                        <div className="search-blk create-blk">
                            <label htmlFor="Program">Образовательная программа</label>
                            <CustomSelect
                                placeholder={"Образовательная программа"}
                                refValue={selected(studentsFilters.educationProgramId || [],"educationProgramId")}
                                onChange={(value) => onFiltersChange("educationProgramId",value)}
                                name="eduProgramId"
                                isMulti={true}
                                options={educationPrograms.map((item) => {
                                    return {label: item.name, value: item.educationProgramId}
                                })}
                            />
                        </div>
                    </div>
                    <div className="search-btn">
                        <button type="reset" onClick={resetFilters} className="btn btn-reset">Сбросить</button>
                        <button onClick={applyFilters} type="button" className="btn btn-blue">Применить</button>
                    </div>
                </form>
            </div>
        </Collapse>
    )
}
export default Search;