import "@assets/styles/pages/shop.scss";

import React, {useEffect, useState} from "react";
import "@assets/newpages/style.scss"
import {NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Information from "@pages/AdminCreateOrganization/Tabs/Information";
import Tariff from "@pages/AdminCreateOrganization/Tabs/Tariff/Tariff";
import Responsible from "@pages/AdminCreateOrganization/Tabs/Responsible/Responsible";
import Career from "@pages/AdminCreateOrganization/Tabs/Career";
import {secondDateBigger} from "@utils/dateFormat";
import {toast} from "react-toastify";
import {useVariables} from "@hooks/useVariables";
import {
    ADMIN_CREATE_COMPANY,
    ADMIN_GET_COMPANY,
    ADMIN_UPDATE_COMPANY,
    CREATE_OFFER, DOWNLOAD_FILE,
    UPDATE_AVATAR, UPDATE_AVTAR_V2
} from "@api/endpoints";
import ModalCustom from "@components/ModalCustom";
import CropModalContent from "@components/organisms/ProfileEditor/components/CropModalContent";
import {usePreviewFile} from "@hooks/usePreviewFile";
import {updateUserProfileAction} from "@store/reducers/userReducer";
import {useDownloadFile} from "@hooks/useDownloadFile";
import ButtonWithLoading from "@components/ButtonWithLoading";
import useTabHash from "@hooks/useTabHash";


const AdminCreateOrganization = () => {
    const {
        selectedFile: selectedAvatar,
        setSelectedFile: setSelectedAvatar,
        handleFileChange: handleImageChange,
    } = usePreviewFile(
        null,
        "image.*",
    );
    const user = useSelector((state) => state.user.user);

    const params = useParams();
    const previousLocationPathname = useLocation().state?.previousLocationPathname
    const navigate = useNavigate();
    const [avatarCropModalActive, setAvatarCropModalActive] = useState(false);

    const [organization, setOrganization] = useState({});
    const img = useDownloadFile(DOWNLOAD_FILE, organization?.avatarId).file;
    const requestGet = useVariables().requestOptionsGET;
    const requestPost = useVariables().constRequestOptionsPOST;
    const requestPut = useVariables().constRequestOptionsPUT;
    const [activeTab, setActiveTab] = useState(0);
    const [selectedProfession, setSelectedProfession] = useState([]);
    const [selectedAvatarImage, setSelectedAvatarImage] = useState();
    const handleAvatarFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.match("image.*")) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedAvatarImage({url: e.target.result});
            };
            reader.readAsDataURL(file);
        }
    };
    useTabHash(activeTab,setActiveTab,true)

    useEffect(() => {
        if (selectedAvatarImage) {
            setAvatarCropModalActive(true);
        }
    }, [selectedAvatarImage]);

    const [croppedImage, setCroppedImage] = useState();
    useEffect(() => {
        if (croppedImage) {
            setSelectedAvatar(croppedImage);
        }
    }, [croppedImage]);

    const selectProf = (id) => {
        /*console.log(id)
        /!*let id =  parseInt(profession.currentTarget.dataset.value);
        let profs = [...selectedProfession];*!/
        let profs = [...selectedProfession];
        if(profs.includes(id)){
            profs = profs.filter(item => item !== id);
        }else  profs.push(id);*/
        console.log(id)
        setSelectedProfession([...id]);
    }
    useEffect(() => {
        if (params.organizationId) {
            fetch(ADMIN_GET_COMPANY(params.organizationId), requestGet).then(res => res.json()).then(data => {
                let org = data;
                org.contactPerson = org.contactPersons[0];
                setOrganization(org);
                setSelectedProfession(org.professions.map(item=> item.professionId));


            }).catch(err => {
                toast.error("Произошла ошибка при открытии организации", {
                    position: toast.POSITION.TOP_CENTER,
                });
            })
        }
    }, [params.organizationId])
    const tabs = params.organizationId ? [
        "Основная информация",
        "Тариф",
        "Ответственный",
        "Карьера",
    ] : ["Основная информация"];

    const updateContacts = (contacts, edit = false) => {
        let org = {...organization};
        if (!edit) {
            org.contactPersons.push(contacts);
            toast.success(`Контакт добавлен`, {
                position: toast.POSITION.TOP_CENTER,
            });
        } else {
            toast.success(`Контакт обновлен`, {
                position: toast.POSITION.TOP_CENTER,
            });
            let index = org.contactPersons.findIndex(x => x.contactPersonId === contacts.contactPersonId);
            org.contactPersons[index] = contacts;
        }
        setOrganization(org);
    }
    const removeContact = (id) => {
        let org = {...organization};
        let index = org.contactPersons.findIndex(x => x.contactPersonId === id);
        org.contactPersons.splice(index, 1);
        setOrganization(org);
        toast.success(`Контакт удален`, {
            position: toast.POSITION.TOP_CENTER,
        });
    }
    const tabsElements = [
        <Information
            img={img}
            selectedAvatar={selectedAvatar}
            selectedAvatarImage={selectedAvatarImage}
            handleAvatarFileChange={handleAvatarFileChange}
            selectProf={selectProf}
            selectedProfession={selectedProfession}
            setSelectedProfession={setSelectedProfession}
            edit={params.organizationId} organization={organization} setOrganization={setOrganization}/>,
        <Tariff userId={params.organizationId}/>,
        <Responsible removeContact={removeContact} updateContacts={updateContacts} organization={organization}/>,
        <Career/>
    ]

    const validate = () => {
        let errors = [];
        if (!organization.inn) {
            errors.push({message: "Введите ИНН", field: "inn"});
        }
        if (!organization.name) {
            errors.push({message: "Введите название", field: "name"});
        }
        if (!organization.longName) {
            errors.push({message: "Введите полное название", field: "longName"});
        }
        /*if (!organization.contactPerson?.firstName) {
            errors.push({message: "Введите имя ответственного", field: "firstName"});//Формат работы
        }
        if (!organization.contactPerson?.secondName) {
            errors.push({message: "Введите фамилию ответственного", field: "firstName"});//Формат работы
        }
        if (!organization.contactPerson?.middleName) {
            errors.push({message: "Введите отчество ответственного", field: "firstName"});//Формат работы
        }
        if (!organization.contactPerson?.email) {
            errors.push({message: "Введите email ответственного", field: "firstName"});//Формат работы
        }*/
        /*  if (!organization.ogrn) {
              errors.push({message: "Введите ОГРН", field: "ogrn"});//Тип занятости
          }*/
        /*if (!organization.description) {
            errors.push({message: "Введите описание", field: "description"});//Тип занятости
        }
        if (!organization.requisites) {
            errors.push({message: "Введите реквизиты", field: "requisites"});//Тип занятости
        }*/
        if (!organization.activityIds) {
            errors.push({message: "Выберите область деятельности", field: "activityId"});//Тип занятости
        }

        errors.map(x => {
            toast.error(x.message, {
                position: toast.POSITION.TOP_CENTER,
            });
        })
        return errors.length === 0;
    }


    const updateAvatar = (companyId, requestOptions) => {
        fetch(UPDATE_AVTAR_V2(companyId), requestOptions)
            .then((response) => {
                if (response.ok) {
                    toast.success("Изменения аватара сохранены", {
                        position: toast.POSITION.TOP_CENTER,
                    });

                    // onClose?.();
                    return response.json();
                } else {
                    throw true;
                }
            })

            .catch((error) => {
                toast.error("Ошибка при обновление аватара", {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }
    const save = async () => {
        if (!validate())
            return;
        if (params.organizationId) {
            let org = {...organization};
            org.professionIds = [...selectedProfession];
            const cfg = requestPut(org);
            let data = await fetch(ADMIN_UPDATE_COMPANY(params.organizationId), cfg);
            data = await data.json();
            if (data.status === "Error") {
                toast.error(data.message[0], {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                if (selectedAvatar) {
                    fetch(selectedAvatar)
                        .then((response) => response.blob())
                        .then((blob) => {
                            // Update the formData object
                            const formData = new FormData();
                            formData.append("file", blob);

                            const requestOptionsAvatar = {
                                method: "POST",
                                headers: {
                                    // "Content-Type": "multipart/form-data",
                                    Authorization: `${user.type} ${user.accessToken}`,
                                },
                                body: formData,
                            };

                            updateAvatar(data.companyId, requestOptionsAvatar);
                        });
                }

                toast.success("Организация отредактирована", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } else {
            let org = {...organization};
            org.professionIds = [...selectedProfession];
            const cfg = requestPost(org);
            let data = await fetch(ADMIN_CREATE_COMPANY, cfg)
            data = await data.json();
            if (data.status === "Error" || data.status === 500 || data.status === 400) {
                toast.error(data.message[0], {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                if (selectedAvatar) {
                    fetch(selectedAvatar)
                        .then((response) => response.blob())
                        .then((blob) => {
                            // Update the formData object
                            const formData = new FormData();
                            formData.append("file", blob);

                            const requestOptionsAvatar = {
                                method: "POST",
                                headers: {
                                    // "Content-Type": "multipart/form-data",
                                    Authorization: `${user.type} ${user.accessToken}`,
                                },
                                body: formData,
                            };

                            updateAvatar(data.companyId, requestOptionsAvatar);
                        });
                }
                toast.success("Организация создана", {
                    position: toast.POSITION.TOP_CENTER,
                });
                navigate(`/admin/org-create/${data.companyId}`, {state: {previousLocationPathname: "/admin"}});
            }
        }
    }

    return (
        <div className="newStyle">
            <NavLink to={previousLocationPathname ? previousLocationPathname : (window.location.hash !== "#0") ? -1 : "/admin"} className="btn-back">
                {params.organizationId ? "Редактирование организации" : "Создание профиля организации"}</NavLink>
            <div className="tabs tabs-scroll">
                <ul style={{marginTop: 20}} className="tabsmenu">
                    {tabs.map((item, index) => (
                        <li key={index} className={`tab1 ${activeTab === index ? "active" : ""}`}
                            onClick={() => setActiveTab(index)}
                        >{item}</li>
                    ))}

                </ul>
                <div className="tab-content">
                    {tabsElements[activeTab]}
                </div>
                {activeTab !== 3 &&
                    <div className="table-btn student-btn btn-all">
                        {/*<button className="btn btn-grey">Отменить</button>*/}
                        <ButtonWithLoading
                            onClick={save}
                            type="submit" className="btn btn-blue"
                            title={params.organizationId ? "Сохранить" : "Создать"}
                        />
                        {/* <button onClick={save} >
                                {params.organizationId ? "Сохранить" : "Создать"}
                            </button>*/}
                    </div>
                }

            </div>
            <ModalCustom
                title="Подготовка Аватара"
                active={avatarCropModalActive}
                setActive={setAvatarCropModalActive}
            >
                <CropModalContent
                    image={selectedAvatarImage ? selectedAvatarImage.url : undefined}
                    onClose={(croppedImage) => {
                        setCroppedImage(croppedImage);
                        setAvatarCropModalActive(false);
                    }}
                />
            </ModalCustom>
        </div>
    );
};

export default React.memo(AdminCreateOrganization);
