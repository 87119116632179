import React, {Component, useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {usePagination} from "@hooks/usePagination";
import {SHOP} from "@api/endpoints";
import {useVariables} from "@hooks/useVariables";
import CropModalContent from "@components/organisms/ProfileEditor/components/CropModalContent";
import ModalCustom from "@components/ModalCustom";
import CreateOrEditModal from "@pages/AdminCreateOrEditProduct/Tabs/Auction/CreateOrEditModal";
import {toast} from "react-toastify";

const Auction = ({productId}) => {
    const requestGet = useVariables().requestOptionsGET
    const [deleteProduct,setDeleteProduct] = useState();
    const [filters,setFilters] = useState({productId: productId});
    const [activeModal,setActiveModal] = useState(false);
    const requestConstPost = useVariables().constRequestOptionsPOST
    const requestConstPut = useVariables().constRequestOptionsPUT
    const requestDelete = useVariables().requestOptionsDELETE
    const [selectedAuction,setSelectedAuction] = useState(null);
    const getAuctionStatus = (auction) => {
        switch (auction?.auctionStatus) {
            case "UPCOMING":
                return {
                    class: "opened",
                    name: "Создан",
                };
            case "PAST":
                return {
                    class: "closed",
                    name: "Закрыт",
                };
            case "CURRENT":
                return {
                    class: "open",
                    name: "Проходит",
                };
            default:
                return {
                    class: "",
                    name: "-",
                };
        }
    };
    useEffect(()=>{
        if(!activeModal && selectedAuction)
            setSelectedAuction(null)
    },[activeModal])
    const {data:auctions,refresh} = usePagination(SHOP.GET_AUCTIONS,requestGet,"",null,filters);
    const handleSubmit = (data) =>{
        data.productId = productId;
        const request = requestConstPost(data)
        if(!selectedAuction){
            fetch(SHOP.CREATE_AUCTION,request).then(res => res.json()).then(res => {
                toast.success("Аукцион создан", {
                    position: toast.POSITION.TOP_CENTER,
                });
                refresh()
            })
        }else{
            const request = requestConstPut(data)
            fetch(SHOP.UPDATE_AUCTION(selectedAuction.auctionId),request).then(res => res.json()).then(res => {
                toast.success("Аукцион сохранен", {
                    position: toast.POSITION.TOP_CENTER,
                });
                refresh()
            })
        }
        setSelectedAuction(null)
        setActiveModal(false)
    }
    const yesDelete = () => {
        fetch(SHOP.DELETE_AUCTION(deleteProduct.auctionId),requestDelete).then(res => res.json()).then(res => {
            toast.success("Аукцион удален", {
                position: toast.POSITION.TOP_CENTER,
            });
            refresh()
        })
        setDeleteProduct(null);
    }
    return (
        <div className={"newStyle active"}>
            <table className="table table-profile">
                <thead>
                <tr>
                    <th className="add">
                        <NavLink onClick={()=>setActiveModal(true)} end>
                            <span></span>
                        </NavLink>
                    </th>
                    <th>Стартовая ставка</th>
                    <th>Начало</th>
                    <th>Конец</th>
                    <th>Статус</th>
                    <th style={{textAlign: 'center'}}></th>
                </tr>
                </thead>
                <tbody>
                    {auctions && auctions.map(auction => (
                        <tr data-tab="3" className="active">
                            <td>
                                <ul className="unstyled">
                                    <NavLink  end>
                                        <li><a onClick={()=>{
                                            setSelectedAuction(auction)
                                            setActiveModal(true)
                                        }} className="edit"></a></li>
                                    </NavLink>

                                    <li><a onClick={()=>setDeleteProduct(auction)}  className="delete"></a></li>
                                </ul>
                            </td>
                            <td className={"vacancy-name-table"}>

                                <span className="name">{auction.startBid}</span>
                            </td>
                            <td data-label={"Начало"}>{auction.startDate}</td>
                            <td data-label={"Конец"}>{auction.endDate}</td>
                            <td className="status">{getAuctionStatus(auction).name}</td>
                            <td>
                                <NavLink state={{previousLocationPathname:`/profile/shop/edit/${auction.productId}`}} to={`/profile/shop/${auction.productId}`}  end>
                                    <a className="btn btn-arrow">Перейти</a>
                                </NavLink>

                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>


            <ModalCustom
                title={selectedAuction ? "Редактирование" : "Добавление"}
                className={"autoWidth"}
                active={activeModal}
                setActive={setActiveModal}
            >
                <CreateOrEditModal activeModal={activeModal} setActiveModal={setActiveModal} selectedAuction={selectedAuction} handleSubmit={handleSubmit}/>
            </ModalCustom>
            <ModalCustom
                title={"Удаление ответственного"}
                active={deleteProduct}
                className={"autoWidth delete_career"}
                setActive={setDeleteProduct}
            >
                {deleteProduct &&
                    <div className="delete_modal">

                        <p>Вы уверены, что хотите удалить аукцион?</p>
                        <div className="button_block">
                            <button
                                className="reset_button"
                                onClick={() => setDeleteProduct(null)}
                            >
                                Отмена
                            </button>
                            <button
                                className="submit_button"
                                onClick={yesDelete}
                            >
                                Да
                            </button>
                        </div>
                    </div>
                }

            </ModalCustom>
        </div>
    )

}
export default Auction;
