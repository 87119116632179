import React from'react';

const FeedBackRow = ({feedback}) => {
    const calc = (type) =>feedback.competenceFeedbacks.filter(item => item.competenceMark === type).length;
    return (
        <tr>
            <td>{feedback?.company?.name}</td>
            <td data-label="Хорошо">{calc('LIKE')}</td>
            <td data-label="Нейтрально">{calc('MIDDLE')}</td>
            <td data-label="Плохо">{calc('DISLIKE')}</td>
        </tr>
    )

}
export default FeedBackRow;