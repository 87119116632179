import React, {useEffect, useState} from 'react';

const useTabHash = (activeTab,setActiveTab,setTabs=false) => {
    const [checkIfOpenByHash, setCheckIfOpenByHash] = useState(false);

    useEffect(()=>{
       if(setTabs){
           window.addEventListener("hashchange", (event) => {
               let tab = parseInt(window.location.hash.substring(1));
               setActiveTab(tab)
           });
           return ()=>{
                window.removeEventListener("hashchange", (event) => {});
           }
       }
    },[])

    useEffect(() => {
        if (window.location.hash && !checkIfOpenByHash) {
            let tab = parseInt(window.location.hash.substring(1));
            setActiveTab(tab)
            setCheckIfOpenByHash(true);
            return;
        } else {
            if (!checkIfOpenByHash)
                setCheckIfOpenByHash(true);
            window.location.hash = activeTab;
        }
    }, [activeTab]);

}
export default useTabHash;