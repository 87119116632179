import React from'react';
import {offersLanguages} from "@utils/languageList";
import {NavLink} from "react-router-dom";

const ResponseBlock = ({item}) => {
    const avatar = null;
    const statusArr = {
        "ACTUAL":<span className="type1 type-green">{offersLanguages.status.ACTUAL}</span>,
        "DRAFT":<span className="type2">{offersLanguages.status.DRAFT}</span>,
        "ARCHIVE":<span className="type3">{offersLanguages.status.ARCHIVE}</span>,

    }
    return (
        <tr data-tab="3" className="active">
            <td>
                <div className="td-responses">
                    <div>
                        <span>{item.offerInfo?.companyName}</span>
                        <p>{item.offerInfo?.name}</p>
                    </div>
                </div>
            </td>
            <td className="td-tag">
                <span>{offersLanguages.type[item.offerInfo?.offerType]}</span>
            </td>
            <td className="status">
                <div className="flex">
                    {statusArr[item.offerInfo?.offerStatus]}
                   {/* <button className="btn btn-confirmed">Подтверждено</button>*/}
                    {/*<button className="td-more"></button>*/}
                    <NavLink to={`/admin/view-career/${item.offerId}`} end>
                        <a className="btn btn-arrow">Перейти</a>
                    </NavLink>
                </div>

            </td>

        </tr>
    )

}
export default ResponseBlock;
