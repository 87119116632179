import React, {useState} from 'react';


const CompanyActivities = ({activities}) => {
    const [more,setMore] = useState(false);

    return (
        <ul className="unstyled teg">
            {[...activities].splice(0,more ? activities.length : 3).map((activity) => (
                <li><a>{activity.name}</a></li>
            ))}
            {activities.length > 3 &&
                <li onClick={()=>setMore(!more)} className="li-more"><a >
                    {more ? `Скрыть`:`Еще ${activities.length - 3}`}
                </a></li>
            }

        </ul>
    )

}
export default CompanyActivities;