import React, {useEffect, useRef, useState} from 'react';
import {animated, useSpring} from '@react-spring/web'

const AnimationBlock = ({children, transformAnimation = true}) => {
    const divRef = useRef();
    //const [opacity,setOpacity] = useState(0);
    const [style, api] = useSpring(() => ({
        from: {
            opacity: 0,
            transform: 'translateY(0)'
        },
    }));
    const [transform, transformApi] = useSpring(() => ({
        from: {translateX: -500},
        to: {translateX: 0},
    }))
    const to = (x, y) => {

    }
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const windowHeight = window.innerHeight;
            const elementTop = divRef.current.getBoundingClientRect().top + scrollTop;
            const elementHeight = divRef.current.offsetHeight;
            const documentHeight = document.documentElement.scrollHeight;

            const centerDistance = (elementTop + elementHeight / 2 - (scrollTop) - windowHeight / 2) -  100;
            const centerDistanceTransform = (elementTop + elementHeight / 2 - (scrollTop) - windowHeight / 2) - 100;
            let percent = scrollTop <= 10 ? 0 : (1 / centerDistance) * 100;

            let percentTransform = scrollTop <= 10 ? 0 : (1 / centerDistanceTransform) * 100;
            let reversePercent = (1 - percentTransform);

            if (reversePercent < 0 || centerDistanceTransform <= 0)
                reversePercent = 0;
            if (percent < 0)
                percent = 1;
            if (percent < .3)
                percent = 0;
            const scrollToBottom = scrollTop + windowHeight >= documentHeight;
            if(scrollToBottom) {
                reversePercent = 0;
                percent = 1;
            }

            api.start({to: {opacity: percent}})
            if (transformAnimation)
                transformApi.start({to: {translateX: reversePercent * 100}});
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <animated.div ref={divRef} className={"transition"} style={
            {
                ...style,
                transform: transform.translateX.to(value => `translateX(${value}%) `)
            }
        }>
            {children}
        </animated.div>
    )

}
export default React.memo(AnimationBlock);