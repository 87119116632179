import React, {useEffect, useState} from "react";
import styled from "styled-components";

import {useVariables} from "@hooks/useVariables";
import getProductStatus, {ProductStatusElement, productStatusMap} from "@utils/getProductStatus";

import ProductImage from "./ProductImage";

import {
    GET_ALL_PRODUCTS,
    GET_PRODUCT_STASUSES,
    UPDATE_COMPANY_STATUS,
    UPDATE_PRODUCT_STATUS,
} from "@api/endpoints";
import {NavLink} from "react-router-dom";
import {usePagination} from "@hooks/usePagination";
import ShopActions from "@store/asyncActions/ShopActions";
import ModalCustom from "@components/ModalCustom";

const ProductsList = ({products,refresh}) => {
    const requestOptionsGET = useVariables().requestOptionsGET;
    const {removeProduct} = ShopActions();
    const [deleteProduct,setDeleteProduct] = useState();
    const [productStatuses, setProductStatuses] = useState([]);
    useEffect(() => {
        fetch(GET_PRODUCT_STASUSES, requestOptionsGET)
            .then((response) => response.json())
            .then((response) => {
                console.log("### GET_PRODUCT_STASUSES.response", response);
                setProductStatuses(response);
            });
    }, []);

    const [loading, setLoading] = useState(false);


    const [selectStatusForProduct, setSelectStatusForProduct] = useState(null);

    const handleProductStatusEdit = (product) => {
        setSelectStatusForProduct(product);
    };

    const constRequestOptionsPOST = useVariables().constRequestOptionsPOST;
    const handleStatusClick = (product, status) => {
        console.log("### handleStatusClick", product, status);

        setSelectStatusForProduct(null);

        fetch(
            UPDATE_PRODUCT_STATUS(product.productId),
            constRequestOptionsPOST({
                status,
            }),
        )
            .then((response) => response.json())
            .then((jsonData) => {
                console.log("### jsonData", jsonData);

                const indexOfProduct = products.findIndex(
                    ({productId}) => productId === product.productId,
                );
                if (typeof indexOfProduct !== "undefined") {
                    products[indexOfProduct].productStatus = status;
                    //setProducts([...products]);
                }
            })
            .catch((error) => console.log("Не удалось обновить статус"));
    };

    const yesDelete = () => {
        removeProduct(deleteProduct.productId).then(()=>{
            refresh();
        });
        setDeleteProduct(null);
    }

    return (
        <tbody>
        {products?.length > 0 ? (
            products.map((product) => (
                <tr key={product.productId} className="line">
                    <td>
                        <ul className="unstyled">
                            <li>
                                <NavLink className="edit" to={`/admin/shop/edit/${product.productId}`} state={{previousLocationPathname: "/admin/shop"}}>
                                </NavLink>
                            </li>
                             <li><a onClick={() => setDeleteProduct(product)} className="delete"></a></li>
                        </ul>
                    </td>
                    <td>
                        <ProductImage product={product}/>
                    </td>
                    <td>
                        <DisplayName>{product.name}</DisplayName>
                    </td>
                    <td>
                        <Description>{product.description}</Description>
                    </td>

                    <td>{product.company.name}</td>
                    {/*<ProductStatusElement status={product.productStatus}>
                        <StatusButton
                            className="status"
                            onClick={() => handleProductStatusEdit(product)}
                        >
                            {getProductStatus(product)?.name}
                        </StatusButton>
                        {selectStatusForProduct?.productId === product.productId ? (
                            <StatusModal>
                                {productStatuses.map((status) => (
                                    <Status
                                        $active={status === product.productStatus}
                                        onClick={() => handleStatusClick(product, status)}
                                    >
                                        {productStatusMap[status]}
                                    </Status>
                                ))}
                            </StatusModal>
                        ) : null}
                    </ProductStatusElement>*/}

                </tr>
            ))
        ) : (
            <tr className="line">
                <td colSpan="9" className="empty_list">
                    {loading ? "Загрузка..." : "Список пуст..."}
                </td>
            </tr>
        )}
        <ModalCustom
            title={"Удаление ответственного"}
            active={deleteProduct}
            className={"autoWidth delete_career"}
            setActive={setDeleteProduct}
        >
            {deleteProduct &&
                <div className="delete_modal">

                    <p>Вы уверены, что хотите удалить "{deleteProduct.name}"?</p>
                    <div className="button_block">
                        <button
                            className="reset_button"
                            onClick={() => setDeleteProduct(null)}
                        >
                            Отмена
                        </button>
                        <button
                            className="submit_button"
                            onClick={yesDelete}
                        >
                            Да
                        </button>
                    </div>
                </div>
            }

        </ModalCustom>
        </tbody>
    );
};

export default React.memo(ProductsList);

const DisplayName = styled.div`
  min-width: 150px;
`;

const Description = styled.div`
  min-width: 200px;
`;

const StatusButton = styled.button`
  text-align: left;
  min-width: 150px;
`;

const StatusModal = styled.div`
  position: absolute;
  top: 5px;
  left: 0;
  margin-left: 20px;
  width: 140px;
  height: 100px;

  box-shadow: 0 0 5px black;

  background-color: white;

  border-radius: 5px;

  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  padding: 0 5px;
`;

const Status = styled.button`
  background-color: ${({$active}) => ($active ? "#CCC" : "#FFF")};
  text-align: start;
  padding: 5px;
`;
