import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom";
import {usePagination} from "@hooks/usePagination";
import {STUDENT_INFORMATION} from "@api/endpoints";
import {useVariables} from "@hooks/useVariables";
import ResponseBlock from "@pages/AdminCreateStudent/Tabs/Responses/ResponseBlock";


const Responses = ({})=>{
    const [sort,setSort] =useState({offerId:"desc"});
    const requestGet = useVariables().requestOptionsGET;
    const studentId = useParams().studentId;
    const [filters,setFilters] = React.useState({size:50})
    const feedback = usePagination(STUDENT_INFORMATION.getResponsesForPagination(studentId),requestGet,`${Object.keys(sort)[0]},${Object.values(sort)[0]}`,filters)

    useEffect(()=>{
        if(sort && feedback.data)
            feedback.refresh();
    },[sort])
    return (
        <div className="active">
            <div
                onClick={()=>{
                    setSort(prevState => {
                        return (prevState.offerId === "desc" ?
                            {offerId: "asc"} : {offerId: "desc"})
                    })
                }}
                className={`sorting ${sort.offerId === "desc"? "sorting-desc" : "sorting-asc"}`}>
                <span>По дате отклика</span>
            </div>
            <table className="table table-responses">
                <thead>
                <tr>
                    <th>Предложение</th>
                    <th>Тип</th>
                    <th>Статус</th>
                </tr>
                </thead>
                <tbody>
                {feedback.data && feedback.data.length > 0 && feedback.data.map((item,index)=>(
                    <ResponseBlock key={index} item={item}/>
                ))}

                </tbody>
            </table>
        </div>
    )
}

export default Responses