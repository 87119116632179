import React, {Component, useEffect, useMemo, useRef, useState} from 'react';
import {NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import {usePagination} from "@hooks/usePagination";
import {
    ADMIN_COMPANY,
    ADMIN_STUDENTS,
    DOWNLOAD_FILE,
    GET_ALL_PRODUCTS,
    GET_COMPANY_PROFILES_V2,
    SHOP
} from "@api/endpoints";
import {useVariables} from "@hooks/useVariables";
import CustomSelect from "@components/CustomSelect";
import {studentsLanguages} from "@utils/languageList";
import {toast} from "react-toastify";
import ShopActions from "@store/asyncActions/ShopActions";
import {useDownloadFile} from "@hooks/useDownloadFile";
import {usePreviewFile} from "@hooks/usePreviewFile";
import ModalCustom from "@components/ModalCustom";
import CropModalContent from "@components/organisms/ProfileEditor/components/CropModalContent";
import {useProfileStudent} from "@hooks/useProfileStudent";
import {IsCompany} from "@hooks/useAuth";
import {useSelector} from "react-redux";
import Information from "@pages/AdminCreateOrEditProduct/Tabs/Information";
import Auction from "@pages/AdminCreateOrEditProduct/Tabs/Auction/Auction";

const CreateOrEditProduct = ({}) => {
    const user = useSelector((state) => state.user.user);
    const {productId} = useParams();

    //const {data:products,refresh} = usePagination(GET_ALL_PRODUCTS, requestOptionsGET,"productId,desc");


    const navigate = useNavigate();

    const previousLocationPathname = useLocation().state?.previousLocationPathname


    const [activeTab, setActiveTab] = useState(0)




    const tabs = [
        "Товар",
        "Аукцион"
    ];
    const tabsElements = [
        <Information setActiveTab={setActiveTab} productId={productId}/>,
        <Auction productId={productId}/>
    ]



    return (
        <div className="newStyle">

            <NavLink to={previousLocationPathname ? previousLocationPathname : -1} end
                     className="btn-back">{productId ? "Редактирование" : "Добавление"} товара</NavLink>
            <div className="tabs tabs-scroll">
                <ul style={{marginTop: 20}} className="tabsmenu">
                    {tabs.map((item, index) => (
                        <li key={index} className={`tab1 ${activeTab === index ? "active" : ""}`}
                            onClick={() => setActiveTab(index)}
                        >{item}</li>
                    ))}

                </ul>
                <div className="tab-content">
                    {tabsElements[activeTab]}
                </div>

            </div>


        </div>
    )
}
export default CreateOrEditProduct;