import React from'react';
import {SwiperSlide} from "swiper/react";
import module1 from "@pages/StartPage/assets/images/modul1.png";
import module2 from "@pages/StartPage/assets/images/modul2.png";
import module3 from "@pages/StartPage/assets/images/modul3.png";
import module4 from "@pages/StartPage/assets/images/modul4.png";
import module5 from "@pages/StartPage/assets/images/modul5.png";
import module6 from "@pages/StartPage/assets/images/modul6.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

  const tabs =  [

            <SwiperSlide style={{height: "100%"}}>
                <div className="modul-blk">
                    <div className="modul-info">
                        <div className="modul-title">
                            <span className="titles">Цифровой профиль</span>
                            <p>Важно все, что ты делаешь!</p>
                            <p>Теперь профиль студента представляет собой аккуратно структурированную систему
                                компетенций и достижений, в которой цифры превращаются в графики, а баллы ПГАС в
                                виртуальные токены для разных призов и подарков!</p>
                        </div>
                        <div className="modul-descript">
                            <span>Единая база всех студентов в рейтинговом списке</span>
                        </div>
                    </div>
                    <div className="modul-image">
                        <LazyLoadImage
                            alt={"module1"}
                            effect={"blur"}
                            src={module1} />
                    </div>
                </div>
            </SwiperSlide>,
            <SwiperSlide style={{height: "100%"}}>
                <div className="modul-blk">
                    <div className="modul-info">
                        <div className="modul-title">
                            <span className="titles">Цифровой профиль</span>
                            <p>Научные статьи, гранты, участия в конференциях и другие победы!</p>
                            <p>Мы вместе репрезентуем самые важные достижения и создаем из них цифровые совершенства,
                                среди которых:</p>
                            <ul>
                                <li>основные и дополнительные компетенции</li>
                                <li>области деятельности</li>
                                <li>достижения</li>
                                <li>показатели ПГАС</li>
                                <li>показатели РСВ</li>
                            </ul>
                        </div>
                        <div className="modul-descript">
                            <span>Каждый университет может создать свою метрику</span>
                        </div>
                    </div>
                    <div className="modul-image">
                        <LazyLoadImage
                            effect={"blur"}
                            alt={"module2"}
                            src={module2} />
                    </div>
                </div>

            </SwiperSlide>,
            <SwiperSlide style={{height: "100%"}}>
                <div className="modul-blk">
                    <div className="modul-info">
                        <div className="modul-title">
                            <span className="titles">Рейтинг</span>
                            <p>Оцифровка достижений позволяет нам формировать различные рейтинги и показывать лучших в
                                своих областях деятельности.</p>
                        </div>
                        <div className="modul-descript">
                            <span>Единая база всех студентов в рейтинговом списке</span>
                            <span className="md2">Возможность делать релевантный отбор для компаний</span>
                        </div>
                    </div>
                    <div className="modul-image">
                        <LazyLoadImage
                            alt={"module3"}
                            effect={"blur"}
                            src={module3} />
                    </div>
                </div>
            </SwiperSlide>,
            <SwiperSlide style={{height: "100%"}}>
                <div className="modul-blk">
                    <div className="modul-info">
                        <div className="modul-title">
                            <span className="titles">Вакансии, стажировки и практики от ведущих компаний</span>
                            <p>Карьерные предложения здесь и сейчас по выбранным компетенциям и профилям.</p>
                            <p>И да, без опыта!</p>
                        </div>
                        <div className="modul-descript">
                            <span>Связь студентов с компаниями из личного кабинета</span>
                            <span className="md2">Целевой отклик на карьерные предложения</span>
                        </div>
                    </div>
                    <div className="modul-image">
                        <LazyLoadImage
                            alt={"module4"}
                            effect={"blur"}
                            src={module4} />
                    </div>
                </div>
            </SwiperSlide>,
            <SwiperSlide style={{height: "100%"}}>
                <div className="modul-blk">
                    <div className="modul-info">
                        <div className="modul-title">
                            <span className="titles">Быстрая связь по конкретным предложениям</span>
                            <p>Получил отклик от компании или обратную связь по своим компетенциям? Можно быстро
                                обсудить все детали во внутреннем мессенджере!</p>
                        </div>
                        <div className="modul-descript">
                            <span>Связь студентов с компаниями из личного кабинета</span>
                            <span className="md2">Возможность прямого контакта со студентом</span>
                        </div>
                    </div>
                    <div className="modul-image">
                        <LazyLoadImage
                            alt={"module5"}
                            effect={"blur"}
                            src={module5} />
                    </div>
                </div>
            </SwiperSlide>,
            <SwiperSlide style={{height: "100%"}}>
                <div className="modul-blk">
                    <div className="modul-info">
                        <div className="modul-title">
                            <span className="titles">Промо-товары и сувениры любимых вузов!</span>
                            <p>Теперь каждое достижение конвертируется в виртуальные Поликоины, которые
                                можно потратить на крутой мерч!</p>
                        </div>
                        <div className="modul-descript">
                            <span>Единая база всех студентов в рейтинговом списке</span>
                        </div>
                    </div>
                    <div className="modul-image">
                        <LazyLoadImage
                            alt={"module6"}
                            effect={"blur"}
                            src={module6} />
                    </div>
                </div>

            </SwiperSlide>

    ]


export default tabs;