import ProfileStudent from "./ProfilePage/components/ProfileStudent";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useProfileStudent } from "../hooks/useProfileStudent";
import {useEffect} from "react";

const StudentFromRatingPage = () => {
  const user = useSelector((state) => state.user.user);
  const roles = user.roles;
  const params = useParams();
  const { studentData } = useProfileStudent(params.studentId);
  return (
    <div className="profile">
      {(roles.includes("COMPANY") || roles.includes("ADMIN") || roles.includes("STUDENT"))? (
        <ProfileStudent studentData={studentData} />
      ) : (
        <div>Нет доступа к странице</div>
      )}
    </div>
  );
};
export default StudentFromRatingPage;
