import React, {useEffect, useRef, useState} from "react"
import CustomSelect from "@components/CustomSelect";
import {CHANGE_COMPANY_REGISTRATION_STATUS, GET_COMPANIES_ACTIVITIES, GET_PROFESSIONS_V2} from "@api/endpoints";
import {usePagination} from "@hooks/usePagination";
import {useVariables} from "@hooks/useVariables";
import Professions from "@pages/AdminCreateOrganization/components/Professions";
import FilterAccordion from "@pages/CareersPage/components/FilterAccordion";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";


const Information = ({
                         img,
                         selectedAvatar,
    handleAvatarFileChange,
    selectedAvatarImage,
                         organization,
                         setOrganization,
                         selectedProfession,
                         setSelectedProfession,
                         selectProf,
                         edit = false
                     }) => {
    const user = useSelector(state => state.user.user);
    const profsRef = useRef();
    const requestGet = useVariables().requestOptionsGET;
    const [limit,setLimit] = useState({size:1000})
    const activities = usePagination(GET_COMPANIES_ACTIVITIES, requestGet, "activityId,desc",null,limit).data;
    const professions = usePagination(GET_PROFESSIONS_V2, requestGet, "","profsRef",limit).data;
    const activityId = organization.activityIds ? organization.activityIds : null;
    const [companyStatus,setCompanyStatus] = useState(organization.registrationStatus);
    const requestPut = useVariables().constRequestOptionsPUT({status:companyStatus});
    useEffect(()=>setCompanyStatus(organization?.registrationStatus),[organization])
    const statuses = [
        {label: "Актуальна", value: "ACTUAL"},
        {label: "Черновик", value: "DRAFT"},
        {label: "Архив", value: "ARCHIVE"},
    ]

    const changeOrg = e => {
        let field = e.currentTarget.dataset.field;
        let subfield = e.currentTarget.dataset.subfield;
        let org = {...organization};
        if (subfield) {
            if (!org[field])
                org[field] = {};
            org[field][subfield] = e.currentTarget.value;
        } else
            org[field] = e.currentTarget.value;
        setOrganization(org);
    }
    const getDefaultActivity = () => {
        let act = activities.filter(x => activityId.includes(x.activityId));
        if (act)
            return act.map((comp) => {
                return {label: comp.name, value: comp.activityId}
            })

        return null;
    }

    const getDefaultProfessions = () => {
        if(selectedProfession && professions && professions.length) {
            return selectedProfession.map((comp) => {
                if(professions.find(x => x.professionId === comp))
                    return {label: professions.find(x => x.professionId === comp).name, value: comp}
            })
        }

        return null;
    }
    const changeStatus = e =>{
        setCompanyStatus(e);
        let config ={
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `${user.type} ${user.accessToken}`,
            },
            body: JSON.stringify({status:e}),
        };
        fetch(CHANGE_COMPANY_REGISTRATION_STATUS(organization.companyId), config).then(r=>r.json()).then(r=>{
            toast.success("Статус изменен",{position:"top-center"});
        })
    }


    return (
        <div className="active student-blks">
            <div className="student-info-block">
                <div className="add-photo add-photo-org">
                    {!organization?.avatarId &&
                        <span>Выберите файл или перетащите сюда</span>
                    }
                    <div className="chosen_avatar">
                        {((selectedAvatar) ||  (img && organization?.avatarId)) &&
                            <img src={selectedAvatar ? selectedAvatar : img} alt="avatar" />
                        }
                    </div>
                    <input
                            name="avatar"
                            accept="image"
                            value={""}
                            onChange={handleAvatarFileChange} type="file" title="Выберите файл или перетащите сюда"/>
                </div>
                <div className="student-info-blk">
                    <form action="" method="" className="search-form">
                        <div className="flex">
                            <div className="search-blk create-blk ">
                                <label htmlFor="Name">Название организации</label>
                                <input value={organization?.name} data-field={"name"} onChange={changeOrg} type="text"
                                       placeholder="Ведите короткое название"
                                       id="Name"/>
                            </div>
                            <div className="search-blk create-blk">
                                <label htmlFor="FullName">Полное название организации</label>
                                <input value={organization?.longName} data-field={"longName"} onChange={changeOrg}
                                       type="text" placeholder="Введите полное название"
                                       id="FullName"/>
                            </div>
                            <div className="search-blk create-blk">
                                <label htmlFor="Inn">ИНН</label>
                                <input value={organization?.inn} data-field={"inn"} onChange={changeOrg} type="text"
                                       placeholder="ИНН"
                                       id="Inn"/>
                            </div>
                            <div className="search-blk create-blk">
                                <label htmlFor="Ogrn">ОГРН</label>
                                <input value={organization?.ogrn} data-field={"ogrn"} onChange={changeOrg} type="text"
                                       placeholder="ОГРН"
                                       id="Ogrn"/>
                            </div>
                            <div className="search-blk create-blk">
                                <label>Область деятельности</label>
                                <CustomSelect
                                    placeholder={"Область деятельности"}
                                    refValue={activities && activityId && activities.length ? getDefaultActivity() : null}
                                    onChange={(e) => {
                                        let org = {...organization};
                                        org.activityIds = e;
                                        setOrganization(org);
                                    }}
                                    isMulti={true}
                                    options={activities.map((comp) => {
                                        return {label: comp.name, value: comp.activityId}
                                    })}
                                />
                            </div>
                            <div className="search-blk create-blk ">
                                <label>Профессия</label>
                                <CustomSelect
                                    placeholder={"Выберите профессию"}
                                    id={"profsRef"}
                                    refValue={selectedProfession && selectedProfession.length ? getDefaultProfessions() : null}
                                    onChange={(e) => {
                                        console.log("SELECT",e)
                                        selectProf(e);
                                    }}
                                    isMulti={true}
                                    options={professions.map((comp) => {
                                        return {label: comp.name, value: comp.professionId}
                                    })}
                                />
                                  {/*  <FilterAccordion title={"Список профессий"}>
                                        <Professions professions={professions} selectedProfession={selectedProfession}
                                                     selectProf={selectProf}/>
                                    </FilterAccordion>*/}
                            </div>


                            <div className="search-blk create-blk">
                                <label htmlFor="Description">Описание</label>
                                <textarea value={organization?.description} data-field={"description"}
                                          onChange={changeOrg} placeholder="Описание"
                                          id="Description"></textarea>
                            </div>
                            <div className="search-blk create-blk">
                                <label htmlFor="Requizite">Реквизиты</label>
                                <textarea value={organization?.requisites} data-field={"requisites"}
                                          onChange={changeOrg} placeholder="Реквизиты"
                                          id="Requizite"></textarea>
                            </div>

                            {edit &&
                                <div className="search-blk create-blk ">
                                    <label>Статус компании</label>
                                    <CustomSelect
                                        placeholder={"Выберите статус"}
                                        id={"profsRef"}
                                        refValue={statuses.find(x=>x.value === companyStatus)}
                                        onChange={changeStatus}
                                        isMulti={false}
                                        options={statuses}
                                    />
                                    {/*  <FilterAccordion title={"Список профессий"}>
                                        <Professions professions={professions} selectedProfession={selectedProfession}
                                                     selectProf={selectProf}/>
                                    </FilterAccordion>*/}
                                </div>
                            }

                            {!edit &&
                                <div className="flex flex-row">
                                    <div className="search-blk create-blk ">
                                        <label htmlFor="Name2">Фамилия ответственного</label>
                                        <input value={organization?.contactPerson?.firstName} data-field={"contactPerson"}
                                               data-subfield="firstName" onChange={changeOrg} name={"firstName"} type="text"
                                               placeholder="Иван" id="Name2"/>
                                    </div>
                                    <div className="search-blk create-blk ">
                                        <label htmlFor="Name2">Имя ответственного</label>
                                        <input value={organization?.contactPerson?.secondName} data-field={"contactPerson"}
                                               data-subfield="secondName" onChange={changeOrg} name={"secondName"} type="text"
                                               placeholder="Иванченко" id="Name2"/>
                                    </div>
                                    <div className="search-blk create-blk ">
                                        <label htmlFor="Name2">Отчество ответственного</label>
                                        <input value={organization?.contactPerson?.middleName} data-field={"contactPerson"}
                                               data-subfield="middleName" onChange={changeOrg} name={"middleName"} type="text"
                                               placeholder="Иванович" id="Name2"/>
                                    </div>
                                    <div className="search-blk create-blk ">
                                        <label htmlFor="Email">Почта ответственного</label>
                                        <input value={organization?.contactPerson?.email} data-field={"contactPerson"}
                                               data-subfield="email" onChange={changeOrg} type="email"
                                               placeholder="example@gmail.com" id="Email"/>
                                    </div>
                                </div>
                            }
                            <div className="search-blk create-blk ">
                                <label htmlFor="Website">Сайт организации</label>
                                <input value={organization?.site} data-field={"site"} onChange={changeOrg} type="text"
                                       placeholder="https://example.com"
                                       id="Website"/>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
            {/* <div className="table-btn student-btn">
                <button className="btn btn-grey">Отменить</button>
                <button type="submit" className="btn btn-blue">Создать</button>
            </div>*/}
        </div>
    )
}

export default Information