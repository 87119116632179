import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {usePagination} from "./usePagination";
import {useVariables} from "./useVariables";

import {GET_COMPANIES, GET_COMPANIES_V2} from "../api/endpoints";
import {useState} from "react";

export const useCompanyList = () => {
    const [sort,setSort] = useState({name:"desc"});
    const navigate = useNavigate();
    const [filters,setFilters] = useState({name:""})
    const user = useSelector((state) => state.user.user);
    const requestOptions = useVariables().requestOptionsGET;
    const companies = usePagination(
        GET_COMPANIES_V2,
        requestOptions,
        `${Object.keys(sort)[0]},${Object.values(sort)[0]}`,
        null,
        filters
    );

    const redirectCompanyPage = (companyId) => {
        if (user.id === companyId) {
            navigate("/profile");
        } else {
            navigate(`../profile/${companyId}`);
        }
    };
    const handleSearchByName = (e) => {
        const name = e.target.value;
        setFilters(prevState => ({...prevState, name}))
    }
    const clearFilters = () => {
        setFilters({name:""})
    }
    const applyFilters = (filters) => {
        setFilters(prevState => ({...prevState,...filters}))
    }
    return {
        setSort,
        sort,
        refresh: companies.refresh,
        applyFilters,
        handleSearchByName: handleSearchByName,
        loading: companies.loading,
        clearFilters,
        companies: companies.data,
        redirectCompanyPage,
    };
};
