import "@assets/styles/components/product_item.scss";

import React, {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";

import {useVariables} from "@hooks/useVariables";
import {useDownloadFile} from "@hooks/useDownloadFile";

import EditAuction from "./EditAuction";

import test_img from "@assets/images/avatar-default.png";
import defaultProduct from "@assets/images/product-default.png";

import {DOWNLOAD_FILE, ADD_AUCTION_BID, SHOP} from "@api/endpoints";
import {PRODUCT_STATUS} from "@constants/products";
import {GET_TOP_BIDS} from "@api/endpoints";
import {usePagination} from "@hooks/usePagination";
import {IsCompany} from "@hooks/useAuth";
import StudentBidItem from "@pages/ShopItem/components/StudentBidItem";
import {Link} from "react-router-dom";

const ProductItem = ({product, onRefresh}) => {
    const user = useSelector((state) => state.user.user);
    // const isVendor = user.id === product.company.id;
    const isVendor = IsCompany()
    const dateOptions = useVariables().shortDateOptions;
    const requestPost = useVariables().requestOptionsPOST;
    const requestOptionsPUT = useVariables().constRequestOptionsPUT;
    const [activeTab, setActiveTab] = useState(0);
    const requestGet = useVariables().requestOptionsGET
    const [filters, setFilters] = useState({productId: product.productId});
    const {data: auctions, refresh} = usePagination(SHOP.GET_AUCTIONS, requestGet, "", null, filters);
    const auction = auctions?.find(
        ({auctionStatus}) => auctionStatus === "CURRENT",
    );
    const [bid, setBid] = useState(auction?.startBid);
    const myBid = useMemo(() => {
        if (auction && auction?.myBid) {
            setBid(auction?.myBid.bidAmount)
            return auction?.myBid;
        }
    }, [auction]);
    const tabs = [
        {
            id: 0,
            name: "Основная информация",
        },
        {
            id: 1,
            name: "Аукционы",
        }
    ]

    const img = useDownloadFile(
        DOWNLOAD_FILE,
        product.imgId,
        defaultProduct,
    ).file;

    const getAuctionStatus = (auction) => {
        switch (auction?.auctionStatus) {
            case "UPCOMING":
                return {
                    class: "opened",
                    name: "Создан",
                };
            case "PAST":
                return {
                    class: "closed",
                    name: "Закрыт",
                };
            case "CURRENT":
                return {
                    class: "open",
                    name: "Проходит",
                };
            default:
                return {
                    class: "",
                    name: "-",
                };
        }
    };

    const handleAuctionAdd = () => {
        onRefresh();
        setActiveTab(0);
    };

    const handleOpenAuction = () => {
        setActiveTab(1);
    };

    const onBidChange = (event) => {
        setBid(event.target.value);
    };

    const getTopBids = () => {
        console.log("### getTopBids", auction?.auctionId);
        if (auction?.auctionId) {
            setTopBids(auction?.topBids);
            return auction?.topBids;
        }
    };

    const requestOptionsGET = useVariables().requestOptionsGET;
    const constRequestOptionsPOST = useVariables().constRequestOptionsPOST;
    const getAuction = () => {
        fetch(SHOP.get)
    }
    const handleBidClick = () => {
        console.log("### handleBidClick", auction, bid);
        if (bid < auction.startBid) {
            toast.error("Ставка не может быть меньше стартовой", {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }
        if (myBid && myBid.bidAmount >= bid) {
            toast.error("Ставка не может быть меньше той, которую вы уже ставили", {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }

        if (!myBid) {
            fetch(
                SHOP.CREATE_BID(auction?.auctionId, bid),
                requestPost
            )
                .then((response) => {
                    console.log("### response", response);
                    toast.success("Ставка создана", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    refresh();
                    return response.json();
                })
                .then((json) => {
                    console.log(json);
                    if (json.status === "Error") {
                        toast.error(json.message, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    } else {
                        getTopBids();
                    }
                })
                .catch((error) => console.log("### error", error));
        } else {
            fetch(
                SHOP.UPDATE_BID(auction?.auctionId, bid),
                requestOptionsPUT()).then(() => {
                toast.success("Ставка обновлена", {
                    position: toast.POSITION.TOP_CENTER,
                });
                refresh();
                getTopBids();
            })
        }

    };

    const [topBids, setTopBids] = useState([]);
    useEffect(() => {
        getTopBids();
    }, [auction?.auctionId]);
    const maxBid = useMemo(() => Math.max(...topBids?.map(o => o.bidAmount))|0, [topBids]);

    console.log("### topBids", topBids);
    console.log("### product.auctions", product.auctions);

    const getMaxBid = (data) => data && data.length > 0 ? data.reduce((prev, current) => (prev && prev.bidAmount > current.bidAmount) ? prev : current) : null

    return (
        <div className="product_item">
            <ul className="tab_list">
                {tabs.map((tab) => (
                    <li
                        key={tab.id}
                        className={activeTab === tab.id ? "selected" : ""}
                        onClick={() => setActiveTab(tab.id)}
                    >
                        {tab.name}
                    </li>
                ))}
            </ul>

            {activeTab === 0 && (
                <>
                    <div className="flex_block">
                        <div className="image">
                            <img src={img} alt="товар"/>
                        </div>
                        <div className="main_info">
                            <div className="shadow_block">
                                <h2>{product.displayName}</h2>
                                <div className="product_info">
                                    <div>
                                        <p className="info_title">Описание товара</p>
                                        <p>{product.description}</p>
                                    </div>
                                    <div>
                                        <p className="info_title">Организация</p>
                                        <p>{product.company.name}</p>
                                    </div>
                                </div>
                            </div>
                            {auctions?.map((auction) => (
                                <div
                                    key={auction.auctionId}
                                    className="shadow_block auction_item"
                                >
                                    <div className="flex_header auction_title">
                                        <h3>Информация об аукционе</h3>
                                        <div className="bubble">
                                            {getAuctionStatus(auction).name}
                                        </div>
                                    </div>
                                    <div className="auction_info">
                                        <div>
                                            <p className="title">Даты проведения</p>
                                            <p>
                                                {`${new Date(auction.startDate).toLocaleString(
                                                    "ru",
                                                    dateOptions,
                                                )} 
                        —
                        ${new Date(auction.endDate).toLocaleString(
                                                    "ru",
                                                    dateOptions,
                                                )}`}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="title">Начальная цена</p>
                                            <p>{auction.startBid}</p>
                                        </div>
                                        {auction.topBids && auction.topBids.length > 0 &&
                                            <div>
                                                <p className="title">Максимальная ставка</p>
                                                <p>{Math.max(...auction.topBids?.map(o => o.bidAmount))|0}</p>
                                            </div>
                                        }

                                        {auction.topBids && auction.topBids.length > 0 && auction?.auctionStatus === "PAST" && isVendor &&
                                         <div>
                                             <p className="title">Победитель</p>
                                             <Link target={"_blank"} to={`/profile/portfolio/${getMaxBid(auction.topBids)?.student.studentId}`} className={"secondary_button turquoise_button"}>

                                                 {getMaxBid(auction.topBids)?.student.secondName} {getMaxBid(auction.topBids)?.student.firstName}
                                             </Link>
                                         </div>
                                        }
                                    </div>
                                    {!isVendor && auction.auctionStatus === "CURRENT" ? (
                                        <div
                                            className="secondary_button turquoise_button"
                                            onClick={handleOpenAuction}
                                        >
                                            Участвовать
                                        </div>
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="buttons_block"></div>
                </>
            )}

            {/*{activeTab === 1 && isVendor && !product.auctions?.length ? (
        <EditAuction product={product} onAdd={handleAuctionAdd} />
      ) : null}*/}

            {activeTab === 1 && (
                <div className="flex_block">
                    <div>
                        <div className="image">
                            <img src={img} alt="товар"/>
                        </div>
                        <h2 className="product_name">{product.displayName}</h2>
                    </div>
                    <div className="main_info">
                        {auction ?
                            <>
                                <div className="shadow_block auction_item">
                                    <div className="flex_header auction_title">
                                        <h3>Информация об аукционе</h3>
                                        <div className="bubble">
                                            {getAuctionStatus(auction).name}
                                        </div>
                                    </div>
                                    <div className="auction_info">
                                        <div>
                                            <p className="title">Даты проведения</p>
                                            <p>
                                                {`${new Date(auction?.startDate).toLocaleString(
                                                    "ru",
                                                    dateOptions,
                                                )} 
                      —
                    ${new Date(auction?.endDate).toLocaleString(
                                                    "ru",
                                                    dateOptions,
                                                )}`}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="title">Начальная цена</p>
                                            <p>{auction?.startBid}</p>
                                        </div>
                                        <div>
                                            <p className="title">Максимальная ставка</p>
                                            <p>{maxBid}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bid_list">
                                    <div className="flex_header">
                                        <h3>Список сделанных ставок</h3>
                                        <span className="i-sort_after icon_text bold icon_large">
                                          По размеру ставки
                                        </span>
                                    </div>
                                    <ul className="rating_list scroll_element">
                                        {topBids?.map((item, index) => (
                                            <StudentBidItem key={index} item={item}/>
                                        ))}
                                    </ul>
                                </div>
                            </>
                            :
                            <div className="flex_header">
                                <h3>Нет активных аукционов</h3>

                            </div>

                        }

                        {auction ? (
                            <div className="shadow_block bid_place">
                                <h3>{myBid ? "Вы уже сделали ставку" : "Сделать ставку"}</h3>
                                <div className="bid_info">
                                    <div>
                                        <p className="icon_text i-up_right_after bold icon_small">
                                            Об аукционе
                                        </p>
                                        <p>Правила и условия участия в аукционе</p>
                                    </div>
                                    <div className="field">
                                        <input
                                            placeholder={`Мин. ${auction?.startBid}`}
                                            className="form_field"
                                            type="number"
                                            min={auction?.startBid}
                                            value={bid}
                                            onChange={onBidChange}
                                        />
                                    </div>
                                </div>
                                <button
                                    className="secondary_button turquoise_button"
                                    onClick={handleBidClick}
                                >
                                    {myBid ? "Обновить ставку" : "Оставить ставку"}
                                </button>
                            </div>
                        ) : null}
                    </div>
                </div>
            )}
        </div>
    );
};

export default React.memo(ProductItem);
