import { PRODUCT_STATUS } from "@constants/products";
import React from "react";

export const productStatusMap = {
  [PRODUCT_STATUS.needReview]: "На рассмотрении",
  [PRODUCT_STATUS.publication]: "Опубликован",
  [PRODUCT_STATUS.archive]: "Архив",
  [PRODUCT_STATUS.draft]: "Черновик",
};
const statuses = (child) => [
  <td className="td-tag">{child}</td>,
  <td className="td-tag not-pay">{child}</td>,
]
export const ProductStatusElement = ({children, status}) => {
  switch(status){
    case PRODUCT_STATUS.needReview:
      return <td className="td-tag needReview">{children}</td>;
    case PRODUCT_STATUS.publication:
      return <td className="td-tag public">{children}</td>;
    case PRODUCT_STATUS.archive:
      return <td className="td-tag disable">{children}</td>;
    case PRODUCT_STATUS.draft:
      return <td className="td-tag disable">{children}</td>;
    default:
      return <td className="td-tag">{children}</td>;

  }
};



const getProductStatus = (product) => {
  const statusName = productStatusMap[product?.productStatus] || "-";

  return { name: statusName };
};

export default getProductStatus;
