import React, {useState} from "react"
import ModalCustom from "@components/ModalCustom";
import CropModalContent from "@components/organisms/ProfileEditor/components/CropModalContent";
import EditOrCreateModal from "@pages/AdminCreateOrganization/Tabs/Responsible/EditOrCreateModal";
import {useVariables} from "@hooks/useVariables";
import {
    CREATE_RESPONSIBLE_CONTACT,
    REMOVE_RESPONSIBLE_CONTACT_FROM_COMPANY,
    RESET_PASSWORD_CONTACT_PERSON,
    UPDATE_RESPONSIBLE_CONTACT
} from "@api/endpoints";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import ButtonWithLoading from "@components/ButtonWithLoading";


const Responsible = ({organization,updateContacts,removeContact}) => {
    const user = useSelector((state) => state.user.user);

    const [activeModalEdit, setActiveModalEdit] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const [errors,setErrors] = useState({})
    const [activeModalDelete, setActiveModalDelete] = useState(false);
    const [selectedContact, setContact] = useState({});
    const requestDataPost = useVariables().constRequestOptionsPOST(selectedContact);
    const requestDataPut = useVariables().constRequestOptionsPUT(selectedContact);
    const requestDataDelete = useVariables().requestOptionsDELETE
    const closeModal = () => {
        setActiveModalEdit(false);
        setContact(false)
    }
    const changeContact = e => {
        let target = e.target.dataset.field;
        let cnt = {...selectedContact};
        cnt[target] = e.target.value;
        setContact(cnt);
    }
    const deleteContact = () =>{
        fetch(REMOVE_RESPONSIBLE_CONTACT_FROM_COMPANY(selectedContact.contactPersonId),requestDataDelete).finally(()=>{
            removeContact(selectedContact.contactPersonId)
            setActiveModalDelete(false)
        });

    }
    const save = e =>{
        if(selectedContact.contactPersonId){
            fetch(UPDATE_RESPONSIBLE_CONTACT(selectedContact.contactPersonId),requestDataPut).then(res=>res.json()).then(res=>{
                if(res.status === "Error"){
                    if(res.message[0] === "Invalid email address"){
                        setErrors({email: "Некорректный email"})
                    }else
                        toast.error(`Ошибка при добавление контакта. ${res.message[0]}`, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                }else{
                    updateContacts(res,true)
                    setContact({})
                    setActiveModalEdit(false)
                }
            })
        }else{
            fetch(CREATE_RESPONSIBLE_CONTACT(organization.companyId),requestDataPost).then(res=>res.json()).then(res=>{
                if(res.status === "Error"){
                    if(res.message[0] === "Invalid email address"){
                        setErrors({email: "Некорректный email"})
                    }else
                        toast.error(`Ошибка при добавление контакта. ${res.message[0]}`, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                }else{
                    updateContacts(res)
                    setContact({})
                    setActiveModalEdit(false)
                }
            })
        }
    }
    const resetPassword = async (userId) =>{
        let config = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `${user.type} ${user.accessToken}`,
            },
            body: JSON.stringify({userId}),
        }
        let res = await fetch(RESET_PASSWORD_CONTACT_PERSON,config);
        res = await res.json();
        if(res.status === "Error"){
            toast.error(`Ошибка при сбросе пароля. ${res.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }else
            toast.success(`Все пароли были сброшены`, {position: toast.POSITION.TOP_CENTER})
    }
    return (
        <div className="active">
            <table className="tables table-otv table-md table-lg">
                <thead>
                <tr>
                    <th className="add"><a
                        onClick={() => {
                            setActiveModalEdit(true)
                            setContact({})
                        }}
                        className="btn btn-blue modal-link" data-modal="#modal-add-otv"></a>
                    </th>
                    <th>ФИО</th>
                    <th>Должность</th>
                    <th>Почта</th>
                    <th>Номер телефона</th>
                    <th>
                        <ButtonWithLoading
                            className={"btn btn-blue"}
                            onClick={resetPassword}
                            paramsInOnClick={organization?.companyId}
                            title={"Сбросить пароли"}
                            style={{height: 40, fontSize: 12}}
                        />
                    </th>
                </tr>
                </thead>
                <tbody>
                {organization && organization.contactPersons ? organization.contactPersons.map((contactPerson, index) => (
                    <tr key={index}>
                        <td>
                            <ul className="unstyled">
                                <li><a onClick={() => {
                                    setContact(contactPerson)
                                    setActiveModalEdit(true)
                                }} className="edit modal-link"></a></li>
                                {contactPerson.contactPersonType !== "CREATOR" &&
                                    <li><a onClick={()=> {
                                        setActiveModalDelete(true)
                                        setContact(contactPerson)
                                    }}
                                           className="delete"></a></li>
                                }

                            </ul>
                        </td>
                        <td>{contactPerson.secondName} {contactPerson.firstName} {contactPerson.middleName}</td>
                        <td data-label="Почта:">{contactPerson.position}</td>
                        <td data-label="Почта:">{contactPerson.email}</td>
                        <td data-label="Почта:">{contactPerson.phoneNumber}</td>
                        <td>

                           {/* <button onClick={()=>resetPassword(contactPerson.contactPersonId)} className="btn btn-blue modal-link" >
                                Сбросить пароль
                            </button>*/}
                        </td>
                        {/*<td data-label="Номер телефона:">+7 (233) 569-45-45</td>*/}
                    </tr>
                )) : <tr>Пусто</tr>}

                </tbody>
            </table>
            <ModalCustom
                title={selectedContact?.contactPersonId ? "Редактирования ответственного" : "Добавление ответственного"}
                active={activeModalEdit}
                className={"autoWidth"}
                setActive={setActiveModalEdit}
            >
                <EditOrCreateModal
                    edit={selectedContact?.contactPersonId}
                    errors={errors}
                    setErrors={setErrors}
                    close={closeModal}
                    save={save}
                    contact={selectedContact}
                    changeData={changeContact}
                />
            </ModalCustom>
            <ModalCustom
                title={"Удаление ответственного"}
                active={activeModalDelete}
                className={"autoWidth delete_career"}
                setActive={setActiveModalDelete}
            >
                {selectedContact &&
                   <div className="delete_modal">

                       <p>Вы уверены, что хотите удалить "{selectedContact.secondName} {selectedContact.firstName}"?</p>
                        <div className="button_block">
                            <button
                                className="reset_button"
                                onClick={() => setActiveModalDelete(null)}
                            >
                                Отмена
                            </button>
                            <button
                                className="submit_button"
                                onClick={() => deleteContact()}
                            >
                                Да
                            </button>
                        </div>
                    </div>
                }

            </ModalCustom>
        </div>
    )
}

export default Responsible