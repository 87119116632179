import "../assets/styles/components/layout.scss";
import React, { useState } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";
import { ToastContainer } from "react-toastify";

const Layout = ({ menu }) => {
  const { logout } = useAuth();
  const exit = (
    <Link to="/" onClick={() => logout()}>
      <span>
        Выход<span className="i-up_right"></span>
      </span>
    </Link>
  );

  const getCollapseStartValue = () => {
    const windowWidth = window.innerWidth;
    return windowWidth < 900;
  };

  const onCollapseClick = () => {
    setCollapse((prevState) => !prevState);
  };

  const [collapse, setCollapse] = useState(getCollapseStartValue());

  return (
    <div className="layout">
      <header className={collapse ? "collapsed_header header" : "header"}>
        <Link to={"/"} className="logo" reloadDocument>
          <p>
            <span>Поликапитал</span>
          </p>
        </Link>
       {/* Проект на этапе пользовательского тестирования*/}
        <div className="exit exit_from_header">{exit}</div>
        <div
          className={
            collapse ? "burger_menu collapsed_burger_menu" : "burger_menu"
          }
          onClick={onCollapseClick}
        >
          <span> </span>
        </div>
      </header>
      <div className="layout_body">
        <div className={collapse ? "menu_block collapsed_menu" : "menu_block"}>
          <nav className="menu">
            {menu.map((el, index) => (
              <NavLink className="menu_link" key={index} to={el.path} end>
                <span className={`icon ${el.icon_class}`}></span>
                <span className="menu_title">{el.name}</span>
              </NavLink>
            ))}
          </nav>
          <div className="collapse" onClick={onCollapseClick}>
            <span
              className={collapse ? "icon i-right_xs" : "icon i-left_xs"}
            ></span>
            <span className="collapse_text">Свернуть</span>
          </div>
          <div className="exit exit_from_menu">{exit}</div>
        </div>
        <div className="content_block">
          {/*<CustomErrorBoundary>*/}
          <Outlet />
          {/*</CustomErrorBoundary>*/}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Layout;
