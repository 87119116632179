import {loadInstitutes} from "@store/asyncActions/institute";

const PRODUCTION = true;
const DOMAIN = PRODUCTION ? "polykp.spbstu.ru" : "92.51.39.72";

// const DEFAULT_PORT = "80";
export const DOMAIN_WITH_PORT = `http${PRODUCTION ? "s" : ""}://${DOMAIN}`;

// const MESSAGING_PORT = "80";
const DOMAIN_WITH_MESSAGING_PORT = `http${PRODUCTION ? "s" : ""}://${DOMAIN}`;

export const UPDATE_RESPONSIBLE_CONTACT_COMPANY = contactId => `${DOMAIN_WITH_PORT}/api/v2/company/update-contact-person/${contactId}`;
export const UPDATE_RESPONSIBLE_CONTACT = contactId => `${DOMAIN_WITH_PORT}/api/v2/company/admin/update-contact-person/${contactId}`;
export const CREATE_RESPONSIBLE_CONTACT = companyId => `${DOMAIN_WITH_PORT}/api/v2/company/admin/create-contact-person/${companyId}`;

export const RESET_PASSWORD_COMPANY = `${DOMAIN_WITH_PORT}/api/auth/reset-company-password`
export const RESET_PASSWORD_STUDENT = `${DOMAIN_WITH_PORT}/api/auth/reset-student-password`
export const REGISTER_COMPANY = `${DOMAIN_WITH_PORT}/api/v2/company/create`;

export const SEND_FEEDBACK = `${DOMAIN_WITH_PORT}/api/feedback-creation/send-feedback`;

export const WEBSOCKET_CONNECTION = `ws${PRODUCTION ? "s" : ""}://${DOMAIN}/`;

export const CHANGE_COMPANY_REGISTRATION_STATUS = (companyId) =>{
    return `${DOMAIN_WITH_PORT}/api/v2/company/admin/update-registration-status/${companyId}`;
}

export const LOGIN_STUDENT = `${DOMAIN_WITH_PORT}/api/auth/student`;
export const GET_PORTFOLIO = `${DOMAIN_WITH_PORT}/api/portfolio/get`;

export const GET_PORTFOLIO_BY_COMPANY_ID = (companyId) =>`${DOMAIN_WITH_PORT}/api/portfolio/get-portfolio/${companyId}`;
export const GET_PORTFOLIO_BY_STUDENT_ID = (studentId) =>`${DOMAIN_WITH_PORT}/api/portfolio/get-portfolio/${studentId}`;

export const UPDATE_RESPONSE = (id) =>
    `${DOMAIN_WITH_PORT}/api/offer/update-job-response-status/${id}`

export const LOGIN_OTHER = `${DOMAIN_WITH_PORT}/api/auth/other`;

export const GET_RATING = (page, size, sort, filterParams = null) =>
    `${DOMAIN_WITH_PORT}/api/v2/rating/get-rating?` +
    new URLSearchParams({
        ...filterParams,
        page,
        size,
        sort,
    });

export const REMOVE_RESPONSIBLE_CONTACT_FROM_COMPANY = (companyId) => `${DOMAIN_WITH_PORT}/api/v2/company/delete-contact-person/${companyId}`
export const CREATE_RESPONSIBLE_CONTACT_FROM_COMPANY = (companyId) => `${DOMAIN_WITH_PORT}/api/v2/company/create-contact-person/${companyId}`

export const GET_COMPANIES_BY_NAME_V2 = (name) => {
    if (name)
        return `${DOMAIN_WITH_PORT}/api/v2/company/get-preview-companies?name=${name}`;
    else
        return `${DOMAIN_WITH_PORT}/api/v2/company/get-preview-companies`;
}
export const GET_COMPANIES_BY_NAME = (name) => {
    if (name)
        return `${DOMAIN_WITH_PORT}/api/company/get-names?name=${name}`;
    else
        return `${DOMAIN_WITH_PORT}/api/company/get-names`;
}

export const CREATE_OFFER_RESPONSE = (offerId) =>
    `${DOMAIN_WITH_PORT}/api/offer/respond/${offerId}?`;

export const GET_MY_RESPONSES = (page, size, sort, otherParams) =>
    `${DOMAIN_WITH_PORT}/api/offer/get-my-responses?` +
    new URLSearchParams({
        page,
        size,
        sort,
        ...otherParams
    });

export const GET_ALL_EDU_DIRECTIONS_NEW =
    `${DOMAIN_WITH_PORT}/api/edu/get-all-edu-direction/`


export const GET_SUBSCRIPTIONS =`${DOMAIN_WITH_PORT}/api/v2/subscription/get-subscription`;
export const GET_SUBSCRIPTION_BY_COMPANY_ID = (otherParams) => `${DOMAIN_WITH_PORT}/api/v2/subscription/get-company-subscription/${otherParams}`
export const UPDATE_COMPANY_SUBSCRIPTION = (companyId) => `${DOMAIN_WITH_PORT}/api/v2/subscription/send-change-subscription-request/${companyId}`
export const UPDATE_COMPANY_SUBSCRIPTION_ADMIN = (companyId) => `${DOMAIN_WITH_PORT}/api/v2/subscription/admin/update-company-subscription/${companyId}`

export const STUDENT_INFORMATION = {

    getResponsesForPagination:(id)=>{
        return (page,size,sort,otherParams)=>`${DOMAIN_WITH_PORT}/api/offer/get-user-responses/${id}?`+
            new URLSearchParams({
                page,
                size,
                sort,
                ...otherParams,
            });
    },
    getMarks:(id)=>{
      return `${DOMAIN_WITH_PORT}/api/v2/mark/get-student-mark/${id}`;
    },
    getInstitutes:(page, size, sort,otherParams) => {
        return `${DOMAIN_WITH_PORT}/api/v2/education/get-institutes?` +
            new URLSearchParams({
                page,
                size,
                sort,
                ...otherParams,
            });
    },
    getEducationPrograms:(page, size, sort,otherParams) => {
        return `${DOMAIN_WITH_PORT}/api/v2/education/get-education-programs?` +
            new URLSearchParams({
                page,
                size,
                sort,
                ...otherParams,
            });
    },
    getEducationDirections:(page, size, sort,otherParams) => {
        return `${DOMAIN_WITH_PORT}/api/v2/education/get-education-directions?` +
            new URLSearchParams({
                page,
                size,
                sort,
                ...otherParams,
            });
    },
    getQualifications:(page, size, sort,otherParams) => {
        return `${DOMAIN_WITH_PORT}/api/v2/education/get-qualifications?` +
            new URLSearchParams({
                page,
                size,
                sort,
                ...otherParams,

            });
    }
}
export const CREATE_CHAT_WITH_ADMIN = `${DOMAIN_WITH_PORT}/api/chat/get-admin-chat`
export const ADMIN_EDUCATION = {
    LOAD_INSTITUTES: (page, size, sort,otherParams) => {
        return `${DOMAIN_WITH_PORT}/api/v2/education/admin/get-institutes?` +
            new URLSearchParams({
                page,
                size,
                sort,
                ...otherParams,
            });
    },
    LOAD_DIRECTIONS: (page, size, sort,otherParams) => {
        return `${DOMAIN_WITH_PORT}/api/v2/education/admin/get-education-directions?` +
            new URLSearchParams({
                page,
                size,
                sort,
                ...otherParams,
            });
    },
    LOAD_PROGRAMS: (page, size, sort,otherParams) => {
        return `${DOMAIN_WITH_PORT}/api/v2/education/admin/get-education-programs?` +
            new URLSearchParams({
                page,
                size,
                sort,
                ...otherParams,
            });
    },
    LOAD_COMPANIES_ACTIVITIES: (page, size, sort,otherParams) => {
      return `${DOMAIN_WITH_PORT}/api/v2/company/admin/get-company-activity?` +
          new URLSearchParams({
              page,
                size,
                sort,
                ...otherParams,
          });
    },
    LOAD_QUALIFICATIONS: (page, size, sort,otherParams) => {
        return `${DOMAIN_WITH_PORT}/api/v2/education/admin/get-qualifications?` +
            new URLSearchParams({
                page,
                size,
                sort,
                ...otherParams,
            });
    },
    LOAD_PROFESSION: (page, size, sort,otherParams) => {
        return `${DOMAIN_WITH_PORT}/api/v2/education/admin/get-professions?` +
            new URLSearchParams({
                page,
                size,
                sort,
                ...otherParams,
            });
    },

    CREATE_INSTITUTE:`${DOMAIN_WITH_PORT}/api/v2/education/admin/create-institute`,
    CREATE_QUALIFICATION:`${DOMAIN_WITH_PORT}/api/v2/education/admin/create-qualification`,
    CREATE_EDUCATION_DIRECTION:`${DOMAIN_WITH_PORT}/api/v2/education/admin/create-education-direction`,
    CREATE_EDUCATION_PROGRAM:`${DOMAIN_WITH_PORT}/api/v2/education/admin/create-education-program`,
    CREATE_COMPANY_ACTIVITY: `${DOMAIN_WITH_PORT}/api/v2/company/admin/create-company-activity`,

    CREATE_COMPANY_PROFESSION: `${DOMAIN_WITH_PORT}/api/v2/education/admin/create-profession`,

    UPDATE_INSTITUTE: (id)=>`${DOMAIN_WITH_PORT}/api/v2/education/admin/update-institute/${id}`,
    UPDATE_QUALIFICATION:(id)=>`${DOMAIN_WITH_PORT}/api/v2/education/admin/update-qualification/${id}`,
    UPDATE_EDUCATION_DIRECTION:(id)=>`${DOMAIN_WITH_PORT}/api/v2/education/admin/update-education-direction/${id}`,
    UPDATE_EDUCATION_PROGRAM:(id)=>`${DOMAIN_WITH_PORT}/api/v2/education/admin/update-education-program/${id}`,
    UPDATE_COMPANY_ACTIVITY: (id)=>`${DOMAIN_WITH_PORT}/api/v2/company/admin/update-company-activity/${id}`,

    UPDATE_COMPANY_PROFESSION: (id)=>`${DOMAIN_WITH_PORT}/api/v2/education/admin/update-profession/${id}`,

    DELETE_INSTITUTE: (id)=>`${DOMAIN_WITH_PORT}/api/v2/education/admin/delete-institute/${id}`,
    DELETE_QUALIFICATION:(id) =>`${DOMAIN_WITH_PORT}/api/v2/education/admin/delete-qualification/${id}`,
    DELETE_EDUCATION_DIRECTION:(id)=>`${DOMAIN_WITH_PORT}/api/v2/education/admin/delete-education-direction/${id}`,
    DELETE_EDUCATION_PROGRAM:(id)=>`${DOMAIN_WITH_PORT}/api/v2/education/admin/delete-education-program/${id}`,
    DELETE_COMPANY_ACTIVITY: (id)=>`${DOMAIN_WITH_PORT}/api/v2/company/admin/delete-company-activity/${id}`,

    DELETE_COMPANY_PROFESSION: (id)=>`${DOMAIN_WITH_PORT}/api/v2/education/admin/delete-profession/${id}`,


}

export const ADMIN_COMPANY = {
    GET_INFORMATION: `${DOMAIN_WITH_PORT}/api/v2/company/get-reference-information`,
}



export const ADMIN_STUDENTS = {
    GET_FILES_LOGS: (page, size, sort,other={}) => `${DOMAIN_WITH_PORT}/api/v2/student-job/admin/get-jobs?`+ new URLSearchParams({
        page,
        size,
        sort,
        ...other
    }),
    UPDATE_MARKS:{
        COMPETENCE: `${DOMAIN_WITH_PORT}/api/v2/mark/admin/update-student-competence/`,
        ACHIEVEMENT:`${DOMAIN_WITH_PORT}/api/v2/mark/admin/update-student-achievement/`,
        ACTIVITY:`${DOMAIN_WITH_PORT}/api/v2/mark/admin/update-student-activity/`,
        WALLET: `${DOMAIN_WITH_PORT}/api/v2/mark/admin/update-student-wallet/`,
        RSV: `${DOMAIN_WITH_PORT}/api/v2/mark/admin/update-student-rsv`,
        DPO:(studentId) => `${DOMAIN_WITH_PORT}/api/v2/mark/admin/create-student-dpo/${studentId}`,
    },
    GET_DPO:`${DOMAIN_WITH_PORT}/api/v2/mark/admin/get-dpo`,
    GET_ALL: (page, size, sort,otherParams) => {
        return `${DOMAIN_WITH_PORT}/api/v2/student/admin/get-students?` +
            new URLSearchParams({
                page,
                size,
                sort,
                ...otherParams,
            });
    },
    UPLOAD_ACHIEVEMENTS:{
        DPO: (jobId,fileName) =>`${DOMAIN_WITH_PORT}/api/v2/mark/admin/upload-student-dpo?${jobId ? `jobId=${jobId}&` : ``}fileName=${fileName}`,
        COMPETENCE: (jobId,fileName) =>`${DOMAIN_WITH_PORT}/api/v2/mark/admin/upload-student-competence?${jobId ? `jobId=${jobId}&` : ``}fileName=${fileName}`,
        RSV:(jobId,fileName) => `${DOMAIN_WITH_PORT}/api/v2/mark/admin/upload-student-rsv?${jobId ? `jobId=${jobId}&` : ``}fileName=${fileName}`,
        ACTIVITY:(jobId,fileName) =>`${DOMAIN_WITH_PORT}/api/v2/mark/admin/upload-student-activity?${jobId ? `jobId=${jobId}&` : ``}fileName=${fileName}`,
    },
    CREATE_STUDENTS_JSON: (jobId,fileName) => `${DOMAIN_WITH_PORT}/api/v2/student/admin/upload-student?${jobId ? `jobId=${jobId}&` : ``}fileName=${fileName}`,
    UPDATE_STUDENT: (id)=>`${DOMAIN_WITH_PORT}/api/v2/student/admin/update-student/${id}`,
    CREATE_STUDENT: `${DOMAIN_WITH_PORT}/api/v2/student/admin/create-student`,
    GET_STUDENT_MARKS: (id)=>`${DOMAIN_WITH_PORT}/api/v2/mark/admin/get-student-mark/${id}`,
    RESET_PASS: `${DOMAIN_WITH_PORT}/api/auth/reset-student-password`,
    GET_REFERENCE_INFORMATION: `${DOMAIN_WITH_PORT}/api/v2/student/admin/get-reference-information`,
    GET_ONE: (id) => `${DOMAIN_WITH_PORT}/api/v2/student/admin/get-student/${id}`

}


export const GET_RESPONSES_BY_OFFER_ID = (page, size, sort, offerId) => {
    return `${DOMAIN_WITH_PORT}/api/offer/get-job-responses/${offerId}?` +
        new URLSearchParams({
            page,
            size,
            sort,
        });
}

export const GET_CITY_BY_ID = (id) =>
    `${DOMAIN_WITH_PORT}/api/offer/get-city-info/${id}`;

export const CREATE_OFFER =
    `${DOMAIN_WITH_PORT}/api/offer/create`;
export const DELETE_OFFER = (id) =>
    `${DOMAIN_WITH_PORT}/api/offer/delete/${id}`;
export const UPDATE_OFFER = (id) =>
    `${DOMAIN_WITH_PORT}/api/offer/update/${id}`;

export const GET_OFFERS_INFO =
    `${DOMAIN_WITH_PORT}/api/offer/get-reference-information`;

export const GET_OFFERS_BY_ID = (id) =>
    `${DOMAIN_WITH_PORT}/api/offer/get-offer/` + id

export const GET_OFFERS_BY_USER_ID = (page, size, sort, otherParams, paramsInUrl = false) => {
    /*if(!paramsInUrl)
        `${DOMAIN_WITH_PORT}/api/offer/get-offers`+new URLSearchParams({
            page,
            size,
            sort,
            ...otherParams
        });*/
}

export const RESET_PASSWORD_CONTACT_PERSON  = `${DOMAIN_WITH_PORT}/api/auth/admin/reset-password`;
export const RESET_PASSWORD_CONTACT_PERSON_FROM_COMPANY  = `${DOMAIN_WITH_PORT}/api/auth/reset-company-password`;

export const GET_OFFERS_LIST = (page, size, sort, otherParams, paramsInUrl = false) => {
    if (!paramsInUrl)
        return `${DOMAIN_WITH_PORT}/api/offer/get-offers?` + new URLSearchParams({
            page,
            size,
            sort,
            ...otherParams
        });
    else
        return `${DOMAIN_WITH_PORT}/api/offer/get-offers?` + new URLSearchParams({
            page,
            size,
            sort,
        }) + '&' + Object.keys(otherParams).map(x => {
            let str = '';
            if (Array.isArray(otherParams[x]))
                str = otherParams[x].map(y => `${x}=${y}`).join('&');
            else
                str = `${x}=${otherParams[x]}`
            return str;
        }).join('&');

}


export const GET_OFFERS_JOB_TYPE = () =>
    `${DOMAIN_WITH_PORT}/api/offer/get-format-job-types`;

export const GET_OFFERS_TIME_TYPES = () =>
    `${DOMAIN_WITH_PORT}/api/offer/get-time-job-types`;

export const GET_OFFERS_STATUSES = () =>
    `${DOMAIN_WITH_PORT}/api/offer/get-offer-statuses`;

export const GET_OFFERS_TYPES = () =>
    `${DOMAIN_WITH_PORT}/api/offer/get-offer-types`;

export const GET_COMPETENCIES = () =>
    `${DOMAIN_WITH_PORT}/api/v2/mark/get-competencies`;


export const GET_OFFERS_CITIES = (page, size, sort, name) =>
    `${DOMAIN_WITH_PORT}/api/offer/get-cities?` +
    new URLSearchParams({
        page,
        size,
        sort,
        name
    });
export const GET_OFFERS_SPECIALIZATIONS = (page, size, sort) =>
    `${DOMAIN_WITH_PORT}/api/offer/get-specializations?` +
    new URLSearchParams({
        page,
        size,
        sort,
    });

export const GET_OFFERS_CITIES_BY_NAME = (name) =>
    `${DOMAIN_WITH_PORT}/api/offer/get-cities?` +
    new URLSearchParams({
        name,
    });


export const GET_OFFERS_SPECIALIZATIONS_BY_NAME = (name) =>
    `${DOMAIN_WITH_PORT}/api/offer/get-specializations?` +
    new URLSearchParams({
        name,
    });


export const GET_PROFILE = (id) =>
    `${DOMAIN_WITH_PORT}/api/portfolio/get/${id}`;

export const GET_COMPANIES_V2 = (page, size, sort,otherParams={}) =>
    `${DOMAIN_WITH_PORT}/api/v2/company/get-preview-companies?` +
    new URLSearchParams({
        page,
        size,
        sort,
        ...otherParams
    });

export const GET_COMPANIES = (page, size, sort) =>
    `${DOMAIN_WITH_PORT}/api/company/get_small?` +
    new URLSearchParams({
        page,
        size,
        sort,
    });

export const VERIFY_TOKEN = `${DOMAIN_WITH_PORT}/api/auth/verify`;

export const GET_CHAT_LIST = (page, size, sort) =>
    `${DOMAIN_WITH_PORT}/private-message/chat/last?` +
    new URLSearchParams({
        page,
        size,
        sort,
    });

// export const GET_CHAT = (chatId) => {
//     return (page, size, sort) => `${DOMAIN_WITH_PORT}/private-message/${chatId}/messages?` + new URLSearchParams({
//         page,
//         size,
//         sort
//     })
// };

export const GET_CHAT = (chatId) =>
    `${DOMAIN_WITH_PORT}/private-message/${chatId}/messages?`;

export const LOAD_QUALIFICATION = (page, size, sort) =>
    `${DOMAIN_WITH_PORT}/api/edu/get/qualification?` +
    new URLSearchParams({
        page,
        size,
        sort,
    });
export const ADD_QUALIFICATION = `${DOMAIN_WITH_PORT}/api/edu/add/qualification`;
export const REMOVE_QUALIFICATION = (qualificationId) =>
    `${DOMAIN_WITH_PORT}/api/edu/delete/qualification/${qualificationId}`;

export const LOAD_PROFESSION = `${DOMAIN_WITH_PORT}/api/edu/get/profession`;
export const ADD_PROFESSION = `${DOMAIN_WITH_PORT}/api/edu/add/profession`;
export const REMOVE_PROFESSION = (professionId) =>
    `${DOMAIN_WITH_PORT}/api/edu/delete/profession/${professionId}`;

export const LOAD_INSTITUTE = (page, size, sort) =>
    `${DOMAIN_WITH_PORT}/api/edu/get/institute?` +
    new URLSearchParams({
        page,
        size,
        sort,
    });

export const GET_ALL_INSTITUTES = `${DOMAIN_WITH_PORT}/api/edu/get/all/institute`;

export const GET_ALL_EDU_DIRECTIONS = `${DOMAIN_WITH_PORT}/api/v2/education/get-education-directions`;

export const ADD_INSTITUTE = `${DOMAIN_WITH_PORT}/api/edu/add/institute`;
export const REMOVE_INSTITUTE = (instituteId) =>
    `${DOMAIN_WITH_PORT}/api/edu/delete/institute/${instituteId}`;

//team
export const INVITE_TO_COMMAND = (studentId) =>
    `${DOMAIN_WITH_PORT}/api/command/invite/student/${studentId}`;
export const KICK_FROM_COMMAND = (studentId) =>
    `${DOMAIN_WITH_PORT}/api/command/kick/student/${studentId}`;
export const KNOCK_TO_COMMAND = (companyId) =>
    `${DOMAIN_WITH_PORT}/api/command/knock/company/${companyId}`;
export const LEAVE_COMMAND = (companyId) =>
    `${DOMAIN_WITH_PORT}/api/command/leave/company/${companyId}`;
export const APPROVE_INVITATION = (invitationId) =>
    `${DOMAIN_WITH_PORT}/api/command/approve/${invitationId}`;
export const REJECT_INVITATION = (invitationId) =>
    `${DOMAIN_WITH_PORT}/api/command/reject/${invitationId}`;

export const GET_COMMAND = (page, size, sort) =>
    `${DOMAIN_WITH_PORT}/api/command/get/command?` +
    new URLSearchParams({
        page,
        size,
        sort,
    });
export const GET_KNOCKS = (page, size, sort) =>
    `${DOMAIN_WITH_PORT}/api/command/get/knocks?` +
    new URLSearchParams({
        page,
        size,
        sort,
    });
export const GET_INVITES = (page, size, sort) =>
    `${DOMAIN_WITH_PORT}/api/command/get/invites?` +
    new URLSearchParams({
        page,
        size,
        sort,
    });

export const GET_OUTGOINGS = (page, size, sort) =>
    `${DOMAIN_WITH_PORT}/api/command/get/outgoing?` +
    new URLSearchParams({
        page,
        size,
        sort,
    });

// update profile
export const UPDATE_COMPANY_MAIN_V2 = (id) =>
    `${DOMAIN_WITH_PORT}/api/v2/company/update-company/${id}`;
export const UPDATE_COMPANY_MAIN = (id) =>
    `${DOMAIN_WITH_PORT}/api/company/update/${id}`;
export const UPDATE_COMPANY_CONTACT_PERSON = (id) =>
    `${DOMAIN_WITH_PORT}/api/company/person/update/${id}`;
export const UPDATE_COMPANY_PROFESSIONS = (id) =>
    `${DOMAIN_WITH_PORT}/api/edu/elected/profession/${id}`;

export const UPDATE_AVATAR = (id) =>
    `${DOMAIN_WITH_PORT}/api/file/upload/avatar`;

export const UPDATE_AVTAR_V2 = (id) =>
    `${DOMAIN_WITH_PORT}/api/file/upload-avatar/${id}`;
export const UPDATE_AVATAR_PRODUCT = (id) => `${DOMAIN_WITH_PORT}/api/file/upload-product/${id}`;

// feedback
export const GET_FEEDBACK_FROM_ME = (studentId) =>
    `${DOMAIN_WITH_PORT}/api/feedback/company/get/${studentId}`;
export const ADD_FEEDBACK = (studentId) =>
    `${DOMAIN_WITH_PORT}/api/feedback/add/${studentId}`;
export const GET_FEEDBACK_FROM_ALL_COMPANIES = (studentId) =>
    `${DOMAIN_WITH_PORT}/api/v2/mark/get-student-competence-feedback/${studentId}`;
export const GET_FEEDBACK_FROM_ADMIN_PAGINATION = (studentId) => `${DOMAIN_WITH_PORT}/api/v2/mark/admin/get-student-competence-feedback/${studentId}?`
export const UPDATE_FEEDBACK_FROM_COMPANY = (studentId) =>
    `${DOMAIN_WITH_PORT}/api/v2/mark/update-student-competence-feedback/${studentId}`;
export const GET_FEEDBACK_FOR_ME = `${DOMAIN_WITH_PORT}/api/v2/mark/get-my-feedback`;

export const UPDATE_STUDENT_PROFILE = (studentId) => `${DOMAIN_WITH_PORT}/api/v2/student/update-student/${studentId}`;


export const GET_ALL_COMPETENCIES = `${DOMAIN_WITH_PORT}/api/competence/get/all/competence`;
export const GET_ALL_COMPETENCIES_PAGINATION = (page,size,sort,otherParams) => `${DOMAIN_WITH_PORT}/api/v2/mark/get-competencies?` +
    new URLSearchParams({
        page,
        size,
        sort,
        ...otherParams
    });
export const GET_ALL_CATEGORIES = `${DOMAIN_WITH_PORT}/api/v2/mark/get-activities`;

export const GET_PROFESSIONS_V2 = (page, size, sort,otherParams) =>  `${DOMAIN_WITH_PORT}/api/v2/education/admin/get-professions?` +
    new URLSearchParams({
        page,
        size,
        sort,
        ...otherParams
    });

export const GET_PROFESSIONS = (page, size, sort,otherParams) =>
    `${DOMAIN_WITH_PORT}/api/v2/education/get-professions?` +
    new URLSearchParams({
        page,
        size,
        sort,
        ...otherParams
    });

export const GET_EDU_PROGRAMS = (page, size, sort) =>
    `${DOMAIN_WITH_PORT}/api/edu/get/eduprogram?` +
    new URLSearchParams({
        page,
        size,
        sort,
    });

export const GET_EDU_DIRECTIONS = (page, size, sort) =>
    `${DOMAIN_WITH_PORT}/api/edu/get/edudirection?` +
    new URLSearchParams({
        page,
        size,
        sort,
    });

// shop

export const GET_PRODUCT_LIST = (page, size, sort, otherParams) =>
    `${DOMAIN_WITH_PORT}/api/v2/shop/get-product?` +
    new URLSearchParams({
        page,
        size,
        sort,
        ...otherParams,
    });

export const GET_ALL_PRODUCT_LIST = (companyId) => (page, size, sort) =>
    `${DOMAIN_WITH_PORT}/api/shop/get-company-all-products/${companyId}?` +
    new URLSearchParams({
        page,
        size,
        sort,
    });

export const GET_PRODUCT_LIST_OF_COMPANY = (companyId) => (page, size, sort) =>
    `${DOMAIN_WITH_PORT}/api/shop/get/companies-product/${companyId}?` +
    new URLSearchParams({
        page,
        size,
        sort,
    });

export const GET_VACANCY_LIST_OF_COMPANY = (companyId) => {
    return (page, size, sort) =>
        `${DOMAIN_WITH_PORT}/api/vacancy/get/companies-vacancy/${companyId}?` +
        new URLSearchParams({
            page,
            size,
            sort,
        });
};

export const ADD_PRODUCT = `${DOMAIN_WITH_PORT}/api/shop/add/product`;

export const DELETE_PRODUCT = (productId) =>
    `${DOMAIN_WITH_PORT}/api/v2/shop/delete-product/${productId}`;

export const UPLOAD_PRODUCT_IMAGE = (productId) =>
    `${DOMAIN_WITH_PORT}/api/file/upload/product/${productId}`;

// ftp server
export const DOWNLOAD_FILE = `${DOMAIN_WITH_PORT}/api/file/download/`; // need to use with fileId
export const GET_DEFAULT_AVATARS = `${DOMAIN_WITH_PORT}/api/file/get-default-avatar`;

export const GET_OR_CREATE_CHAT = (otherUserId) =>
    `${DOMAIN_WITH_MESSAGING_PORT}/api/chat/get-or-create/${otherUserId}`;

export const GET_PAGED_CHATS = (
    page = 0,
    size = 10,
    sort = "lastMessageId,desc",
    filters,
) => `${DOMAIN_WITH_MESSAGING_PORT}/api/chat/get-chat-info?`+
new URLSearchParams({
    page,
    size,
    sort,
    ...filters,
});

export const POST_PAGED_CHATS = (
    page = 0,
    size = 10,
    sort = "lastMessageId,desc",
) => `${DOMAIN_WITH_MESSAGING_PORT}/api/chat/get-chat-info
?page=${page}&size=${size}&sort=${sort}`;


export const GET_CHAT_MESSAGES =
    (chatId) =>
        (page = 0, size = 10, sort = "lastMessageDate,desc") =>
            `${DOMAIN_WITH_MESSAGING_PORT}/api/chat/get-message/${chatId}?page=${page}&size=${size}&sort=${sort}`;

export const GET_PRODUCT = (productId) =>
    `${DOMAIN_WITH_PORT}/api/shop/get-product/${productId}`;

export const EDIT_AUCTION = (auctionId) =>
    `${DOMAIN_WITH_PORT}/api/shop/update-auction/${auctionId}`;

export const ADD_AUCTION = (productId) =>
    `${DOMAIN_WITH_PORT}/api/shop/add/auction/${productId}`;

export const ADMIN_CREATE_COMPANY = `${DOMAIN_WITH_PORT}/api/v2/company/admin/create-company`;
export const ADMIN_UPDATE_COMPANY = companyId => `${DOMAIN_WITH_PORT}/api/v2/company/admin/update-company/${companyId}`;
export const ADMIN_GET_COMPANY = companyId => `${DOMAIN_WITH_PORT}/api/v2/company/admin/get-company/${companyId}`;

export const GET_COMPANIES_ACTIVITIES = (page, size, sort,otherParams)  => {
    return  `${DOMAIN_WITH_PORT}/api/v2/company/admin/get-company-activity/?` + new URLSearchParams({
        page,
        size,
        sort,
        ...otherParams
    });
}
export const GET_COMPANIES_ACTIVITIES_FROM_COMPANY = (page, size, sort,otherParams) =>{
    return  `${DOMAIN_WITH_PORT}/api/v2/company/get-company-activity?` + new URLSearchParams({
        page,
        size,
        sort,
        ...otherParams
    });

}

export const ADD_AUCTION_BID = (auctionId, bid) =>
    `${DOMAIN_WITH_PORT}/api/shop/add-bid/${auctionId}`;

export const GET_TOP_BIDS = (auctionId) =>
    `${DOMAIN_WITH_PORT}/api/shop/get-top-bids/${auctionId}`;

export const GET_COMPANY_PROFILES = `${DOMAIN_WITH_PORT}/api/company/get-profiles`;
export const GET_COMPANY_PROFILES_V2 =
    (page, size, sort, otherParams) => `${DOMAIN_WITH_PORT}/api/v2/company/admin/get-companies?` +
        new URLSearchParams({
                page,
                size,
                sort,
                ...otherParams
            });



export const SHOP={
    REMOVE_PRODUCT:(productId) => `${DOMAIN_WITH_PORT}/api/v2/shop/delete-product/${productId}`,
    CREATE_PRODUCT: `${DOMAIN_WITH_PORT}/api/v2/shop/create-product`,
    GET_PRODUCT_BY_ID: (productId) => `${DOMAIN_WITH_PORT}/api/v2/shop/get-product/${productId}`,
    UPDATE_PRODUCT: (productId) => `${DOMAIN_WITH_PORT}/api/v2/shop/update-product/${productId}`,

    CREATE_BID: (auctionId,bid) => `${DOMAIN_WITH_PORT}/api/v2/shop/create-auction-bid/${auctionId}?bidAmount=${bid}`,
    UPDATE_BID: (auctionId, bid) => `${DOMAIN_WITH_PORT}/api/v2/shop/update-auction-bid-amount/${auctionId}?bidAmount=${bid}`,

    GET_AUCTION_BY_ID: (auctionId) =>`${DOMAIN_WITH_PORT}/api/v2/shop/get-auction?`,
    CREATE_AUCTION:`${DOMAIN_WITH_PORT}/api/v2/shop/create-auction`,
    UPDATE_AUCTION: (auctionId) => `${DOMAIN_WITH_PORT}/api/v2/shop/update-auction/${auctionId}`,
    DELETE_AUCTION: (auctionId) => `${DOMAIN_WITH_PORT}/api/v2/shop/delete-auction/${auctionId}`,
    GET_AUCTIONS: (page, size, sort, otherParams) => `${DOMAIN_WITH_PORT}/api/v2/shop/get-auction?` +
        new URLSearchParams({
            page,
            size,
            sort,
            ...otherParams
        })
}


export const CREATE_COMPANY = `${DOMAIN_WITH_PORT}/api/company/create`;

export const UPDATE_COMPANY = (companyId) =>
    `${DOMAIN_WITH_PORT}/api/company/update/${companyId}`;

export const GET_COMPANY_STATUSES = `${DOMAIN_WITH_PORT}/api/company/get-company-statuses`;

export const UPDATE_COMPANY_STATUS = (companyId) =>
    `${DOMAIN_WITH_PORT}/api/company/update-company-status/${companyId}`;

export const GET_PRODUCT_STASUSES = `${DOMAIN_WITH_PORT}/api/shop/get-product-statuses`;

export const GET_ALL_PRODUCTS = (page, size, sort, otherParams) => `${DOMAIN_WITH_PORT}/api/v2/shop/get-product?` +
    new URLSearchParams({
        page,
        size,
        sort,
        ...otherParams
    });


export const UPDATE_PRODUCT_STATUS = (productId) =>
    `${DOMAIN_WITH_PORT}/api/shop/update-product-status/${productId}`;
