import React from'react';
import {SwiperSlide} from "swiper/react";
import modul2_1 from "@pages/StartPage/assets/images/modul2-1.png";
import modul2_2 from "@pages/StartPage/assets/images/modul2-2.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import module1 from "@pages/StartPage/assets/images/modul1.png";

       const tabs = [
         <SwiperSlide className={"modules-tab-2"} style={{height: "100%"}}>
             <div className="modul-blk">
                 <div className="modul-info">
                     <div className="modul-title">
                         <span className="titles">Админ-панель или целая CRM-система</span>
                         <p>Мы не только разрабатываем платформу, но и создаем CRM-систему для ее личного
                             сопровождения.</p>
                         <ul>
                             <li>мессенджер</li>
                             <li>студенты</li>
                             <li>товары</li>
                             <li>аукционы</li>
                             <li>вакансии</li>
                         </ul>
                     </div>
                 </div>
                 <div className="modul-image">
                     <LazyLoadImage
                         alt={"modul2_1"}
                         effect={"blur"}
                         src={modul2_1} />
                 </div>
             </div>
         </SwiperSlide>,
            <SwiperSlide className={"modules-tab-2"} style={{height: "100%"}}>
                <div className="modul-blk">
                    <div className="modul-info">
                        <div className="modul-title">
                            <span className="titles">Админ-панель или целая CRM-система</span>
                            <p>Мы не только разрабатываем платформу, но и создаем CRM-систему для ее личного
                                сопровождения.</p>
                            <ul>
                                <li>мессенджер</li>
                                <li>студенты</li>
                                <li>товары</li>
                                <li>аукционы</li>
                                <li>вакансии</li>
                            </ul>
                        </div>
                    </div>
                    <div className="modul-image">
                        <LazyLoadImage
                            alt={"modul2_2"}
                            effect={"blur"}
                            src={modul2_2} />
                    </div>
                </div>

            </SwiperSlide>
        ]
export default tabs;