import React from "react";
import styled from "styled-components";

import ProductsListHeader from "./components/ProductsListHeader";
import ProductsList from "./components/ProductsList";

const ProductsTable = ({products,refresh}) => {
    return (
        <table className="tables table-status products-table">

            <ProductsListHeader/>

            <ProductsList refresh={refresh} products={products}/>
        </table>


    );
};

export default React.memo(ProductsTable);

const TableScrollWrapper = styled.div`
  overflow: auto;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`;
