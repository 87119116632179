import React from "react";

import { useDownloadFile } from "@hooks/useDownloadFile";
import { DOWNLOAD_FILE } from "@api/endpoints";
import CompanyActivities from "@pages/ProfilePage/components/ProfileCompany/components/CompanyActivities";

const CompanyItemLoad= ({ company, onClick }) => {

  return (
      <div  className="col-block load-company">
          <div className="list-company-blk blks">

          </div>

      </div>

  );
};

export default React.memo(CompanyItemLoad);
