import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  GET_FEEDBACK_FOR_ME,
  GET_FEEDBACK_FROM_ALL_COMPANIES, UPDATE_FEEDBACK_FROM_COMPANY,
} from "../api/endpoints";
import {useVariables} from "@hooks/useVariables";
import {toast} from "react-toastify";

export const useShowFeedback = (student) => {
  const user = useSelector((state) => state.user.user);
  const requestPut = useVariables().constRequestOptionsPUT;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${user.type} ${user.accessToken}`,
    },
  };
  const [feedback, setFeedback] = useState([]);

  const updateMark = (index,mark) =>{
    const competence = {
      "competenceId": feedback[0].competenceFeedbacks[index].studentCompetence.competence.competenceId,
      "competenceMark": mark
    }
    const oldFeeds = [...feedback];
    oldFeeds[0].competenceFeedbacks[index].competenceMark = mark;
    setFeedback([...oldFeeds]);

  }
  const saveNewFeedback = () => {
    const request = requestPut(feedback[0].competenceFeedbacks.map(competence => ({competenceMark:competence.competenceMark,competenceId:competence.studentCompetence.competence.competenceId})));
    fetch(UPDATE_FEEDBACK_FROM_COMPANY(student.id),request).then((response) => response.json()).then((json) => {
      toast.success("Обратная связь отправлена", {
        position: toast.POSITION.TOP_CENTER,
      });
    })
  }

  const getFeedback = (studentId) => {
    const url = user.roles.includes("COMPANY")
      ? GET_FEEDBACK_FROM_ALL_COMPANIES(studentId)
      : GET_FEEDBACK_FOR_ME;
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((json) => setFeedback(json))
      .catch(() => console.log("Произошла ошибка"));
  };

  useEffect(() => {
    getFeedback(student.id);
  }, []);

  return {
    feedback,
    updateMark,
    saveNewFeedback
  };
};
