import "@assets/styles/pages/rating.scss";
import React, {useEffect, useMemo, useState} from "react";
import _ from "lodash";

import {useRating} from "./hooks/useRating";
import {useVariables} from "@hooks/useVariables";

import CustomSelect from "@components/CustomSelect";
import SearchLine from "@components/SearchLine";

import RatingListItem from "./components/RatingListItem";
import InstitutesFilterSelect from "./components/InstitutesFilterSelect";
import QualificationsFilterSelect from "./components/QualificationsFilterSelect";
import ProgramsFilterSelect from "./components/ProgramsFilterSelect";
import DirectionsFilterSelect from "./components/DirectionsFilterSelect";
import ProfessionsFilterSelect from "./components/ProfessionsFilterSelect";

import {GET_ALL_EDU_DIRECTIONS} from "@api/endpoints";
import {useSelector} from "react-redux";
import FilterAccordion from "@pages/CareersPage/components/FilterAccordion";
import StudentsFilters from "@pages/RatingPage/StudentsFilters";
import BlockLoads from "@components/BlockLoads";
import RatingItemLoad from "@pages/RatingPage/components/RatingItemLoad";

const RatingPage = () => {
    const {
        rating,
        competencies,
        categories,
        applyFilters,
        clearFilters,
        redirectStudentPage,
        changeCompetenceSortHandler,
        isCustomSelectActive,
        loading
    } = useRating();
    const user = useSelector((state) => state.user.user);
    const [collapseFilter, setCollapseFilter] = useState(true);
    const [filters, setFilters] = useState({});
    const handleFilterSelect = (filterId, selectedIds) => {
        console.log("HI",filterId, selectedIds);
        setFilters((state) => ({...state, [filterId]: selectedIds}));
    };
    const competenciesAlphabetical = useMemo(
        () => _.sortBy(competencies, ["name"]),
        [competencies],
    );

    const categoriesAlphabetical = useMemo(
        () => _.sortBy(categories, ["name"]),
        [categories],
    );

    const handleApplyClick = () => {
        applyFilters(filters);
    };
    const handleResetClick = () => {
        const emptyFilters = {};
        setFilters(emptyFilters);
        clearFilters();
    };

    const handleFIOChange = (value) => {
        setFilters((oldFilters) => {
            const newFilters = {...oldFilters, name: value};
            applyFilters(newFilters);

            return newFilters;
        });
    };

    const [allEduDirections, setAllEduDirections] = useState();
    const requestOptionsGET = useVariables().requestOptionsGET;
    const getAllEduDirections = () => {
        fetch(GET_ALL_EDU_DIRECTIONS, requestOptionsGET)
            .then((response) => response.json())
            .then((json) => {
                setAllEduDirections(json.content);
            })
            .catch(() => console.log("Произошла ошибка"));
    };

    useEffect(() => {
        getAllEduDirections();
    }, []);

    const allDirectionIdToNameMap = useMemo(
        () =>
            allEduDirections?.reduce((all, direction) => {
                all[direction.educationDirectionId] = direction.name;
                return all;
            }, {}),
        [allEduDirections],
    );

    return (
        <div className="rating filtered_page page newStyle">
            <h1>Рейтинг</h1>

            <div className="rating_block filtered_page_block">
                <div className="rating_content filtered_page_content">
                    <SearchLine
                        value={filters.name}
                        onChangeValue={handleFIOChange}
                        placeholder="Поиск по ФИО"
                    />
                    <div className="sort_menu">
                        <div className="sorts">
                            <CustomSelect
                                name="competencies"
                                options={competenciesAlphabetical}
                                isMulti
                                placeholder={
                                    <span className="i-sort sort_item">
                                    Сортировка по компетенциям
                                  </span>
                                }
                                maxElements={3}
                                isDisabled={isCustomSelectActive.competencies}
                                onChange={(value) => {
                                    changeCompetenceSortHandler("competencies", value);
                                }}
                            />
                            <CustomSelect
                                name="activity"
                                options={categoriesAlphabetical}
                                isMulti={false}
                                placeholder={
                                    <span className="i-sort sort_item">
                                        Сортировка по областям деятельности
                                      </span>
                                }
                                isDisabled={isCustomSelectActive.polycoin}
                                onChange={(value) => {
                                    console.log("SELECT",value)
                                    changeCompetenceSortHandler("categories", value);
                                }}
                            />
                        </div>
                        <button
                            className="i-filter filter_button"
                            onClick={() => setCollapseFilter(false)}
                        />
                    </div>
                    <ul className="rating_list rating">
                        {loading &&
                            <BlockLoads component={<RatingItemLoad/>} repeat={25}/>
                        }
                        {rating.length > 0 && allDirectionIdToNameMap ? (
                            rating.map((el, index) => {
                                const directions = el.directionIds?.map(
                                    (id) => allDirectionIdToNameMap[id],
                                );

                                return (
                                    <RatingListItem
                                        key={el.id}
                                        el={el}
                                        index={index}
                                        directions={directions}
                                        onClick={() => redirectStudentPage(el.id)}
                                    />
                                );
                            })
                        ) : (
                            <div className="empty">Здесь пока пусто</div>
                        )}
                    </ul>
                </div>
                <StudentsFilters filters={filters} setCollapseFilter={setCollapseFilter}
                                 collapseFilter={collapseFilter} handleFilterSelect={handleFilterSelect}
                                 handleApplyClick={handleApplyClick}
                                 handleResetClick={handleResetClick}
                />
                {/*<form
          ref={filtersFormRef}
          className="filter_block"
          onSubmit={applyFilters}
        >
          <h2>Фильтры</h2>


          </div>
        </form>*/}
            </div>
        </div>
    );
};

export default RatingPage;
