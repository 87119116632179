import "@assets/styles/pages/page_from_admin.scss";
import "@assets/styles/pages/catalog.scss";

import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import ModalCustom from "@components/ModalCustom";
import Institutes from "@pages/AdminCatalog/Tabs/Institutes";
import Directions from "@pages/AdminCatalog/Tabs/Directions";
import Programs from "@pages/AdminCatalog/Tabs/Programs";
import Qualifications from "@pages/AdminCatalog/Tabs/Qualifications";
import {ADMIN_EDUCATION} from "@api/endpoints";
import {useVariables} from "@hooks/useVariables";
import {toast} from "react-toastify";
import {usePagination} from "@hooks/usePagination";
import CompaniesActivities from "@pages/AdminCatalog/Tabs/CompaniesActivities";
import ModalContent from "@pages/AdminCatalog/ModalContent";
import Professions from "@pages/AdminCatalog/Tabs/Professions";

function CatalogFromAdminPage() {
    const user = useSelector((state) => state.user.user);
    const [activeModal, setActiveModal] = useState(false);
    const [activeUpdateMethod,setActiveMethod] = useState(0);
    const [activeModalRemove, setActiveModalRemove] = useState(false);
    const [selectedObject, setSelectedObject] = useState(null);
    const requestPost = useVariables().constRequestOptionsPOST
    const requestDelete = useVariables().requestOptionsDELETE
    const requestPut = useVariables().constRequestOptionsPUT
    const formRef = useRef();
    const [activeTab, setActiveTab] = useState(0);
    const tabsEndpoints = [
        {
            GET:ADMIN_EDUCATION.LOAD_INSTITUTES,
            UPDATE: ADMIN_EDUCATION.UPDATE_INSTITUTE,
            CREATE: ADMIN_EDUCATION.CREATE_INSTITUTE,
            REMOVE: ADMIN_EDUCATION.DELETE_INSTITUTE,
            idField:"instituteId",
        },
        {
            GET: ADMIN_EDUCATION.LOAD_DIRECTIONS,
            UPDATE: ADMIN_EDUCATION.UPDATE_EDUCATION_DIRECTION,
            CREATE: ADMIN_EDUCATION.CREATE_EDUCATION_DIRECTION,
            REMOVE: ADMIN_EDUCATION.DELETE_EDUCATION_DIRECTION,
            idField: "educationDirectionId",
        },
        {
            GET: ADMIN_EDUCATION.LOAD_PROGRAMS,
            UPDATE: ADMIN_EDUCATION.UPDATE_EDUCATION_PROGRAM,
            CREATE: ADMIN_EDUCATION.CREATE_EDUCATION_PROGRAM,
            REMOVE: ADMIN_EDUCATION.DELETE_EDUCATION_PROGRAM,
            idField: "educationProgramId"
        },
        {
            GET: ADMIN_EDUCATION.LOAD_QUALIFICATIONS,
            UPDATE: ADMIN_EDUCATION.UPDATE_QUALIFICATION,
            CREATE: ADMIN_EDUCATION.CREATE_QUALIFICATION,
            REMOVE: ADMIN_EDUCATION.DELETE_QUALIFICATION,
            idField: "qualificationId"
        },


        {
            GET: ADMIN_EDUCATION.LOAD_PROFESSION,
            UPDATE: ADMIN_EDUCATION.UPDATE_COMPANY_PROFESSION,
            CREATE: ADMIN_EDUCATION.CREATE_COMPANY_PROFESSION,
            REMOVE: ADMIN_EDUCATION.DELETE_COMPANY_PROFESSION,
            idField: "professionId"
        },
        {
            GET: ADMIN_EDUCATION.LOAD_COMPANIES_ACTIVITIES,
            UPDATE: ADMIN_EDUCATION.UPDATE_COMPANY_ACTIVITY,
            CREATE: ADMIN_EDUCATION.CREATE_COMPANY_ACTIVITY,
            REMOVE: ADMIN_EDUCATION.DELETE_COMPANY_ACTIVITY,
            idField: "activityId"
        }
    ]

    const requestGet = useVariables().requestOptionsGET
    const [filters, setFilters] = useState({size: 25})
    const data = usePagination(tabsEndpoints[activeTab].GET, requestGet, "", "", filters, true)

    const tabs = [
        "Институты",
        "Направления",
        "Программы",
        "Квалификации",
        "Профессии",
        "Области деятельности для компаний",
    ];
    const create = () => {
        setSelectedObject(null)
        setActiveModal(true);
    }
    const update = (object) => {
        setSelectedObject(object)
        setActiveModal(true)
    }
    const remove = (object) => {
        setSelectedObject(object)
        setActiveModalRemove(true)
    }

    const tabsElements = [
        <Institutes setUpdateMe={setActiveMethod} data={data} creat_={create} update={update} remove={remove}/>,
        <Directions creat_={create} update={update} data={data} remove={remove}/>,
        <Programs creat_={create} update={update} data={data} remove={remove}/>,
        <Qualifications creat_={create} update={update} data={data} remove={remove}/>,
        <Professions creat_={create} update={update} data={data} remove={remove}/>,
        <CompaniesActivities creat_={create} update={update} data={data} remove={remove}/>,
    ]
    const close = () => {
        setSelectedObject(null)
        setActiveModal(false)
    }
    const save = (e) => {
        e.preventDefault();
        let frm = new FormData(formRef.current);
        let data_ = {};
        for (let [key, value] of frm) {
            data_[key] = value;
        }
        if(Object.values(data_).filter(x=>!x).length){
            toast.error("Заполните все поля", {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }
        if (selectedObject) {
            fetch(tabsEndpoints[activeTab].UPDATE(selectedObject[tabsEndpoints[activeTab].idField]), requestPut(data_)).then((response) => {
                toast.success("Запись отредактирована", {
                    position: toast.POSITION.TOP_CENTER,
                });
                data.refresh();
            }).catch((e)=>{
                toast.error("Ошибка", {
                    position: toast.POSITION.TOP_CENTER,
                });
            })
        } else {
            fetch(tabsEndpoints[activeTab].CREATE, requestPost(data_)).then((response) => {
                toast.success("Запись создана", {
                    position: toast.POSITION.TOP_CENTER,
                });
                data.refresh();
            }).catch((e)=>{

                toast.error("Ошибка", {
                    position: toast.POSITION.TOP_CENTER,
                });
            })
        }
        setActiveModal(false)
        setSelectedObject(null)
    }
    const deleteCatalogItem = () =>{
        fetch(tabsEndpoints[activeTab].REMOVE(selectedObject[tabsEndpoints[activeTab].idField]), requestDelete).then((response) => {
            toast.success("Запись удалена", {
                    position: toast.POSITION.TOP_CENTER,
                });
                data.refresh();
        })
        setActiveModalRemove(false)
        setSelectedObject(null)
    }

    useEffect(() => {
        data.refresh()
    },[activeTab])

    return (
        <div className="catalog page_from_admin newStyle">
            <h1>Справочники</h1>
            <div className="tabs tabs-scroll">
                <ul style={{marginTop: 20}} className="tabsmenu">
                    {tabs.map((item, index) => (
                        <li key={index} className={`tab1 ${activeTab === index ? "active" : ""}`}
                            onClick={() => setActiveTab(index)}
                        >{item}</li>
                    ))}

                </ul>
                <div className="tab-content">
                    {tabsElements[activeTab]}

                </div>


            </div>
            <ModalCustom
                title={"Удаление"}
                active={activeModalRemove}
                className={"autoWidth"}
                setActive={setActiveModalRemove}
            >
                <div className="delete_modal">
                    <p>Вы уверены, что хотите удалить "{selectedObject ? selectedObject.name :""}"?</p>
                    <div className="button_block">
                        <button
                            className="reset_button"
                            onClick={() => setActiveModalRemove(false)}
                        >
                            Отмена
                        </button>
                        <button
                            className="submit_button"
                            onClick={() => deleteCatalogItem()}
                        >
                            Да
                        </button>
                    </div>
                </div>
            </ModalCustom>
            <ModalCustom
                title={selectedObject ? "Редактирование" : "Создание"}
                active={activeModal}
                className={"autoWidth"}
                setActive={setActiveModal}
            >
               <ModalContent close={close} activeTab={activeTab} formRef={formRef} save={save} selectedObject={selectedObject}/>
            </ModalCustom>
        </div>
    );
}

export default CatalogFromAdminPage;
