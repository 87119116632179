import "@assets/styles/pages/profile.scss";

import { useEffect } from "react";
import { useSelector } from "react-redux";

import {IsCompany, IsStudent, useAuth} from "@hooks/useAuth";
import { useProfileStudent } from "@hooks/useProfileStudent";
import { useProfileCompany } from "@hooks/useProfileCompany";

import ProfileStudent from "./components/ProfileStudent";
import ProfileCompany from "./components/ProfileCompany";

const ProfilePage = () => {
  const user = useSelector((state) => state.user.user);
  //console.log("### user", user);

  const { loadUserData } = useAuth();
  const roles = user.roles;
  const isStudent = IsStudent()
  const isCompany = IsCompany()
  const { studentData } = useProfileStudent(user.id);
  const { companyData } = useProfileCompany(user.id);

  // TODO возможно лучше сделать loadUserData в LoginPage, чтобы метод принимал не user а token
  useEffect(() => {
    //loadUserData(user);
  }, []);

  return (
    <div className="profile">
      {isStudent && (
        <ProfileStudent studentData={studentData} />
      )}
      {isCompany && (
        <ProfileCompany companyData={companyData} />
      )}
    </div>
  );
};

export default ProfilePage;
