import React from "react";
import styled from "styled-components";
import {NavLink} from "react-router-dom";

const ProductsListHeader = () => {
  return (
      <thead>
      <tr>
          <th width={20}  style={{width:50}} className="add">
              <NavLink to="/admin/shop/create" state={{previousLocationPathname:"/admin/shop"}} className="btn btn-blue modal-link" ></NavLink>
          </th>
          <th>Фото</th>
          <th>Название</th>
          <th>Описание</th>

          <th>Компания</th>
          {/*<th>Статус</th>*/}
      </tr>
      </thead>

  );
};

export default React.memo(ProductsListHeader);

const ContactPerson = styled.div`
  width: 140px;
  display: flex;
  justify-content: center;
  font-weight: bold;
`;

const PersonFields = styled(ContactPerson)`
  justify-content: space-between;
  margin-top: 5px;
`;
