import React from "react";
import { Link } from "react-router-dom";

const HeaderBlock = () => (
  <div className="header_block">
    <Link to="/" className="logo" />
    {/*<span className="test_label">
      Проект на этапе пользовательского тестирования
    </span>*/}
    <div className="company_link">
      <span>
        Вход
        <span className="i-up_right"></span>
      </span>
    </div>
  </div>
);

export default React.memo(HeaderBlock);
