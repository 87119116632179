import "@assets/styles/pages/company_list.scss";
import React, {useEffect, useState} from "react";

import {useCompanyList} from "@hooks/useCompanyList";

import CompanyListItem from "./components/CompanyListItem";
import BlockLoads from "@components/BlockLoads";
import CompanyItemLoad from "@pages/CompanyListPage/components/CompanyItemLoad";
import Filters from "@pages/CompanyListPage/components/Filters";

const CompanyListPage = () => {
    const {companies, loading,clearFilters,setSort,sort, redirectCompanyPage,applyFilters, handleSearchByName,refresh} = useCompanyList();
    const [openedFilters, setOpenedFilters] = useState(true);
    const applyFilters_ = (filters) =>{
        let activities = filters.activities?.map(x=>x.activityId) || [];
        applyFilters({activityId:activities.join(",")});
    }
    useEffect(()=>{
        refresh();
    },[sort])
    return (
        <div className="list-company filtered_page page newStyle">
            <h1>Компании</h1>
            <div className="content">
                <div className="edit-profile">
                    <div className="search search_line">
                        <input onChange={handleSearchByName} type="text" className={""} placeholder="Поиск по названию"/>
                        <button type="submit"></button>
                    </div>
                    <div
                        onClick={()=>{
                            setSort(prevState => {
                                return (prevState.name === "desc" ?
                                    {name: "asc"} : {name: "desc"})
                            })
                        }}
                        className={`sorting ${sort.name === "desc"? "sorting-desc" : "sorting-asc"}`}>
                        <span>По алфавиту</span>
                    </div>
                    <div className="list-company-block row-block">
                        {loading &&
                            <BlockLoads component={<CompanyItemLoad/>} repeat={25}/>
                        }
                        {companies.map((company) => (
                            <CompanyListItem
                                key={company.id}
                                company={company}
                                onClick={redirectCompanyPage}
                            />
                        ))}
                    </div>
                </div>
                    <Filters handleApplyFilters={applyFilters_} handleClearFilters={clearFilters}
                             setOpenedFilters={setOpenedFilters}/>
            </div>

        </div>
        /* <div className="company_list  page newStyle">
           <h1>Представители компаний</h1>
           {/!* TODO waiting for endpoint search support *!/}
           {/!*<SearchLine placeholder="Поиск по названию" />*!/}

           <ul>
             {companies.map((company) => (
               <CompanyListItem
                 key={company.id}
                 company={company}
                 onClick={redirectCompanyPage}
               />
             ))}
           </ul>
         </div>*/
    );
};

export default CompanyListPage;
