import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useVariables } from "./useVariables";

import {
  APPROVE_INVITATION,
  GET_PORTFOLIO,
  GET_PORTFOLIO_BY_STUDENT_ID,
  GET_PROFILE,
  INVITE_TO_COMMAND,
  KICK_FROM_COMMAND, STUDENT_INFORMATION,
} from "../api/endpoints";

export const useProfileStudent = (studentId) => {
  const user = useSelector((state) => state.user.user);
  const requestOptions = useVariables().requestOptionsGET;
  const [student, setStudent] = useState({
    inviteStatus: "",
    invitationId: null,
    firstName: "",
    secondName: "",
    middleName: "",
    course: "",
    institute: { id: 0, name: "" },
    direction: { id: 0, name: "" },
    program: { id: 0, name: "" },
    qualification: { id: 0, name: "" },
    competences: [],
    professions: [],
    wallet: {
      currentBalance: 0,
      sumAllTime: 0,
      categories: [],
    },
  });

  useEffect(() => {
    if (user.professions) {
      setStudent((state) => ({
        ...state,
        professions: user.professions,
      }));
    }
  }, [user.professions]);

  useEffect(() => {
    if (user.newAvatar) {
      setStudent((state) => ({
        ...state,
        newAvatar: user.newAvatar,
      }));
    }
  }, [user.newAvatar]);

  const [activityPoints,setActivityPoints] = useState(null);

  const [competenceChartData, setCompetenceChartData] = useState({
    labels: [""],
    names:[""],
    datasets: [
      {
        bgColor: "#49A9BF",
        label: "за текущий семестр",
        data: [0],
      },
    ],
  });

  const [competenceChartDataDop, setCompetenceChartDataDop] = useState({
    labels: [""],
    names:[""],
    datasets: [
      {
        bgColor: "#49A9BF",
        label: "за текущий семестр",
        data: [0],
      },
    ],
  });

  const [competenceTableData, setCompetenceTableData] = useState([]);
  const [competenceTableDataDop, setCompetenceTableDataDop] = useState([]);
  const [achievements,setAchievements] = useState([]);
  const [dpos,setDpos] = useState([]);
  const [wallet,setWallet] = useState();
  const [rsvs,setRsvs] = useState([]);

  const getPortfolio = () => {
    const url = GET_PORTFOLIO_BY_STUDENT_ID(studentId);//studentId === user.id ? GET_PORTFOLIO : GET_PROFILE(studentId);
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        console.log("###LOAD PROFILE",json);
        setStudent(json);
      })
      .catch(() => console.log("Не удается подключиться к серверу"));
  };

  const getChartData = () => {
    console.log("GET MARKS",studentId,user.id)
    const url = STUDENT_INFORMATION.getMarks(studentId);//studentId === user.id ? GET_PORTFOLIO : GET_PROFILE(studentId);
    fetch(url, requestOptions)
        .then((response) => response.json())
        .then((json) => {
          setAchievements(json.achievements)
          setActivityPoints(json.activities)
          setDpos(json.dpos)
          setRsvs(json.rsvs)
          setWallet(json.wallet)
          const mainCompts = json.competences.filter(competence => competence.competence.competenceType.type === 'MAIN');
          const extraCompts = json.competences.filter(competence => competence.competence.competenceType.type === 'EXTRA');

          const mainCompetenciesIds = mainCompts.map(
              (el) => el.competence.name,
          );
          const mainCompetenciesData = mainCompts.map((el) => el.competenceScore);
          const extraCompetenciesIds = extraCompts.map(
              (el) => el.competence.name,
          );
          const extraCompetenciesData = extraCompts.map((el) => el.competenceScore);

          setCompetenceTableData(
              mainCompts.map((el) => {
                return {
                  id: el.competence.competenceId + 1,
                  name: el.competence.name,
                  points: el.competenceScore,
                };
              }),
          );
          setCompetenceTableDataDop(
              extraCompts.map((el) => {
                return {
                  id: el.competence.competenceId + 1,
                  name: el.competence.name,
                  points: el.competenceScore,
                };
              }),
          );
          setCompetenceChartDataDop((prevState) => {
            return {
              labels: extraCompetenciesIds,

              datasets: [
                {
                  ...prevState.datasets[0],
                  data: extraCompetenciesData,
                },
              ],
            };
          });
          setCompetenceChartData((prevState) => {
            return {
              labels: mainCompetenciesIds,

              datasets: [
                {
                  ...prevState.datasets[0],
                  data: mainCompetenciesData,
                },
              ],
            };
          });

        })
        .catch(() => console.log("Не удается подключиться к серверу"));
  };

  const inviteToCommand = () => {
    fetch(INVITE_TO_COMMAND(studentId), requestOptions)
      .then((response) => response.json())
      .then((json) => {
        setStudent((student) => {
          return {
            ...student,
            inviteStatus: "COMPANY_INVITE",
          };
        });
        console.log(json);
      })
      .catch(() => console.log("Не удается подключиться к серверу"));
  };

  const kickFromCommand = () => {
    fetch(KICK_FROM_COMMAND(studentId), requestOptions)
      .then((response) => response.json())
      .then((json) => {
        setStudent((student) => {
          return {
            ...student,
            inviteStatus: "NO_INVITE",
          };
        });
        console.log(json);
      })
      .catch(() => console.log("Не удается подключиться к серверу"));
  };

  const approveInvitation = (invitationId) => {
    fetch(APPROVE_INVITATION(invitationId), requestOptions)
      .then((response) => response.json())
      .then((json) => {
        setStudent((student) => {
          return {
            ...student,
            inviteStatus: "APPROVED",
          };
        });
        console.log(json);
      })
      .catch(() => console.log("Не удается подключиться к серверу"));
  };

  useEffect(() => {
    console.log("WTF NAX",(studentId !== user.id),user.roles.includes("STUDENT"))
    if (
      (studentId !== user.id && user.roles.includes("STUDENT")) ||
      (studentId !== user.id && user.roles.includes("COMPANY")) ||
      (studentId !== user.id && user.roles.includes("ADMIN"))
    ) {
      getPortfolio();
    }
  }, []);

  useEffect(() => {
    console.log("useEffect get marks",studentId === user.id,user.roles.includes("STUDENT"))
    if (
      (studentId !== user.id && user.roles.includes("STUDENT")) ||
      (studentId === user.id && user.roles.includes("STUDENT")) ||
      (studentId !== user.id && user.roles.includes("COMPANY"))
    ) {
      getChartData();
    }
  }, [student]);

  const studentData = {
    student,
    achievements,
    activityPoints,
    competenceChartData,
    competenceChartDataDop,
    competenceTableData,
    rsvs,
    wallet,
    dpos,
    competenceTableDataDop,
    inviteToCommand,
    kickFromCommand,
    approveInvitation,
  };

  return {
    studentData,
  };
};
