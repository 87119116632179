import { toast } from "react-toastify";
import { GET_PORTFOLIO,GET_PORTFOLIO_BY_STUDENT_ID, LOGIN_OTHER, LOGIN_STUDENT } from "@api/endpoints";
import {
  loadUserProfileAction,
  loginFailureAction,
  loginSuccessAction,
  logoutAction,
  reloadAction, updateUserProfessionsAction,
} from "../reducers/userReducer";

export const getUser = () => {
  return (dispatch) => {};
};

export const loginStudent = (token) => {

  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ casToken: token }),
    };

    fetch(LOGIN_STUDENT, requestOptions)
      .then((response) => {
        if (response.ok) {
          console.log("~~~~~~~ LOGIN SUCCESS ~~~~~~~",response)
          return response.json();
        } else {
          throw true;
        }
      })
      .then((json) => {
        toast.success("Вы авторизованы!", {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(loadUserProfile(json));
        return dispatch(loginSuccessAction(json));
      })
      .catch((error) => {
        toast.error("Не удалось войти в систему, попробуйте снова!", {
          position: toast.POSITION.TOP_CENTER,
        });
        return dispatch(loginFailureAction());
      });
  };
};

export const loginOther = (login, password) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ login: login, password: password }),
    };

    fetch(LOGIN_OTHER, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw true;
        }
      })
      .then((json) => {
        toast.success("Вы авторизованы!", {
          position: toast.POSITION.TOP_CENTER,
        });
        return dispatch(loginSuccessAction(json));
      })
      .catch((error) => {
        toast.error("Неправильный логин и/или пароль", {
          position: toast.POSITION.TOP_CENTER,
        });
        return dispatch(loginFailureAction());
      });
  };
};

export const logoutUser = () => {
  return (dispatch) => {
    return dispatch(logoutAction());
  };
};

export const reloadUser = (savedUser) => {
  return (dispatch) => {
    dispatch(reloadAction(savedUser));
  };
};

export const loadUserProfile = (savedUser) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${savedUser.type} ${savedUser.accessToken}`,
    },
  };

  return (dispatch) => {
    fetch(GET_PORTFOLIO_BY_STUDENT_ID(savedUser.id), requestOptions)
      .then((response) => response.json())
      .then((json) => {
        //const professions = [...json.professions];
        dispatch(loadUserProfileAction({...json}))
        //dispatch(updateUserProfessionsAction([...professions]))
      })
      .catch(() => console.log("Не удается подключиться к серверу"));
  };
};
