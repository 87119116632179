import React, {useEffect, useState} from "react"
import {GET_SUBSCRIPTION_BY_COMPANY_ID, UPDATE_COMPANY_SUBSCRIPTION_ADMIN} from "@api/endpoints";
import {useVariables} from "@hooks/useVariables";
import {NavLink} from "react-router-dom";
import EditOrCreateModal from "@pages/AdminCreateOrganization/Tabs/Tariff/EditOrCreateModal";
import ModalCustom from "@components/ModalCustom";
import {getDateInInput} from "@utils/other";
import { toast } from "react-toastify";

const Tariff = ({userId})=>{
    const [selectedTariff, setSelectedTariff] = useState();
    const [activeModalEdit, setActiveModalEdit] = useState(false);

    const [myTariff, setMyTariff] = useState();
    const requestGet = useVariables().requestOptionsGET
    const requestPut = useVariables().constRequestOptionsPUT
    useEffect(() => {
        if (userId) {
            fetch(GET_SUBSCRIPTION_BY_COMPANY_ID(userId), requestGet).then((res) => res.json()).then((data) => {
                setMyTariff(data.content)
            })
        }
    }, [userId])
    const closeModal = () =>{
        setSelectedTariff(null)
        setActiveModalEdit(false)
    }
    const editTariff = (subId,endDate) => {
        const id = selectedTariff.subscriptionId;
        setActiveModalEdit(false)
        const config = requestPut({
            "subscriptionId" : subId,
            "endDate": getDateInInput(endDate) ,
        })
        const myTariffs__ = [...myTariff];
        let index = myTariffs__.findIndex((tariff) => tariff.subscriptionId === id);
        fetch(UPDATE_COMPANY_SUBSCRIPTION_ADMIN(userId), config).then((res) => res.json()).then((data) => {
            toast.success("Тариф обновлен",{position: toast.POSITION.TOP_CENTER})
            myTariffs__[index] = data;
            setMyTariff([...myTariffs__])
        }).catch(()=>{
            toast.error("Ошибка при обновлении тарифа",{position: toast.POSITION.TOP_CENTER})
        })
    }
    return (
        <div className="active">
            <table className="tables table-tarif table-md">
                <thead>
                <tr>
                    <th onClick={() => {
                        setSelectedTariff(null)
                        setActiveModalEdit(true)
                    }} className="add"><a  className="btn btn-blue modal-link" data-modal="#modal-add2"></a>
                    </th>
                    <th>Название</th>
                    <th>Дата покупки</th>
                    <th>Дата окончания</th>
                    <th>Пользователи</th>
                    <th>Предложения</th>
                    <th>Контакты</th>
                </tr>
                </thead>
                <tbody>
                    {(myTariff && myTariff.length > 0) ?
                        myTariff.map((tariff, index) => (
                            <tr>
                                <td>
                                    <ul className="unstyled">
                                            <li><a className="edit"
                                                onClick={() => {
                                                    setActiveModalEdit(true)
                                                    setSelectedTariff(tariff)
                                                }}
                                            ></a></li>
                                    </ul>
                                </td>
                                <td data-label="Текущий тариф:">{tariff.subscriptionName}</td>
                                <td data-label="Дата покупки:">{tariff.startDate === "1970-01-01" ? "Неизвестно" : tariff.startDate}</td>
                                <td data-label="Дата окончания:">{tariff.endDate === null ? "" : tariff.endDate}</td>
                                <td data-label="Пользователи:">{tariff.usersCount} из {tariff.userMaxCount}</td>
                                <td data-label="Предложения:">{tariff.offersCount} из {tariff.offerMaxCount}</td>
                                <td data-label="Чаты:">{tariff.chatsCount} из {tariff.chatMaxCount}</td>
                            </tr>
                        ))
                        :
                        <td colSpan={6}>История пустая</td>

                    }
                </tbody>
            </table>
            <ModalCustom
                title={selectedTariff ? "Редактирования тарифа" : "Добавление тарифа"}
                active={activeModalEdit}
                className={"autoWidth"}
                setActive={setActiveModalEdit}
            >
                <EditOrCreateModal
                    editTariff={editTariff}
                    close={closeModal}
                    tariff={selectedTariff}
                />
            </ModalCustom>
        </div>
    )
}

export default Tariff