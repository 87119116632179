import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {IsAdmin} from "@hooks/useAuth";

const MessageInput = ({selectedChat, sendTyping, sendMessage}) => {
    const user = useSelector((state) => state.user.user);
    const isAdmin_ = IsAdmin();

    const [currentMessage, setCurrentMessage] = useState("");
    const textAreaRef = useRef(null);
    const handleSendPress = () => {
        if (currentMessage.trim()) {
            const chatMessage = {
                chatId: selectedChat.chatId,
                senderId: user.id,
                message: currentMessage,
            };
            sendMessage(chatMessage);
        }
        setCurrentMessage("");
    };
    const resizeTextArea = () => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto";
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
        }

    };
    useEffect(resizeTextArea, [currentMessage]);
    const handleEnterPress = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            handleSendPress();
        }else if (event.key === "Enter" && event.shiftKey) {
            event.preventDefault();
            setCurrentMessage(currentMessage + "\n");
        }
    };

    if (!selectedChat?.chatId) return null;

    return (
        <div className="input_message" style={{paddingLeft:isAdmin_ ? 24 : 24}}>
          <textarea
              type="text"
              ref={textAreaRef}
              rows={1}
              style={{"resize": "none",padding: "10px"}}
              value={currentMessage}
              className="form_field"
              placeholder="Сообщение"
              onChange={(e) => {
                  sendTyping();
                  setCurrentMessage(e.target.value);
              }}
              onKeyPress={(e) => handleEnterPress(e)}
          />
                <button
                    className="button turquoise_button"
                    onClick={() => handleSendPress()}
                >
                    <span className="i-telegram"/>
                </button>
        </div>
    );
};

export default React.memo(MessageInput);
