import React, { Component } from'react';

const ModalContent = ({formRef,selectedObject,save,close,activeTab}) => {
    return(
        <form ref={formRef} onSubmit={(e) => e.preventDefault()}>
            <div className="newStyle flex flex-col gap-25">
                <div className={"flex flex-col gap-10"}>
                    <div className={`search-blk create-blk`}>
                        <label htmlFor="Name2">Название </label>
                        <input
                            name={"name"}
                            defaultValue={selectedObject ? selectedObject.name : ""}
                            type="text"
                            placeholder="Название" id="Name2"/>
                    </div>
                </div>
                {activeTab === 0 && (
                    <div className={"flex flex-col gap-10"}>
                        <div className={`search-blk abbreviation create-blk`}>
                            <label htmlFor="Name2">Аббревиатура </label>
                            <input
                                name={"abbreviation"}
                                defaultValue={selectedObject ? selectedObject.abbreviation : ""}
                                type="text"
                                placeholder="Аббревиатура" id="Name2"/>
                        </div>
                    </div>

                )}
                {(activeTab !== 5 && activeTab !== 4) &&
                <div className={"flex flex-col gap-10"}>
                    <div className={`search-blk create-blk`}>
                        <label htmlFor="Name2">Код </label>
                        <input
                            name={"code"}
                            style={{width: "100%", textAlign: "left", margin: 0}}
                            defaultValue={selectedObject ? selectedObject.code : ""}
                            type="number"
                            placeholder="Код" id="Name2"/>
                    </div>
                </div>
                }
                    <div className="table-btn student-btn btn-all">
                        <button onClick={close} className="btn btn-grey">Отменить</button>
                        <button onClick={save} type="button" className="btn btn-blue">
                            {selectedObject ? "Сохранить" : "Добавить"}
                        </button>
                    </div>


            </div>
        </form>
    )

}
export default ModalContent;