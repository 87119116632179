import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { useDownloadFile } from "@hooks/useDownloadFile";
import getProductStatus from "@utils/getProductStatus";

import defaultProduct from "@assets/images/product-default.png";

import { DOWNLOAD_FILE } from "@api/endpoints";

const ProductLoad = ({ product, openProductItemModal, onDelete }) => {


  return (
    <div
      className="product_element shadow_block loader-product"
    >
      <div className="product_title">
        <h3></h3>

      </div>

      <div className="image">

      </div>
    </div>
  );
};

export default React.memo(ProductLoad);


