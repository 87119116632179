import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {useDownloadFile} from "@hooks/useDownloadFile";

import {DOWNLOAD_FILE} from "@api/endpoints";
import {NavLink} from "react-router-dom";

const RatingItemLoad = ({el, index, directions, onClick}) => {


    return (
        <div className={"user-loader"}>
            <li className="rating_item" /*onClick={onClick}*/>
                <span className="number"></span>
                <div className="student_item">
                    <img

                    />
                    <div className="student_info">
                        <p></p>
                        <p></p>
                    </div>
                    <span className="points"></span>
                </div>
            </li>
        </div>
    );
};

export default React.memo(RatingItemLoad);
