import React, {useEffect, useRef, useState} from 'react';
import {Bar} from "react-chartjs-2";
import {Tooltip} from "react-tooltip";


const Rsvs = ({studentData}) => {
    const [table, setTable] = useState(false)
    const [rsvs, setRsvs] = useState(studentData.rsvs);
    const [selectedBar,setSelectedBar] = useState(0)
    const container = useRef(null);
    const descriptionContainer = useRef(null);
    const createBarProgress= (child,onClick,index,name) => <div
        data-tooltip-id="my-tooltip" data-tooltip-content={name}
        onClick={()=>onClick(index)} className="bar-progress ">
        {child}
    </div>
    const createBar = (height,index) => <div style={window.innerWidth > 510 ? {height: `${height}%`} : {width: `${height}%`}} className={`bar ${selectedBar === index? 'active' : ''}`}></div>
    const [bars,setBars] = useState();
    useEffect(() => {
        if(rsvs){
            createBars(rsvs)
        }
    },[rsvs])
    const selectBar = (bar) => {
        setSelectedBar(bar)
    }
    const createBars = (data) =>{
        let arr = [];
        data.forEach((item, dataIndex) => {
            const bar = createBar(((item.rsvMark?.rsvMarkId) / 3) * 100,dataIndex)
            const barProgress = createBarProgress(bar,selectBar,dataIndex,item.rsv?.name);
            arr.push(barProgress);
        });
        setBars(arr)
    }
    return (
        <div className="active">
            <Tooltip id="my-tooltip" />
            <div className="swich-blk">
                <label className="switch">
                    <input onChange={() => setTable(!table)} type="checkbox" className="toggleCheckbox"/>
                    <span className="slider"></span>
                </label>
                <span className="toggleLabel">{table ? 'Таблица' : 'График'}</span>
            </div>
            {!table &&
                <div className="chartDiv student-rsv">
                    <div className="indicator-chart">
                        <div className="chart-block">
                            <div className="y-axis">
                                <div className="level"><span>Высокий уровень</span></div>
                                <div className="level"><span>Средний уровень</span></div>
                                <div className="level"><span>Начальный уровень</span></div>
                                <div className="level"></div>
                            </div>
                         {/*   <div className="y-axis y-axis2">
                                <div className="level"><span>Высокий уровень</span></div>
                                <div className="level"><span>Средний уровень</span></div>
                                <div className="level"><span>Начальный уровень</span></div>
                                <div className="level"></div>
                            </div>*/}
                            <div className="chart-container">
                                <div className="chart-blk chblk1">
                                    <div id="myChart3" className="chart">
                                        {bars && bars.map((bar,index) => <div className={index === selectedBar? 'barme active' : ''}>{bar}</div>)}
                                    </div>
                                    {/*<span>Основные</span>*/}
                                </div>
                                {/*<div className="chart-blk chblk2">
                                    <div id="myChart4" className="chart">
                                        {bars && bars.map(bar => bar)}
                                    </div>
                                    <span>Дополнительные</span>
                                </div>*/}
                                <div className="chart-description chblk3">
                                    <div className="primary-description">
                                        <div id="label2">{rsvs && rsvs[selectedBar] && rsvs[selectedBar].rsv?.name}</div>
                                        <div id="value2">{rsvs && rsvs[selectedBar] && rsvs[selectedBar].rsvMark?.rsvMark}</div>
                                    </div>
                                </div>
                                {/*<div className="chart-description chblk4" style={{display: "none"}}>
                                    <div className="secondary-description">
                                        <div id="label-right2">1</div>
                                        <div id="value-right2">2</div>
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {table &&
                <div className="tableDiv indicator-table">
                    <table className="tables">
                        <thead>
                        <tr>
                            <th>РСВ</th>
                            <th style={{textAlign: "end"}}><span>Текущие баллы</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        {rsvs.map((rsv, i) => (
                            <tr key={i}>
                                <td>{rsv?.rsv?.name}</td>
                                <td>{rsv?.rsvMark?.rsvMark}</td>
                            </tr>
                        ))}


                        </tbody>
                    </table>

                </div>

            }
        </div>
    )
}
export default Rsvs;