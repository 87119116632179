import "../assets/styles/components/login_form.scss";

import React, {useRef, useState} from "react";

import { Tooltip } from "react-tooltip";
import Checkbox from "./Checkbox";

import {politic, privacyPolicy} from "../constants/links";
import {toast} from "react-toastify";
import {NavLink} from "react-router-dom";
import ButtonWithLoading from "@components/ButtonWithLoading";

const RegisterForm = ({
    handlerFormSubmit,back

}) => {
  const [acceptedPolicy, setAcceptedPolicy] = useState(false);
  const [errors,setErrors] = useState({});
  const [inn, setInn] = useState("");
  const [nameOrg, setNameOrg]  = useState("");
  const [fio,setFio]  = useState("");
  const [name, setName]  = useState("");
  const [email, setEmail]  = useState("");
  const formRef = useRef();
  const handlePolicyToggle = (target) => {
    setAcceptedPolicy(target.currentTarget.checked);
  };
  const submitForm = async e =>{
    if(!acceptedPolicy){
      toast.error("Для регистрации необходимо принять соглашения.", {
        position: toast.POSITION.TOP_CENTER,
      });
     return;
    }
    let result = await handlerFormSubmit(formRef.current)
    if (!result.status) {
      setErrors(result.errors);
    }
  }
  const focusInput  =  field  =>  {
    let errs = {...errors}
    if(errs[field])
    delete errs[field];
    setErrors(errs);
  }
  const getError = (field,text=false) =>{
    return errors && errors.hasOwnProperty(field)  &&  errors[field] ?  (text ?
        <label htmlFor="" className="error">{errors[field]}</label>
        :"form-error") : ""
  }
  return (
      <div className="login-content">

        <div className="login-content-block">
          <div className="login-content-title">
            <div className={"flex flex-start"}>
              <a onClick={back} className="btn-back  btn-back__white"></a>
              <h1>Регистрация нового профиля</h1>
            </div>

           {/* <div className="login-popover">
              <div className="popover-blk">
                <p>Данные для входа придут на вашу корпоративную почту.</p>
              </div>
            </div>*/}
          </div>
          <form ref={formRef} onSubmit={(e)=>e.preventDefault()} className="login-form reg-form">
            <div className="login-form-blk">
              <div className={`form-blk ${getError("inn")}`}>
                <label for="Inn">ИНН</label>
                <input onFocus={()=>focusInput("inn")} onChange={(e)=>setInn(e.target.value)} value={inn} type="text" name={"inn"}
                       placeholder="ИНН" id="inn"/>
                {getError("inn",true)}
              </div>
              <div className={`form-blk ${getError("longName")}`}>
                <label for="NameOrg">Полное название организации</label>
                <input onFocus={()=>focusInput("longName")} type="text" name={"name"} placeholder="Введите полное название" id="NameOrg" onChange={(e)=>setNameOrg(e.target.value)} value={nameOrg}/>
                {getError("longName",true)}

              </div>
              <div className="form-blk">
                <div className="flex flex-row gap-15 flex-between ">
                  <div className={`form-name ${getError("secondName")}`}>
                    <label htmlFor="Name">Фамилия</label>
                    <input onFocus={()=>focusInput("secondName")} type="text" name={"second_name"} placeholder="Иванов" id="second_name" onChange={(e)=>setFio(e.target.value)} value={fio}/>
                    {getError("secondName",true)}

                  </div>
                  <div className={`form-name ${getError("firstName")}`}>
                    <label htmlFor="Name">Имя</label>
                    <input type="text" onFocus={()=>focusInput("firstName")} name={"first_name"} placeholder="Иван" id="first_name" onChange={(e)=>setName(e.target.value)} value={name}/>
                    {getError("firstName",true)}

                  </div>

                </div>
              </div>
              <div className={`form-blk ${getError("email")}`}>
                <label for="Email">Почта</label>
                <input type="email" onFocus={()=>focusInput("email")} name={"email"} placeholder="example@gmail.com" id="email" onChange={(e)=>setEmail(e.target.value)} value={email}/>
                {getError("email",true)}

              </div>
            </div>
            <label className="checks">
              <input
                  checked={acceptedPolicy}
                  onChange={handlePolicyToggle}
                  className="checkbox" type="checkbox" name="checkbox-test"/>
                <span className="checkbox-custom"></span>
                <span className="label">Согласен(на) с <a href={privacyPolicy} target={"_blank"}>
                        Политикой конфиденциальности</a> и
                        <a href={politic} target={"_blank"}> Пользовательским соглашением</a></span>
            </label>
            <ButtonWithLoading
                onClick={submitForm}
                className={"btn btn-white"}
                loadingStyle={{width:"100%"}}
                spinnerLoadingStyle={{borderBottomColor:"black",height:30}}
              type="button"
                title={"Зарегистрироваться"}
            />

          </form>
          <div className="help-block">
            <p>По всем вопросам пишите на почту <a href="mailto:polykp@spbstu.ru">polykp@spbstu.ru</a></p>
          </div>
        </div>
      </div>
  );
};

export default RegisterForm;
