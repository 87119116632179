import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useVariables = (params = null) => {
  const user = useSelector((state) => state.user.user);

  const dateOptionsWithTime = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const shortDateOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };

  const [requestOptionsGET, setRequestOptionsGET] = useState({
    method: "GET",
    headers: {
      Authorization: `${user.type} ${user.accessToken}`,
    },
  });

  const [requestOptionsPOST, setRequestOptionsPOST] = useState({
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${user.type} ${user.accessToken}`,
    },
    body: JSON.stringify(params),
  });

  const [requestOptionsDELETE, setRequestOptionsDELETE] = useState({
    method: "GET",
    headers: {
      Authorization: `${user.type} ${user.accessToken}`,
    },
  });
  const constRequestOptionsGET  = (param) => {
    return {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user.type} ${user.accessToken}`,
      },
      body: JSON.stringify(param),
    };
  };
  const constRequestOptionsFormDataPOST = (param) => {
    return {
      method: "POST",
      headers: {
        Authorization: `${user.type} ${user.accessToken}`,
      },
      body: param,
    };
  };
  const constRequestOptionsPOST = (param) => {
    return {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user.type} ${user.accessToken}`,
      },
      body: JSON.stringify(param),
    };
  };
  const constRequestOptionsPUT = (param) => {
    return {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user.type} ${user.accessToken}`,
      },
      body: JSON.stringify(param),
    };
  };

  useEffect(() => {
    setRequestOptionsGET({
      method: "GET",
      headers: {
        Authorization: `${user.type} ${user.accessToken}`,
      },
    });
    setRequestOptionsPOST({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user.type} ${user.accessToken}`,
      },
      body: JSON.stringify(params),
    });
    setRequestOptionsDELETE({
      method: "DELETE",
      headers: {
        Authorization: `${user.type} ${user.accessToken}`,
      },
    });
  }, [user, params]);
  return {
    requestOptionsGET,
    requestOptionsPOST,
    requestOptionsDELETE,
    dateOptions,
    dateOptionsWithTime,
    shortDateOptions,
    constRequestOptionsFormDataPOST,
    constRequestOptionsPOST,
    constRequestOptionsGET,
    constRequestOptionsPUT,
  };
};
