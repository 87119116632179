export const getContactWord = (count) => {

    if(String(count).length > 1)
        count = parseInt(String(count)[String(count).length-1]);
    //Добавляем окончание в зависимости от количества контактов
    if (count === 0)
        return 'Контактов'
    else if (count === 1)
        return 'Контакт'
    else if (count > 1 && count < 5)
        return 'Контакта'
    else if (count >= 5)
        return 'Контактов'


}
export const addHTTPS = (url) =>{
    if(!url)
        return "";
    return url.startsWith('https://')? url : 'https://'+url;
}
export const validateGroupNumber = (number) => {
    return String(number)
        .toLowerCase()
        .match(
            /^\d{7}.\d{5}$/
        );
}
export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};
const checkZero = (num) => {
    num = String(num);
    if(num.length === 1)
        return "0"+num;
    return num;
}
export const getDateInInput = (date,withTime = false) => {
    let now = date

    let day = ("0" + now.getDate()).slice(-2);
    let month = ("0" + (now.getMonth() + 1)).slice(-2);
    if(withTime)
        return  now.getFullYear()+"-"+(month)+"-"+(day)+" "+checkZero(now.getHours())+":"+checkZero(now.getMinutes());
    else
        return  now.getFullYear()+"-"+(month)+"-"+(day) ;
}