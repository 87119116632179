import "@assets/styles/pages/page_from_admin.scss";

import React, {useState} from "react";

import ProductsTable from "./components/ProductsTable";
import FilterAccordion from "@pages/CareersPage/components/FilterAccordion";
import Collapse from "@pages/AdminCareersPage/components/Collapse";
import {usePagination} from "@hooks/usePagination";
import {GET_ALL_PRODUCTS, GET_COMPANY_PROFILES_V2} from "@api/endpoints";
import {useVariables} from "@hooks/useVariables";
import CustomSelect from "@components/CustomSelect";

const ShopFromAdminPage = () => {
    const requestOptionsGET = useVariables().requestOptionsGET;
    const [filters,setFilters] = useState({name:"",companyId:""})
    const [subFilters,setSubFilters] = useState({name:"",companyId:""})
    const {data:companies} = usePagination(GET_COMPANY_PROFILES_V2,requestOptionsGET,"" );
    const {data:products,refresh} = usePagination(GET_ALL_PRODUCTS, requestOptionsGET,"productId,desc","",filters);
    const findCompany = () => {
        const cmp = companies.find(x=>x.companyId === subFilters.companyId)
        if(cmp)
            return {label:cmp.name ? cmp.name :cmp.longName,value:cmp.companyId}
    }
    return (
    <div className="page_from_admin newStyle ">
      <h1>Список товаров</h1>
        <Collapse
            header={"Поиск"}
        >
            <div style={{width: "100%"}}>
                <form action="" method="" className="search-form">
                    <div className="flex">
                        <div style={{flex:1}} className="search-blk create-blk">
                            <label htmlFor="Name">Название</label>
                            <input value={subFilters.name} onChange={(e)=>setSubFilters({...subFilters,name: e.target.value})} type="text" placeholder="Толстовка" id="Name"/>
                        </div>
                        <div style={{flex:1}} className="search-blk create-blk">
                            <label htmlFor="Institute">Организация</label>
                            <CustomSelect
                                placeholder={"Выберите организацию"}
                                allowNullRef={true}
                                refValue={(subFilters.companyId && companies) ? findCompany() : undefined}
                                onChange={(value) => {
                                    setSubFilters({...subFilters, companyId: value})
                                }}
                                name="companyId"
                                options={companies.map((item) => {
                                    return {label: item.name ? item.name : item.longName, value: item.companyId}
                                })}
                            />
                        </div>
                    </div>
                    <div className="search-btn">
                        <button onClick={()=> {
                            setFilters({name: "", companyId: ""})
                            setSubFilters({name: "", companyId: ""})
                        }} type="reset" className="btn btn-reset">Сбросить</button>
                        <button onClick={()=>setFilters(subFilters)} type="button" className="btn btn-blue">Применить</button>
                    </div>
                </form>
            </div>
        </Collapse>
      <div className="shop_block filtered_page_block">

        <div className="shop_content filtered_page_content customTable">
          <ProductsTable products={products} refresh={refresh}/>
        </div>
        {/*<FiltersBlock
          opened={!collapseFilter}
          onClose={() => setCollapseFilter(true)}
          onFilterChange={setSelectedFilter}
        />*/}
      </div>
    </div>
  );
};

export default ShopFromAdminPage;
