import React, {useState} from "react";
import {useVariables} from "@hooks/useVariables";
import {usePagination} from "@hooks/usePagination";
import {GET_PROFESSIONS, GET_PROFESSIONS_V2, STUDENT_INFORMATION} from "@api/endpoints";
import CustomSelect from "@components/CustomSelect";
import {studentsLanguages} from "@utils/languageList";


const StudentBlock = ({user,setSelectedProfession,selectedProfession,getDefaultProfessions}) => {
    const [limit,setLimit] = useState({size:1000})

    const requestGet = useVariables().requestOptionsGET;
    const institutes = usePagination(STUDENT_INFORMATION.getInstitutes,requestGet,"").data
    const educationDirections = usePagination(STUDENT_INFORMATION.getEducationDirections,requestGet,"").data
    const educationPrograms = usePagination(STUDENT_INFORMATION.getEducationPrograms,requestGet,"").data
    const qualifications = usePagination(STUDENT_INFORMATION.getQualifications,requestGet,"").data
    const professions = usePagination(GET_PROFESSIONS, requestGet, "",null,limit).data;

    return(
        <div className="flex">
            <div className="search-blk create-blk">
                <label htmlFor="Profession">Профессия</label>
                <CustomSelect
                    placeholder={"Профессия"}
                    /*refValue={activities && activityId && activities.length ? getDefaultActivity() : null}*/
                    onChange={(e) => {
                        console.log(e,user.professions)
                        setSelectedProfession([...e])
                    }}
                    name="professionId"
                    isMulti={true}
                    refValue={selectedProfession && selectedProfession.length ? getDefaultProfessions() : null}
                    options={professions && professions.map((item) => {
                        return {label: item.name, value: item.professionId}
                    })}
                />
            </div>
            <div className="search-blk create-blk disabled">
                <label htmlFor="Name">Фамилия</label>
                <input  defaultValue={user?.firstName} type="text" placeholder="Иванов" name="secondName"/>
            </div>
            <div className="search-blk create-blk disabled">
                <label htmlFor="Name">Имя</label>
                <input  defaultValue={user?.firstName} type="text" placeholder="Иван" name="firstName"/>
            </div>
            <div className="search-blk create-blk disabled">
                <label htmlFor="Name">Отчество</label>
                <input  defaultValue={user?.middleName} type="text" placeholder="Иванович" name="middleName"/>
            </div>
            <div className="search-blk create-blk disabled">
                <label htmlFor="Mail">Почта</label>
                <input  defaultValue={user?.email} type="text" placeholder="example@gmail.com" name="email"/>
            </div>
            <div className="search-blk create-blk disabled">
                <label htmlFor="Group">Номер группы</label>
                <input  defaultValue={user?.group} type="text" placeholder="1234567/1234567" name="group"/>
            </div>


            <div className="search-blk create-blk disabled">
                <label htmlFor="Level">Уровень получаемого образования</label>
                <CustomSelect
                    disabled
                    placeholder={"Уровень образования"}
                    /*refValue={activities && activityId && activities.length ? getDefaultActivity() : null}*/
                    onChange={(e) => {

                    }}
                    refValue={user && user.qualification && {label:user.qualification.name,value: user.qualification.qualificationId}}
                    name="qualificationId"
                    isMulti={false}
                    options={qualifications && qualifications.map((item) => {
                        return {label: item.name, value: item.qualificationId}
                    })}
                />
            </div>

            <div className="search-blk create-blk disabled">
                <label htmlFor="Institute">Институт</label>
                <CustomSelect
                    disabled
                    placeholder={"Институт"}
                    /*refValue={activities && activityId && activities.length ? getDefaultActivity() : null}*/
                    onChange={(e) => {

                    }}
                    refValue={user && user.institute && {label:user.institute.name,value: user.institute.instituteId}}
                    name="instituteId"
                    isMulti={false}
                    options={institutes && institutes.map((item) => {
                        return {label: item.name, value: item.instituteId}
                    })}
                />
            </div>
            <div className="search-blk create-blk disabled">
                <label htmlFor="Direction">Направление</label>
                <CustomSelect
                    disabled
                    placeholder={"Направление"}
                    /*refValue={activities && activityId && activities.length ? getDefaultActivity() : null}*/
                    onChange={(e) => {

                    }}
                    refValue={user && user.eduDirection && {label:user.eduDirection.name,value: user.eduDirection.educationDirectionId}}
                    name="eduDirectionId"
                    isMulti={false}
                    options={educationDirections && educationDirections.map((item) => {
                        return {label: item.name, value: item.educationDirectionId}
                    })}
                />
            </div>
            <div className="search-blk create-blk disabled">
                <label htmlFor="Program">Образовательная программа</label>
                <CustomSelect
                    disabled
                    placeholder={"Образовательная программа"}
                    /*refValue={activities && activityId && activities.length ? getDefaultActivity() : null}*/
                    onChange={(e) => {

                    }}
                    refValue={user && user.eduProgram && {label:user.eduProgram.name,value: user.eduProgram.educationProgramId}}

                    name="eduProgramId"
                    isMulti={false}
                    options={educationPrograms && educationPrograms.map((item) => {
                        return {label: item.name, value: item.educationProgramId}
                    })}
                />
            </div>

        </div>
    )

}
export default StudentBlock;