import React from "react";

import FilterSelect from "./FilterSelect";

import { usePagination } from "@hooks/usePagination";
import { useVariables } from "@hooks/useVariables";

import {LOAD_INSTITUTE, STUDENT_INFORMATION} from "@api/endpoints";

const SCROLL_ELEMENT_ID = "institutes_list";

const InstitutesFilterSelect = ({ filters, onFilterSelect }) => {
  const requestOptionsGET = useVariables().requestOptionsGET;
  const { data: institutes, loading } = usePagination(
      STUDENT_INFORMATION.getInstitutes,
    requestOptionsGET,
    "name",
    SCROLL_ELEMENT_ID,
  );

  return (
    <FilterSelect
        field={"instituteId"}
      values={institutes}
      loading={loading}
      placeholder="Институт"
      filterName="instituteId"
      filters={filters}
      scrollId={SCROLL_ELEMENT_ID}
      onFilterSelect={onFilterSelect}
    />
  );
};

export default React.memo(InstitutesFilterSelect);
