import React from "react";

const Professions = ({update,creat_,remove,data}) => {

    return (
        <table className="tables logs-table">
            <thead>
            <tr>
                <th style={{width: 100}} className="add">
                    <a onClick={()=>{
                        creat_()
                    }} className="btn btn-blue modal-link"></a>
                </th>
                <th>Название</th>
            </tr>
            </thead>

            <tbody id={""} style={{maxHeight: 50, height: 20}}>
            {data && data.data.map((profession, index) => {
                return (
                    <tr key={index}>
                        <td>
                            <ul className="unstyled">
                                <li>
                                    <a className="edit" onClick={()=>update(profession)}>
                                    </a>
                                </li>
                                <li><a onClick={()=>remove(profession)} className="delete"></a></li>
                                <li></li>
                            </ul>

                        </td>
                        <td>
                            {profession.name}
                        </td>

                    </tr>
                )
            })}
            </tbody>

        </table>
    )
}
export default Professions;